/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const device_link_events = /* GraphQL */ `
  query Device_link_events($id: String!) {
    device_link_events(id: $id) {
      created
      created_by
      device_link_id
      id
      event_name
      event_id
      updated
      updated_by
      __typename
    }
  }
`;
export const getActionV1 = /* GraphQL */ `
  query GetActionV1($id: String!) {
    getActionV1(id: $id) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const getActionParameterV1 = /* GraphQL */ `
  query GetActionParameterV1($id: String!) {
    getActionParameterV1(id: $id) {
      id
      action_id
      parameter_id
      parameter_name
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const getCachedSiteCameras = /* GraphQL */ `
  query GetCachedSiteCameras($siteCode: String!) {
    getCachedSiteCameras(siteCode: $siteCode) {
      siteCode
      created
      cameras {
        name
        description
        systemIdentifier
        __typename
      }
      __typename
    }
  }
`;
export const getCachedSiteCamerasV1 = /* GraphQL */ `
  query GetCachedSiteCamerasV1($siteCode: String!) {
    getCachedSiteCamerasV1(siteCode: $siteCode) {
      siteCode
      created
      cameras {
        name
        description
        systemIdentifier
        __typename
      }
      __typename
    }
  }
`;
export const getConditionOperandV1 = /* GraphQL */ `
  query GetConditionOperandV1($id: String!) {
    getConditionOperandV1(id: $id) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const getDeviceActionV1 = /* GraphQL */ `
  query GetDeviceActionV1($id: String!) {
    getDeviceActionV1(id: $id) {
      id
      site_code
      input_device_id
      output_device_id
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const getDeviceActionConditionGroupV1 = /* GraphQL */ `
  query GetDeviceActionConditionGroupV1($id: String!) {
    getDeviceActionConditionGroupV1(id: $id) {
      id
      device_action_id
      device_action_conditions {
        id
        group_id
        operator
        operand_id
        operand_name
        value
        created
        created_by
        updated
        updated_by
        __typename
      }
      operator
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const getDeviceActionConditionV1 = /* GraphQL */ `
  query GetDeviceActionConditionV1($id: String!) {
    getDeviceActionConditionV1(id: $id) {
      id
      group_id
      operator
      operand_id
      operand_name
      value
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const getDeviceActionParameterV1 = /* GraphQL */ `
  query GetDeviceActionParameterV1($id: String!) {
    getDeviceActionParameterV1(id: $id) {
      id
      device_action_id
      action_id
      action_name
      parameter_id
      parameter_name
      value
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const getDeviceEventV1 = /* GraphQL */ `
  query GetDeviceEventV1($id: String!) {
    getDeviceEventV1(id: $id) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const getActionDeviceTypeEventV1 = /* GraphQL */ `
  query GetActionDeviceTypeEventV1($id: String!) {
    getActionDeviceTypeEventV1(id: $id) {
      id
      device_event_id
      action_device_type_id
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const getDeviceLink = /* GraphQL */ `
  query GetDeviceLink($id: ID!) {
    getDeviceLink(id: $id) {
      acd_device_name
      acd_device_type
      acd_parent_device_id
      acd_child_device_id
      acd_subchild_device_id
      camera_name
      camera_system_id
      created
      created_by
      enabled
      event_links
      id
      system_id
      system_name
      updated
      updated_by
      __typename
    }
  }
`;
export const getDeviceLinkV1 = /* GraphQL */ `
  query GetDeviceLinkV1($id: ID!) {
    getDeviceLinkV1(id: $id) {
      acd_device_name
      acd_device_type
      acd_parent_device_id
      acd_child_device_id
      acd_subchild_device_id
      camera_name
      camera_system_id
      created
      created_by
      enabled
      event_links
      id
      system_id
      system_name
      updated
      updated_by
      __typename
    }
  }
`;
export const getDeviceLinkEvent = /* GraphQL */ `
  query GetDeviceLinkEvent($id: ID!) {
    getDeviceLinkEvent(id: $id) {
      created
      created_by
      device_link_id
      id
      event_name
      event_id
      updated
      updated_by
      __typename
    }
  }
`;
export const getDeviceLinkEventV1 = /* GraphQL */ `
  query GetDeviceLinkEventV1($id: ID!) {
    getDeviceLinkEventV1(id: $id) {
      created
      created_by
      device_link_id
      id
      event_name
      event_id
      updated
      updated_by
      __typename
    }
  }
`;
export const getDeviceTypeV1 = /* GraphQL */ `
  query GetDeviceTypeV1($id: ID!) {
    getDeviceTypeV1(id: $id) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const getActionDeviceTypeV1 = /* GraphQL */ `
  query GetActionDeviceTypeV1($id: ID!) {
    getActionDeviceTypeV1(id: $id) {
      id
      action_id
      device_type_id
      action_device_type
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      created
      created_by
      device_type
      id
      name
      updated
      updated_by
      __typename
    }
  }
`;
export const getEventV1 = /* GraphQL */ `
  query GetEventV1($id: ID!) {
    getEventV1(id: $id) {
      created
      created_by
      device_type
      id
      name
      updated
      updated_by
      __typename
    }
  }
`;
export const getEventLinksForSystemId = /* GraphQL */ `
  query GetEventLinksForSystemId($system_id: ID!) {
    getEventLinksForSystemId(system_id: $system_id) {
      event_links {
        device_link_id
        devices {
          cameraName
          cameraSystemIdentifier
          accessControlDeviceName
          accessControlDeviceParentDeviceID
          accessControlDeviceChildDeviceID
          accessControlDeviceSubchildDeviceID
          accessControlDeviceType
          __typename
        }
        events
        system {
          auth_mode
          system_id
          secondary_system_id
          facman_sitename
          srvr_alrm_srvc_path
          srvr_alrm_srvc_port
          srvr_cmd_srvc_path
          srvr_cmd_srvc_port
          srvr_cnf_srvc_path
          srvr_cnf_srvc_port
          srvr_vpc_endpoint
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getEventLinksForSystemIdV1 = /* GraphQL */ `
  query GetEventLinksForSystemIdV1($system_id: ID!) {
    getEventLinksForSystemIdV1(system_id: $system_id) {
      event_links {
        device_link_id
        devices {
          cameraName
          cameraSystemIdentifier
          accessControlDeviceName
          accessControlDeviceParentDeviceID
          accessControlDeviceChildDeviceID
          accessControlDeviceSubchildDeviceID
          accessControlDeviceType
          __typename
        }
        events
        system {
          auth_mode
          system_id
          secondary_system_id
          facman_sitename
          srvr_alrm_srvc_path
          srvr_alrm_srvc_port
          srvr_cmd_srvc_path
          srvr_cmd_srvc_port
          srvr_cnf_srvc_path
          srvr_cnf_srvc_port
          srvr_vpc_endpoint
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getParameterV1 = /* GraphQL */ `
  query GetParameterV1($id: ID!) {
    getParameterV1(id: $id) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const getParameterStoreValue = /* GraphQL */ `
  query GetParameterStoreValue($parameterName: String!) {
    getParameterStoreValue(parameterName: $parameterName)
  }
`;
export const getSystem = /* GraphQL */ `
  query GetSystem($id: ID!) {
    getSystem(id: $id) {
      auth_mode
      created
      created_by
      enabled
      id
      name
      secondary_system_id
      secondary_system_name
      segment_name
      segment_location
      segment_source
      srvr_alrm_srvc_path
      srvr_alrm_srvc_port
      srvr_cmd_srvc_path
      srvr_cmd_srvc_port
      srvr_cnf_srvc_path
      srvr_cnf_srvc_port
      srvr_vpc_endpoint
      updated
      updated_by
      __typename
    }
  }
`;
export const getSystemV1 = /* GraphQL */ `
  query GetSystemV1($id: ID!) {
    getSystemV1(id: $id) {
      auth_mode
      created
      created_by
      enabled
      id
      name
      secondary_system_id
      secondary_system_name
      segment_name
      segment_location
      segment_source
      srvr_alrm_srvc_path
      srvr_alrm_srvc_port
      srvr_cmd_srvc_path
      srvr_cmd_srvc_port
      srvr_cnf_srvc_path
      srvr_cnf_srvc_port
      srvr_vpc_endpoint
      updated
      updated_by
      __typename
    }
  }
`;
export const getSystemForSegmentName = /* GraphQL */ `
  query GetSystemForSegmentName($segmentName: String!) {
    getSystemForSegmentName(segmentName: $segmentName) {
      auth_mode
      created
      created_by
      enabled
      id
      name
      secondary_system_id
      secondary_system_name
      segment_name
      segment_location
      segment_source
      srvr_alrm_srvc_path
      srvr_alrm_srvc_port
      srvr_cmd_srvc_path
      srvr_cmd_srvc_port
      srvr_cnf_srvc_path
      srvr_cnf_srvc_port
      srvr_vpc_endpoint
      updated
      updated_by
      __typename
    }
  }
`;
export const getSystemForSegmentNameV1 = /* GraphQL */ `
  query GetSystemForSegmentNameV1($segmentName: String!) {
    getSystemForSegmentNameV1(segmentName: $segmentName) {
      auth_mode
      created
      created_by
      enabled
      id
      name
      secondary_system_id
      secondary_system_name
      segment_name
      segment_location
      segment_source
      srvr_alrm_srvc_path
      srvr_alrm_srvc_port
      srvr_cmd_srvc_path
      srvr_cmd_srvc_port
      srvr_cnf_srvc_path
      srvr_cnf_srvc_port
      srvr_vpc_endpoint
      updated
      updated_by
      __typename
    }
  }
`;
export const listAccessControlDevices = /* GraphQL */ `
  query ListAccessControlDevices($path: String!) {
    listAccessControlDevices(path: $path) {
      Parent_DeviceID
      Child_DeviceID
      Subchild_DeviceID
      Device_Type
      DeviceName
      __typename
    }
  }
`;
export const listAccessControlDevicesV1 = /* GraphQL */ `
  query ListAccessControlDevicesV1($path: String!) {
    listAccessControlDevicesV1(path: $path) {
      Parent_DeviceID
      Child_DeviceID
      Subchild_DeviceID
      Device_Type
      DeviceName
      __typename
    }
  }
`;
export const listActionDeviceTypesV1 = /* GraphQL */ `
  query ListActionDeviceTypesV1 {
    listActionDeviceTypesV1 {
      id
      action_id
      device_type_id
      action_device_type
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listActionDeviceTypeEventsForActionDeviceTypeV1 = /* GraphQL */ `
  query ListActionDeviceTypeEventsForActionDeviceTypeV1(
    $action_device_type_id: String!
  ) {
    listActionDeviceTypeEventsForActionDeviceTypeV1(
      action_device_type_id: $action_device_type_id
    ) {
      id
      action_device_type_id
      action_device_type
      action_id
      action_name
      device_event_id
      device_event_name
      device_type_id
      device_type_name
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listActionDeviceTypeEventsV1 = /* GraphQL */ `
  query ListActionDeviceTypeEventsV1 {
    listActionDeviceTypeEventsV1 {
      id
      device_event_id
      action_device_type_id
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listActionsV1 = /* GraphQL */ `
  query ListActionsV1 {
    listActionsV1 {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listActionParametersV1 = /* GraphQL */ `
  query ListActionParametersV1($action_id: String!) {
    listActionParametersV1(action_id: $action_id) {
      id
      action_id
      parameter_id
      parameter_name
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices($methodName: ListDevicesMethodName, $parameters: AWSJSON!) {
    listDevices(methodName: $methodName, parameters: $parameters) {
      message
      statusCode
      devices {
        id
        device_type_id
        device_type
        device_type_name
        device_family
        name
        source_system_device_id
        device_id_key
        parent_id
        parent_name
        grand_parent_id
        grand_parent_name
        site_code
        site_type
        site_group
        site_region
        latitude
        longitude
        country
        zone
        timezone_offset_minutes
        timezone_name
        system_id
        source_system
        source_stack
        source_location
        source_version
        source_instance
        last_updated_at
        device_int_id
        parent_device_int_id
        grand_parent_device_int_id
        deleted_at_timestamp
        communication_address
        communication_port
        communication_server_name
        device_ip_primary
        device_ip_secondary
        source_device_details
        reporting_details
        __typename
      }
      __typename
    }
  }
`;
export const listDevicesV1 = /* GraphQL */ `
  query ListDevicesV1(
    $methodName: ListDevicesMethodName
    $parameters: AWSJSON!
  ) {
    listDevicesV1(methodName: $methodName, parameters: $parameters) {
      message
      statusCode
      devices {
        id
        device_type_id
        device_type
        device_type_name
        device_family
        name
        source_system_device_id
        device_id_key
        parent_id
        parent_name
        grand_parent_id
        grand_parent_name
        site_code
        site_type
        site_group
        site_region
        latitude
        longitude
        country
        zone
        timezone_offset_minutes
        timezone_name
        system_id
        source_system
        source_stack
        source_location
        source_version
        source_instance
        last_updated_at
        device_int_id
        parent_device_int_id
        grand_parent_device_int_id
        deleted_at_timestamp
        communication_address
        communication_port
        communication_server_name
        device_ip_primary
        device_ip_secondary
        source_device_details
        reporting_details
        __typename
      }
      __typename
    }
  }
`;
export const listParametersForActionV1 = /* GraphQL */ `
  query ListParametersForActionV1($action_id: String!) {
    listParametersForActionV1(action_id: $action_id) {
      id
      action_id
      action_name
      parameter_id
      parameter_name
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listAudit = /* GraphQL */ `
  query ListAudit(
    $startDate: String!
    $endDate: String!
    $limit: Int!
    $offset: Int!
  ) {
    listAudit(
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      offset: $offset
    ) {
      event_id
      table_name
      action_tstamp_tx
      action
      row_data
      row_data_json
      changed_fields
      changed_fields_json
      __typename
    }
  }
`;
export const listAuditV1 = /* GraphQL */ `
  query ListAuditV1(
    $startDate: String!
    $endDate: String!
    $limit: Int!
    $offset: Int!
  ) {
    listAuditV1(
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      offset: $offset
    ) {
      event_id
      table_name
      action_tstamp_tx
      action
      row_data
      row_data_json
      changed_fields
      changed_fields_json
      __typename
    }
  }
`;
export const listConditionOperandsV1 = /* GraphQL */ `
  query ListConditionOperandsV1 {
    listConditionOperandsV1 {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listDeviceActionOutputActionsV1 = /* GraphQL */ `
  query ListDeviceActionOutputActionsV1($device_action_id: String!) {
    listDeviceActionOutputActionsV1(device_action_id: $device_action_id) {
      id
      device_action_id
      action_id
      action_name
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listDeviceActionConditionGroupsForDeviceActionV1 = /* GraphQL */ `
  query ListDeviceActionConditionGroupsForDeviceActionV1(
    $device_action_id: String!
  ) {
    listDeviceActionConditionGroupsForDeviceActionV1(
      device_action_id: $device_action_id
    ) {
      id
      device_action_id
      device_action_conditions {
        id
        group_id
        operator
        operand_id
        operand_name
        value
        created
        created_by
        updated
        updated_by
        __typename
      }
      operator
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listDeviceActionConditionsForGroupV1 = /* GraphQL */ `
  query ListDeviceActionConditionsForGroupV1($group_id: String!) {
    listDeviceActionConditionsForGroupV1(group_id: $group_id) {
      id
      group_id
      operator
      operand_id
      operand_name
      value
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listDeviceActionParametersForDeviceActionV1 = /* GraphQL */ `
  query ListDeviceActionParametersForDeviceActionV1(
    $device_action_id: String!
  ) {
    listDeviceActionParametersForDeviceActionV1(
      device_action_id: $device_action_id
    ) {
      id
      device_action_id
      action_id
      action_name
      parameter_id
      parameter_name
      value
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listDeviceActionsForSiteV1 = /* GraphQL */ `
  query ListDeviceActionsForSiteV1($site_code: String!) {
    listDeviceActionsForSiteV1(site_code: $site_code) {
      id
      site_code
      device_action_condition_groups {
        id
        device_action_id
        device_action_conditions {
          id
          group_id
          operator
          operand_id
          operand_name
          value
          created
          created_by
          updated
          updated_by
          __typename
        }
        operator
        created
        created_by
        updated
        updated_by
        __typename
      }
      device_action_input_events {
        id
        device_action_id
        device_event_id
        device_event_name
        created
        created_by
        updated
        updated_by
        __typename
      }
      device_action_output_actions {
        id
        device_action_id
        action_id
        action_name
        created
        created_by
        updated
        updated_by
        __typename
      }
      device_action_parameters {
        id
        device_action_id
        action_id
        action_name
        parameter_id
        parameter_name
        value
        created
        created_by
        updated
        updated_by
        __typename
      }
      input_device_id
      output_device_id
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listDeviceEventsV1 = /* GraphQL */ `
  query ListDeviceEventsV1 {
    listDeviceEventsV1 {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listDeviceTypesV1 = /* GraphQL */ `
  query ListDeviceTypesV1 {
    listDeviceTypesV1 {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listDeviceTypesForActionV1 = /* GraphQL */ `
  query ListDeviceTypesForActionV1($action_id: String!) {
    listDeviceTypesForActionV1(action_id: $action_id) {
      id
      action_id
      action_name
      action_device_type
      device_type_id
      device_type_name
      device_type_description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listSiteCameras = /* GraphQL */ `
  query ListSiteCameras(
    $siteCode: String!
    $authMode: String!
    $cmdSrvcEndpoint: String!
    $cmdSrvcPath: String!
    $cmdSrvcPort: String!
  ) {
    listSiteCameras(
      siteCode: $siteCode
      authMode: $authMode
      cmdSrvcEndpoint: $cmdSrvcEndpoint
      cmdSrvcPath: $cmdSrvcPath
      cmdSrvcPort: $cmdSrvcPort
    ) {
      name
      description
      systemIdentifier
      __typename
    }
  }
`;
export const listSiteCamerasV1 = /* GraphQL */ `
  query ListSiteCamerasV1(
    $siteCode: String!
    $authMode: String!
    $cmdSrvcEndpoint: String!
    $cmdSrvcPath: String!
    $cmdSrvcPort: String!
  ) {
    listSiteCamerasV1(
      siteCode: $siteCode
      authMode: $authMode
      cmdSrvcEndpoint: $cmdSrvcEndpoint
      cmdSrvcPath: $cmdSrvcPath
      cmdSrvcPort: $cmdSrvcPort
    ) {
      name
      description
      systemIdentifier
      __typename
    }
  }
`;
export const listDeviceLinkEventsForDeviceLink = /* GraphQL */ `
  query ListDeviceLinkEventsForDeviceLink($device_link_id: ID!) {
    listDeviceLinkEventsForDeviceLink(device_link_id: $device_link_id) {
      created
      created_by
      device_link_id
      id
      event_name
      event_id
      updated
      updated_by
      __typename
    }
  }
`;
export const listDeviceLinkEventsForDeviceLinkV1 = /* GraphQL */ `
  query ListDeviceLinkEventsForDeviceLinkV1($device_link_id: ID!) {
    listDeviceLinkEventsForDeviceLinkV1(device_link_id: $device_link_id) {
      created
      created_by
      device_link_id
      id
      event_name
      event_id
      updated
      updated_by
      __typename
    }
  }
`;
export const listDeviceLinkTypes = /* GraphQL */ `
  query ListDeviceLinkTypes {
    listDeviceLinkTypes {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listDeviceLinkTypeActions = /* GraphQL */ `
  query ListDeviceLinkTypeActions {
    listDeviceLinkTypeActions {
      id
      action_id
      device_type_id
      action_device_type
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listDeviceLinksForSite = /* GraphQL */ `
  query ListDeviceLinksForSite($segment_name: String!) {
    listDeviceLinksForSite(segment_name: $segment_name) {
      acd_device_name
      acd_device_type
      acd_parent_device_id
      acd_child_device_id
      acd_subchild_device_id
      camera_name
      camera_system_id
      created
      created_by
      enabled
      event_links
      id
      system_id
      system_name
      updated
      updated_by
      __typename
    }
  }
`;
export const listDeviceLinksForSiteV1 = /* GraphQL */ `
  query ListDeviceLinksForSiteV1($segment_name: String!) {
    listDeviceLinksForSiteV1(segment_name: $segment_name) {
      acd_device_name
      acd_device_type
      acd_parent_device_id
      acd_child_device_id
      acd_subchild_device_id
      camera_name
      camera_system_id
      created
      created_by
      enabled
      event_links
      id
      system_id
      system_name
      updated
      updated_by
      __typename
    }
  }
`;
export const listDeviceLinksForSiteV2 = /* GraphQL */ `
  query ListDeviceLinksForSiteV2($segment_name: String!) {
    listDeviceLinksForSiteV2(segment_name: $segment_name) {
      acd_device_name
      acd_device_type
      acd_parent_device_id
      acd_child_device_id
      acd_subchild_device_id
      camera_name
      camera_system_id
      created
      created_by
      enabled
      device_link_events {
        created
        created_by
        device_link_id
        id
        event_name
        event_id
        updated
        updated_by
        __typename
      }
      id
      system_id
      system_name
      updated
      updated_by
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents {
    listEvents {
      created
      created_by
      device_type
      id
      name
      updated
      updated_by
      __typename
    }
  }
`;
export const listEventsV1 = /* GraphQL */ `
  query ListEventsV1 {
    listEventsV1 {
      created
      created_by
      device_type
      id
      name
      updated
      updated_by
      __typename
    }
  }
`;
export const listNotificationsV1 = /* GraphQL */ `
  query ListNotificationsV1 {
    listNotificationsV1 {
      dismissable
      links {
        target
        text
        __typename
      }
      message
      name
      type
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications {
    listNotifications {
      dismissable
      links {
        target
        text
        __typename
      }
      message
      name
      type
      __typename
    }
  }
`;
export const listParametersV1 = /* GraphQL */ `
  query ListParametersV1 {
    listParametersV1 {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const listSIGInfraUserSites = /* GraphQL */ `
  query ListSIGInfraUserSites($username: String!) {
    listSIGInfraUserSites(username: $username) {
      allSites
      sites
      __typename
    }
  }
`;
export const listSIGInfraUserSitesV1 = /* GraphQL */ `
  query ListSIGInfraUserSitesV1($username: String!) {
    listSIGInfraUserSitesV1(username: $username) {
      allSites
      sites
      __typename
    }
  }
`;
export const listSystems = /* GraphQL */ `
  query ListSystems {
    listSystems {
      auth_mode
      created
      created_by
      enabled
      id
      name
      secondary_system_id
      secondary_system_name
      segment_name
      segment_location
      segment_source
      srvr_alrm_srvc_path
      srvr_alrm_srvc_port
      srvr_cmd_srvc_path
      srvr_cmd_srvc_port
      srvr_cnf_srvc_path
      srvr_cnf_srvc_port
      srvr_vpc_endpoint
      updated
      updated_by
      __typename
    }
  }
`;
export const listSystemsV1 = /* GraphQL */ `
  query ListSystemsV1 {
    listSystemsV1 {
      auth_mode
      created
      created_by
      enabled
      id
      name
      secondary_system_id
      secondary_system_name
      segment_name
      segment_location
      segment_source
      srvr_alrm_srvc_path
      srvr_alrm_srvc_port
      srvr_cmd_srvc_path
      srvr_cmd_srvc_port
      srvr_cnf_srvc_path
      srvr_cnf_srvc_port
      srvr_vpc_endpoint
      updated
      updated_by
      __typename
    }
  }
`;
export const listSystemsForSite = /* GraphQL */ `
  query ListSystemsForSite($segment_name: String!) {
    listSystemsForSite(segment_name: $segment_name) {
      auth_mode
      created
      created_by
      enabled
      id
      name
      secondary_system_id
      secondary_system_name
      segment_name
      segment_location
      segment_source
      srvr_alrm_srvc_path
      srvr_alrm_srvc_port
      srvr_cmd_srvc_path
      srvr_cmd_srvc_port
      srvr_cnf_srvc_path
      srvr_cnf_srvc_port
      srvr_vpc_endpoint
      updated
      updated_by
      __typename
    }
  }
`;
export const listSystemsForSiteV1 = /* GraphQL */ `
  query ListSystemsForSiteV1($segment_name: String!) {
    listSystemsForSiteV1(segment_name: $segment_name) {
      auth_mode
      created
      created_by
      enabled
      id
      name
      secondary_system_id
      secondary_system_name
      segment_name
      segment_location
      segment_source
      srvr_alrm_srvc_path
      srvr_alrm_srvc_port
      srvr_cmd_srvc_path
      srvr_cmd_srvc_port
      srvr_cnf_srvc_path
      srvr_cnf_srvc_port
      srvr_vpc_endpoint
      updated
      updated_by
      __typename
    }
  }
`;
export const listUserPermission = /* GraphQL */ `
  query ListUserPermission($path: String!) {
    listUserPermission(path: $path) {
      HASPERMISSION
      __typename
    }
  }
`;
export const listUserPermissionV1 = /* GraphQL */ `
  query ListUserPermissionV1($path: String!) {
    listUserPermissionV1(path: $path) {
      HASPERMISSION
      __typename
    }
  }
`;
export const listUserSites = /* GraphQL */ `
  query ListUserSites($path: String!) {
    listUserSites(path: $path) {
      SegmentName
      SegmentLocation
      SegmentSource
      SiteCode
      __typename
    }
  }
`;
export const listUserSitesV1 = /* GraphQL */ `
  query ListUserSitesV1($path: String!) {
    listUserSitesV1(path: $path) {
      SegmentName
      SegmentLocation
      SegmentSource
      SiteCode
      __typename
    }
  }
`;
export const SNSPublishDeviceActionCreation = /* GraphQL */ `
  query SNSPublishDeviceActionCreation(
    $deviceAction: DeviceActionForSiteInputV1
  ) {
    SNSPublishDeviceActionCreation(deviceAction: $deviceAction) {
      MessageId
      SequenceNumber
      __typename
    }
  }
`;
export const SNSPublishDeviceActionDelete = /* GraphQL */ `
  query SNSPublishDeviceActionDelete(
    $deviceAction: DeviceActionForSiteInputV1
  ) {
    SNSPublishDeviceActionDelete(deviceAction: $deviceAction) {
      MessageId
      SequenceNumber
      __typename
    }
  }
`;
export const SNSPublishDeviceActionUpdate = /* GraphQL */ `
  query SNSPublishDeviceActionUpdate(
    $deviceAction: DeviceActionForSiteInputV1
  ) {
    SNSPublishDeviceActionUpdate(deviceAction: $deviceAction) {
      MessageId
      SequenceNumber
      __typename
    }
  }
`;
export const SNSPublishDeviceActionProcess = /* GraphQL */ `
  query SNSPublishDeviceActionProcess(
    $deviceAction: DeviceActionForSiteInputV1
  ) {
    SNSPublishDeviceActionProcess(deviceAction: $deviceAction) {
      MessageId
      SequenceNumber
      __typename
    }
  }
`;
