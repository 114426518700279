/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createActionDeviceTypeEventV1 = /* GraphQL */ `
  mutation CreateActionDeviceTypeEventV1(
    $input: CreateActionDeviceTypeEventInputV1!
  ) {
    createActionDeviceTypeEventV1(input: $input) {
      id
      device_event_id
      action_device_type_id
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const createActionParameterV1 = /* GraphQL */ `
  mutation CreateActionParameterV1($input: CreateActionParameterInputV1!) {
    createActionParameterV1(input: $input) {
      id
      action_id
      parameter_id
      parameter_name
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const createActionV1 = /* GraphQL */ `
  mutation CreateActionV1($input: CreateActionInputV1!) {
    createActionV1(input: $input) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const createConditionOperandV1 = /* GraphQL */ `
  mutation CreateConditionOperandV1($input: CreateConditionOperandInputV1!) {
    createConditionOperandV1(input: $input) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const createDeviceActionConditionGroupV1 = /* GraphQL */ `
  mutation CreateDeviceActionConditionGroupV1(
    $input: CreateDeviceActionConditionGroupInputV1!
  ) {
    createDeviceActionConditionGroupV1(input: $input) {
      id
      device_action_id
      device_action_conditions {
        id
        group_id
        operator
        operand_id
        operand_name
        value
        created
        created_by
        updated
        updated_by
        __typename
      }
      operator
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const createDeviceActionConditionV1 = /* GraphQL */ `
  mutation CreateDeviceActionConditionV1(
    $input: CreateDeviceActionConditionInputV1!
  ) {
    createDeviceActionConditionV1(input: $input) {
      id
      group_id
      operator
      operand_id
      operand_name
      value
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const createDeviceActionParameterV1 = /* GraphQL */ `
  mutation CreateDeviceActionParameterV1(
    $input: CreateDeviceActionParameterInputV1!
  ) {
    createDeviceActionParameterV1(input: $input) {
      id
      device_action_id
      action_id
      action_name
      parameter_id
      parameter_name
      value
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const createDeviceActionV1 = /* GraphQL */ `
  mutation CreateDeviceActionV1($input: CreateDeviceActionInputV1!) {
    createDeviceActionV1(input: $input) {
      id
      site_code
      input_device_id
      output_device_id
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const createDeviceActionInputEventV1 = /* GraphQL */ `
  mutation CreateDeviceActionInputEventV1(
    $input: CreateDeviceActionInputEventInputV1!
  ) {
    createDeviceActionInputEventV1(input: $input) {
      id
      device_action_id
      device_event_id
      device_event_name
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const createDeviceActionOutputActionV1 = /* GraphQL */ `
  mutation CreateDeviceActionOutputActionV1(
    $input: CreateDeviceActionOutputActionInputV1!
  ) {
    createDeviceActionOutputActionV1(input: $input) {
      id
      device_action_id
      action_id
      action_name
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const createDeviceEventV1 = /* GraphQL */ `
  mutation CreateDeviceEventV1($input: CreateDeviceEventInputV1!) {
    createDeviceEventV1(input: $input) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const createDeviceLink = /* GraphQL */ `
  mutation CreateDeviceLink($input: CreateDeviceLinkInput!) {
    createDeviceLink(input: $input) {
      acd_device_name
      acd_device_type
      acd_parent_device_id
      acd_child_device_id
      acd_subchild_device_id
      camera_name
      camera_system_id
      created
      created_by
      enabled
      event_links
      id
      system_id
      system_name
      updated
      updated_by
      __typename
    }
  }
`;
export const createDeviceLinkV1 = /* GraphQL */ `
  mutation CreateDeviceLinkV1($input: CreateDeviceLinkInput!) {
    createDeviceLinkV1(input: $input) {
      acd_device_name
      acd_device_type
      acd_parent_device_id
      acd_child_device_id
      acd_subchild_device_id
      camera_name
      camera_system_id
      created
      created_by
      enabled
      event_links
      id
      system_id
      system_name
      updated
      updated_by
      __typename
    }
  }
`;
export const createDeviceLinkEvent = /* GraphQL */ `
  mutation CreateDeviceLinkEvent($input: CreateDeviceLinkEventInput!) {
    createDeviceLinkEvent(input: $input) {
      created
      created_by
      device_link_id
      id
      event_name
      event_id
      updated
      updated_by
      __typename
    }
  }
`;
export const createDeviceLinkEventV1 = /* GraphQL */ `
  mutation CreateDeviceLinkEventV1($input: CreateDeviceLinkEventInput!) {
    createDeviceLinkEventV1(input: $input) {
      created
      created_by
      device_link_id
      id
      event_name
      event_id
      updated
      updated_by
      __typename
    }
  }
`;
export const createDeviceTypeV1 = /* GraphQL */ `
  mutation CreateDeviceTypeV1($input: CreateDeviceTypeInputV1!) {
    createDeviceTypeV1(input: $input) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const createActionDeviceTypeV1 = /* GraphQL */ `
  mutation CreateActionDeviceTypeV1($input: CreateActionDeviceTypeInputV1!) {
    createActionDeviceTypeV1(input: $input) {
      id
      action_id
      device_type_id
      action_device_type
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const createParameterV1 = /* GraphQL */ `
  mutation CreateParameterV1($input: CreateParameterInputV1!) {
    createParameterV1(input: $input) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const createSiteCameras = /* GraphQL */ `
  mutation CreateSiteCameras($input: CreateSiteCamerasInput!) {
    createSiteCameras(input: $input) {
      siteCode
      created
      cameras {
        name
        description
        systemIdentifier
        __typename
      }
      __typename
    }
  }
`;
export const createSiteCamerasV1 = /* GraphQL */ `
  mutation CreateSiteCamerasV1($input: CreateSiteCamerasInput!) {
    createSiteCamerasV1(input: $input) {
      siteCode
      created
      cameras {
        name
        description
        systemIdentifier
        __typename
      }
      __typename
    }
  }
`;
export const createSystem = /* GraphQL */ `
  mutation CreateSystem($input: CreateSystemInput!) {
    createSystem(input: $input) {
      auth_mode
      created
      created_by
      enabled
      id
      name
      secondary_system_id
      secondary_system_name
      segment_name
      segment_location
      segment_source
      srvr_alrm_srvc_path
      srvr_alrm_srvc_port
      srvr_cmd_srvc_path
      srvr_cmd_srvc_port
      srvr_cnf_srvc_path
      srvr_cnf_srvc_port
      srvr_vpc_endpoint
      updated
      updated_by
      __typename
    }
  }
`;
export const createSystemV1 = /* GraphQL */ `
  mutation CreateSystemV1($input: CreateSystemInput!) {
    createSystemV1(input: $input) {
      auth_mode
      created
      created_by
      enabled
      id
      name
      secondary_system_id
      secondary_system_name
      segment_name
      segment_location
      segment_source
      srvr_alrm_srvc_path
      srvr_alrm_srvc_port
      srvr_cmd_srvc_path
      srvr_cmd_srvc_port
      srvr_cnf_srvc_path
      srvr_cnf_srvc_port
      srvr_vpc_endpoint
      updated
      updated_by
      __typename
    }
  }
`;
export const createUserAction = /* GraphQL */ `
  mutation CreateUserAction($input: UserActionInput!) {
    createUserAction(input: $input) {
      actionId
      actionName
      parameters
      timestamp
      ttl
      username
      __typename
    }
  }
`;
export const deleteActionV1 = /* GraphQL */ `
  mutation DeleteActionV1($id: String!) {
    deleteActionV1(id: $id) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteActionParameterV1 = /* GraphQL */ `
  mutation DeleteActionParameterV1($id: String!) {
    deleteActionParameterV1(id: $id) {
      id
      action_id
      parameter_id
      parameter_name
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteConditionOperandV1 = /* GraphQL */ `
  mutation DeleteConditionOperandV1($id: String!) {
    deleteConditionOperandV1(id: $id) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteDeviceActionConditionGroupV1 = /* GraphQL */ `
  mutation DeleteDeviceActionConditionGroupV1(
    $input: DeleteDeviceActionConditionGroupInputV1!
  ) {
    deleteDeviceActionConditionGroupV1(input: $input) {
      id
      device_action_id
      device_action_conditions {
        id
        group_id
        operator
        operand_id
        operand_name
        value
        created
        created_by
        updated
        updated_by
        __typename
      }
      operator
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteDeviceActionConditionV1 = /* GraphQL */ `
  mutation DeleteDeviceActionConditionV1(
    $input: DeleteDeviceActionConditionInputV1!
  ) {
    deleteDeviceActionConditionV1(input: $input) {
      id
      group_id
      operator
      operand_id
      operand_name
      value
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteDeviceActionParameterV1 = /* GraphQL */ `
  mutation DeleteDeviceActionParameterV1(
    $input: DeleteDeviceActionParameterInputV1!
  ) {
    deleteDeviceActionParameterV1(input: $input) {
      id
      device_action_id
      action_id
      action_name
      parameter_id
      parameter_name
      value
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteDeviceActionV1 = /* GraphQL */ `
  mutation DeleteDeviceActionV1($input: DeleteDeviceActionInputV1!) {
    deleteDeviceActionV1(input: $input) {
      id
      site_code
      input_device_id
      output_device_id
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteDeviceActionInputEventV1 = /* GraphQL */ `
  mutation DeleteDeviceActionInputEventV1($id: String!, $updated_by: String!) {
    deleteDeviceActionInputEventV1(id: $id, updated_by: $updated_by) {
      id
      device_action_id
      device_event_id
      device_event_name
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteDeviceActionOutputActionV1 = /* GraphQL */ `
  mutation DeleteDeviceActionOutputActionV1(
    $id: String!
    $updated_by: String!
  ) {
    deleteDeviceActionOutputActionV1(id: $id, updated_by: $updated_by) {
      id
      device_action_id
      action_id
      action_name
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteDeviceEventV1 = /* GraphQL */ `
  mutation DeleteDeviceEventV1($id: String!) {
    deleteDeviceEventV1(id: $id) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteActionDeviceTypeEventV1 = /* GraphQL */ `
  mutation DeleteActionDeviceTypeEventV1($id: String!) {
    deleteActionDeviceTypeEventV1(id: $id) {
      id
      device_event_id
      action_device_type_id
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteDeviceLink = /* GraphQL */ `
  mutation DeleteDeviceLink($input: DeleteDeviceLinkInput!) {
    deleteDeviceLink(input: $input) {
      acd_device_name
      acd_device_type
      acd_parent_device_id
      acd_child_device_id
      acd_subchild_device_id
      camera_name
      camera_system_id
      created
      created_by
      enabled
      event_links
      id
      system_id
      system_name
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteDeviceLinkV1 = /* GraphQL */ `
  mutation DeleteDeviceLinkV1($input: DeleteDeviceLinkInput!) {
    deleteDeviceLinkV1(input: $input) {
      acd_device_name
      acd_device_type
      acd_parent_device_id
      acd_child_device_id
      acd_subchild_device_id
      camera_name
      camera_system_id
      created
      created_by
      enabled
      event_links
      id
      system_id
      system_name
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteDeviceLinkEvent = /* GraphQL */ `
  mutation DeleteDeviceLinkEvent($input: DeleteDeviceLinkEventInput!) {
    deleteDeviceLinkEvent(input: $input) {
      created
      created_by
      device_link_id
      id
      event_name
      event_id
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteDeviceLinkEventV1 = /* GraphQL */ `
  mutation DeleteDeviceLinkEventV1($input: DeleteDeviceLinkEventInput!) {
    deleteDeviceLinkEventV1(input: $input) {
      created
      created_by
      device_link_id
      id
      event_name
      event_id
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteDeviceTypeV1 = /* GraphQL */ `
  mutation DeleteDeviceTypeV1($id: String!) {
    deleteDeviceTypeV1(id: $id) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteActionDeviceTypeV1 = /* GraphQL */ `
  mutation DeleteActionDeviceTypeV1($id: String!) {
    deleteActionDeviceTypeV1(id: $id) {
      id
      action_id
      device_type_id
      action_device_type
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteParameterV1 = /* GraphQL */ `
  mutation DeleteParameterV1($id: String!) {
    deleteParameterV1(id: $id) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteSystem = /* GraphQL */ `
  mutation DeleteSystem($input: DeleteSystemInput!) {
    deleteSystem(input: $input) {
      auth_mode
      created
      created_by
      enabled
      id
      name
      secondary_system_id
      secondary_system_name
      segment_name
      segment_location
      segment_source
      srvr_alrm_srvc_path
      srvr_alrm_srvc_port
      srvr_cmd_srvc_path
      srvr_cmd_srvc_port
      srvr_cnf_srvc_path
      srvr_cnf_srvc_port
      srvr_vpc_endpoint
      updated
      updated_by
      __typename
    }
  }
`;
export const deleteSystemV1 = /* GraphQL */ `
  mutation DeleteSystemV1($input: DeleteSystemInput!) {
    deleteSystemV1(input: $input) {
      auth_mode
      created
      created_by
      enabled
      id
      name
      secondary_system_id
      secondary_system_name
      segment_name
      segment_location
      segment_source
      srvr_alrm_srvc_path
      srvr_alrm_srvc_port
      srvr_cmd_srvc_path
      srvr_cmd_srvc_port
      srvr_cnf_srvc_path
      srvr_cnf_srvc_port
      srvr_vpc_endpoint
      updated
      updated_by
      __typename
    }
  }
`;
export const updateActionV1 = /* GraphQL */ `
  mutation UpdateActionV1($input: UpdateActionInputV1!) {
    updateActionV1(input: $input) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const updateActionParameterV1 = /* GraphQL */ `
  mutation UpdateActionParameterV1($input: UpdateActionParameterInputV1!) {
    updateActionParameterV1(input: $input) {
      id
      action_id
      parameter_id
      parameter_name
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const updateConditionOperandV1 = /* GraphQL */ `
  mutation UpdateConditionOperandV1($input: UpdateConditionOperandInputV1!) {
    updateConditionOperandV1(input: $input) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const updateActionDeviceTypeEventV1 = /* GraphQL */ `
  mutation UpdateActionDeviceTypeEventV1(
    $input: UpdateActionDeviceTypeEventInputV1!
  ) {
    updateActionDeviceTypeEventV1(input: $input) {
      id
      device_event_id
      action_device_type_id
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const updateDeviceActionConditionGroupV1 = /* GraphQL */ `
  mutation UpdateDeviceActionConditionGroupV1(
    $input: UpdateDeviceActionConditionGroupInputV1!
  ) {
    updateDeviceActionConditionGroupV1(input: $input) {
      id
      device_action_id
      device_action_conditions {
        id
        group_id
        operator
        operand_id
        operand_name
        value
        created
        created_by
        updated
        updated_by
        __typename
      }
      operator
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const updateDeviceActionConditionV1 = /* GraphQL */ `
  mutation UpdateDeviceActionConditionV1(
    $input: UpdateDeviceActionConditionInputV1!
  ) {
    updateDeviceActionConditionV1(input: $input) {
      id
      group_id
      operator
      operand_id
      operand_name
      value
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const updateDeviceActionParameterV1 = /* GraphQL */ `
  mutation UpdateDeviceActionParameterV1(
    $input: UpdateDeviceActionParameterInputV1!
  ) {
    updateDeviceActionParameterV1(input: $input) {
      id
      device_action_id
      action_id
      action_name
      parameter_id
      parameter_name
      value
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const updateDeviceActionV1 = /* GraphQL */ `
  mutation UpdateDeviceActionV1($input: UpdateDeviceActionInputV1!) {
    updateDeviceActionV1(input: $input) {
      id
      site_code
      input_device_id
      output_device_id
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const updateDeviceActionInputEventV1 = /* GraphQL */ `
  mutation UpdateDeviceActionInputEventV1(
    $input: UpdateDeviceActionInputEventInputV1!
  ) {
    updateDeviceActionInputEventV1(input: $input) {
      id
      device_action_id
      device_event_id
      device_event_name
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const updateDeviceActionOutputActionV1 = /* GraphQL */ `
  mutation UpdateDeviceActionOutputActionV1(
    $input: UpdateDeviceActionOutputActionInputV1!
  ) {
    updateDeviceActionOutputActionV1(input: $input) {
      id
      device_action_id
      action_id
      action_name
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const updateDeviceEventV1 = /* GraphQL */ `
  mutation UpdateDeviceEventV1($input: UpdateDeviceEventInputV1!) {
    updateDeviceEventV1(input: $input) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const updateDeviceLink = /* GraphQL */ `
  mutation UpdateDeviceLink($input: UpdateDeviceLinkInput!) {
    updateDeviceLink(input: $input) {
      acd_device_name
      acd_device_type
      acd_parent_device_id
      acd_child_device_id
      acd_subchild_device_id
      camera_name
      camera_system_id
      created
      created_by
      enabled
      event_links
      id
      system_id
      system_name
      updated
      updated_by
      __typename
    }
  }
`;
export const updateDeviceLinkV1 = /* GraphQL */ `
  mutation UpdateDeviceLinkV1($input: UpdateDeviceLinkInput!) {
    updateDeviceLinkV1(input: $input) {
      acd_device_name
      acd_device_type
      acd_parent_device_id
      acd_child_device_id
      acd_subchild_device_id
      camera_name
      camera_system_id
      created
      created_by
      enabled
      event_links
      id
      system_id
      system_name
      updated
      updated_by
      __typename
    }
  }
`;
export const updateDeviceLinkEvent = /* GraphQL */ `
  mutation UpdateDeviceLinkEvent($input: UpdateDeviceLinkEventInput!) {
    updateDeviceLinkEvent(input: $input) {
      created
      created_by
      device_link_id
      id
      event_name
      event_id
      updated
      updated_by
      __typename
    }
  }
`;
export const updateDeviceLinkEventV1 = /* GraphQL */ `
  mutation UpdateDeviceLinkEventV1($input: UpdateDeviceLinkEventInput!) {
    updateDeviceLinkEventV1(input: $input) {
      created
      created_by
      device_link_id
      id
      event_name
      event_id
      updated
      updated_by
      __typename
    }
  }
`;
export const updateDeviceTypeV1 = /* GraphQL */ `
  mutation UpdateDeviceTypeV1($input: UpdateDeviceTypeInputV1!) {
    updateDeviceTypeV1(input: $input) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const updateActionDeviceTypeV1 = /* GraphQL */ `
  mutation UpdateActionDeviceTypeV1($input: UpdateActionDeviceTypeInputV1!) {
    updateActionDeviceTypeV1(input: $input) {
      id
      action_id
      device_type_id
      action_device_type
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const updateParameterV1 = /* GraphQL */ `
  mutation UpdateParameterV1($input: UpdateParameterInputV1!) {
    updateParameterV1(input: $input) {
      id
      name
      description
      additional_info
      created
      created_by
      updated
      updated_by
      __typename
    }
  }
`;
export const updateSystem = /* GraphQL */ `
  mutation UpdateSystem($input: UpdateSystemInput!) {
    updateSystem(input: $input) {
      auth_mode
      created
      created_by
      enabled
      id
      name
      secondary_system_id
      secondary_system_name
      segment_name
      segment_location
      segment_source
      srvr_alrm_srvc_path
      srvr_alrm_srvc_port
      srvr_cmd_srvc_path
      srvr_cmd_srvc_port
      srvr_cnf_srvc_path
      srvr_cnf_srvc_port
      srvr_vpc_endpoint
      updated
      updated_by
      __typename
    }
  }
`;
export const updateSystemV1 = /* GraphQL */ `
  mutation UpdateSystemV1($input: UpdateSystemInput!) {
    updateSystemV1(input: $input) {
      auth_mode
      created
      created_by
      enabled
      id
      name
      secondary_system_id
      secondary_system_name
      segment_name
      segment_location
      segment_source
      srvr_alrm_srvc_path
      srvr_alrm_srvc_port
      srvr_cmd_srvc_path
      srvr_cmd_srvc_port
      srvr_cnf_srvc_path
      srvr_cnf_srvc_port
      srvr_vpc_endpoint
      updated
      updated_by
      __typename
    }
  }
`;
