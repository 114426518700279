import {
  DeviceTypeV1,
  DeviceV1,
} from 'src/API';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  listDeviceTypes,
} from '../ActionsSetup/utils';
import {
  listDevicesForSite,
} from './utils';
import {
  useQuery,
  useQueryClient,
} from 'react-query';
import { Alert, AppLayout } from '@amzn/awsui-components-react';
import DeviceActionsTablePanel from './DeviceActionsTablePanel';
import { ForceAwakensConfigNavigation } from '../navigation';
import { ForceAwakensStateInterface } from 'src/stores/app';
import { RouteComponentProps } from 'react-router-dom';
import { State } from '@hookstate/core';

interface IActions extends RouteComponentProps {
  forceAwakensState: State<ForceAwakensStateInterface>;
}

export default function DeviceActions(props: IActions) {

  const [error, setError] = useState<string | undefined>();

  const stage = useRef<string>('beta');

  const queryClient = useQueryClient();

  useQuery<DeviceTypeV1[]>(
    ['deviceTypes'],
    () => listDeviceTypes(),
    {
      onError: (error) => {
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  useQuery<DeviceV1[]>(
    ['devicesForSite'],
    async () => await listDevicesForSite(props.forceAwakensState.selectedSite.value?.siteCode),
    {
      enabled: !!props.forceAwakensState.selectedSite.value?.siteCode,
      onError: (error) => {
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      onSuccess() {
        setError(undefined);
      },
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  useEffect(() => {
    if (props.forceAwakensState.selectedSite.value?.siteCode) {
      queryClient.refetchQueries('deviceActionsForSite');
      queryClient.refetchQueries('devicesForSite');
    }
  }, [props.forceAwakensState.selectedSite]);

  return(
    <div id='DeviceActions'>
      <AppLayout
        content={
          <>
            {!props.forceAwakensState.deviceActionsEnabled.value
            &&
            <Alert
              type='warning'
            >
              <p>
                The Device Actions feature is currently disabled. To enable, set the /force-awakens/{stage.current.toLowerCase() === 'test' ? 'beta' : stage.current.toLowerCase()}/deviceActionsEnabled parameter store value to true.
              </p>
            </Alert>}
            {error
              ? <Alert>{error}</Alert>
              : <DeviceActionsTablePanel {...props} forceAwakensState={props.forceAwakensState} />}
          </>}
        contentType='table'
        disableContentPaddings
        headerSelector='#topNavigation'
        navigation={<ForceAwakensConfigNavigation forceAwakensState={props.forceAwakensState}/>}
        stickyNotifications
        toolsHide
      />
    </div>
  );
}


