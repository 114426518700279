import {
  ActionDeviceTypeV1,
  ActionV1,
  CreateActionDeviceTypeInputV1,
  DeviceTypeForActionV1,
  DeviceTypeV1,
} from 'src/API';
import {
  Alert,
  FormField,
  Header,
  Multiselect,
  SelectProps,
  Spinner,
  Table,
} from '@amzn/awsui-components-react';
import {
  TableEmptyState,
  TableNoMatchState,
  DefaultPageSize,
} from './ActionDeviceTypesTableConfig';
import {
  createActionDeviceType,
  deleteActionDeviceType,
  listDeviceTypes,
  listDeviceTypesForAction,
} from './utils';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { ActionDeviceType } from 'src/constants/Constants';
import { ForceAwakensStateInterface } from 'src/stores/app';
import { RouteComponentProps } from 'react-router-dom';
import { State } from '@hookstate/core';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';

interface IActionDeviceTypesTablePanelProps extends RouteComponentProps {
  forceAwakensState: State<ForceAwakensStateInterface>;
  action: ActionV1;
  actionDeviceType: ActionDeviceType;
  setSelectedActionDeviceType: Function;
}

export default function ActionDeviceTypesTablePanel(props: IActionDeviceTypesTablePanelProps) {

  const [actionDeviceTypesForAction, setActionDeviceTypesForAction] = useState<DeviceTypeForActionV1[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<DeviceTypeV1[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [selectedActionDeviceType, setSelectedActionDeviceType] = useState<DeviceTypeForActionV1>();
  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<SelectProps.Option[]>([]);

  const [bundle, isBundleLoading] = useBundle('components.ActionsSetup.ActionDeviceTypesTablePanel');

  const queryClient = useQueryClient();

  useQuery<DeviceTypeV1[]>(
    ['deviceTypes'],
    () => listDeviceTypes(),
    {
      onError: (error) => {
        setDeviceTypes([]);
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      onSuccess: (data) =>  setDeviceTypes(data),
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  const actionDeviceTypesQuery = useQuery<DeviceTypeForActionV1[]>(
    ['actionDeviceTypesForAction', props.actionDeviceType],
    () => listDeviceTypesForAction(props.action.id),
    {
      onError: (error) => {
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      onSuccess: (data) => {
        setActionDeviceTypesForAction(data.filter(adt => adt.action_device_type === props.actionDeviceType));
        setSelectedDeviceTypes(data
          .filter(adt => adt.action_device_type === props.actionDeviceType)
          .map((actionDeviceType) => {
          return {
            label: actionDeviceType.device_type_description,
            value: actionDeviceType.device_type_id,
          };
        })
        .sort((a, b) => {
          return a.label < b.label ? -1 : 1
        }));
      },
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  const createActionDeviceTypeMutation = useMutation<ActionDeviceTypeV1 | undefined, Error, CreateActionDeviceTypeInputV1>(
    async (input: CreateActionDeviceTypeInputV1) => {
      return await createActionDeviceType(input);
    },
    {
      onSettled: (data, error) => {
        if (error) {
          if (!((error as any).errors[0].path[0] === 'createActionDeviceTypeV1'
          && (error as any).errors[0]?.message.startsWith('RDSHttp:{"message":"ERROR: duplicate key value violates unique constraint'))) {
            setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
            return;
          }
        }
        const newActionDeviceTypes = [...actionDeviceTypesForAction];
        if (data) {
          setSelectedDeviceTypes([]);
          newActionDeviceTypes.push({
            __typename: 'DeviceTypeForActionV1',
            action_id: data.action_id,
            action_name: props.action.name,
            action_device_type: data.action_device_type,
            created: data.created,
            created_by: data.created_by,
            device_type_id: data.device_type_id,
            device_type_name: deviceTypes.find(dt => dt.id === data.device_type_id)!.name,
            device_type_description: deviceTypes.find(dt => dt.id === data.device_type_id)!.description,
            id: data.id,
            updated: data.updated,
            updated_by: data.updated_by,
          });
          setSelectedDeviceTypes([...newActionDeviceTypes.map(sp => 
            ({
              label: sp.device_type_description,
              value: sp.device_type_id,
            }) as SelectProps.Option
          )]);
        }
        actionDeviceTypesQuery.refetch();
      },
    },
  );

  const deleteActionDeviceTypeMutation = useMutation<ActionDeviceTypeV1 | undefined, Error, string>(
    async (id: string) => {
      return await deleteActionDeviceType(id);
    },
    {
      onSettled: (data, error) => {
        if (error) {
          if (!((error as any).errors[0].path[0] === 'deleteActionDeviceTypeV1'
          && (error as any).errors[0]?.message.startsWith("Error invoking method 'get(java.lang.Integer)' in java.util.ArrayList at velocity"))) {
            setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
            return;
          }
        }
        if (data) {
          setSelectedDeviceTypes([]);
          const newActionParameters = [...actionDeviceTypesForAction.filter(ap => ap.id !== data.id)];
          setSelectedDeviceTypes([...newActionParameters.map(sp => 
            ({
              label: sp.device_type_description,
              value: sp.device_type_id,
            }) as SelectProps.Option
          )]);
          actionDeviceTypesQuery.refetch();
          if (selectedActionDeviceType?.id === data.id) setSelectedActionDeviceType(undefined);
        }
      },
    },
  );

  const { items, actions } = useCollection(
    actionDeviceTypesForAction.sort((a, b) => a.device_type_description < b.device_type_description ? -1 : 1),
    {
      filtering: {
        empty: <TableEmptyState title={isBundleLoading ? 'No Device Types Found' : bundle.getMessage('no-device-types-found')} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: DefaultPageSize.pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const deviceTypeMultiselectHandler = async (selectedOptions: SelectProps.Option[]) => {
    selectedOptions.forEach(async so => {
      const existingActionParameter = actionDeviceTypesForAction.find(ap => ap.device_type_id === so.value);
      if (!existingActionParameter || existingActionParameter.id === '') {
        const createActionDeviceTypeInput: CreateActionDeviceTypeInputV1 = {
          action_id: props.action.id,
          device_type_id: so.value!,
          action_device_type: props.actionDeviceType,
          created_by: props.forceAwakensState.username.value!,
        };
        await createActionDeviceTypeMutation.mutateAsync(createActionDeviceTypeInput);
      }
    });
    if (selectedOptions.length < actionDeviceTypesForAction.length) {
      const unselectedActionDeviceType = actionDeviceTypesForAction.find(adt => selectedOptions.findIndex(so => so.value === adt.device_type_id) === -1);
      if (unselectedActionDeviceType) await deleteActionDeviceTypeMutation.mutateAsync(unselectedActionDeviceType.id);
    }
  };

  const ColumnDefinitions: TableProps.ColumnDefinition<DeviceTypeForActionV1>[] = [
    {
      cell: item => item.device_type_description,
      header: 'Name',
      id: 'name',
    },
  ];

  useEffect(() => {
    props.setSelectedActionDeviceType(selectedActionDeviceType ? selectedActionDeviceType : undefined);
    queryClient.fetchQuery(['actionDeviceTypeEvents']);
  }, [selectedActionDeviceType]);

  useEffect(() => {
    if (props.action.id) actionDeviceTypesQuery.refetch();
  }, [props.action.id]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <div id='ActionDeviceTypesTablePanel'>
      {error &&
      <Alert
        dismissible
        onDismiss={() => setError(undefined)}
        type='error'
      >
        {error}
      </Alert>}
      <FormField label={props.actionDeviceType === ActionDeviceType.input ? bundle.getMessage('select-input-device-types') : bundle.getMessage('select-output-device-types')}>
        <Multiselect
          filteringType='auto'
          hideTokens
          onChange={({ detail }) => deviceTypeMultiselectHandler([...detail.selectedOptions])}
          options={deviceTypes
            .sort((a, b) => a.description < b.description ? -1 : 1)
            .map(dt => ({ label: dt.description, value: dt.id }))}
          selectedOptions={selectedDeviceTypes.map(sdt => {
            return {
              label: sdt.label,
              value: sdt.value,
            } as SelectProps.Option
          })}
        />
      </FormField>
      <Table
        columnDefinitions={ColumnDefinitions}
        header={
          <Header
            counter={`(${items.length})`}
          >
            {bundle.getMessage('device-types')}
          </Header>
        }
        items={items}
        loading={actionDeviceTypesQuery.isFetching || actionDeviceTypesQuery.isLoading}
        onSelectionChange={({detail}) => {
          const selectedActionDeviceType = actionDeviceTypesForAction.find(adt => adt.device_type_id === detail.selectedItems[0].device_type_id);
          setSelectedActionDeviceType(selectedActionDeviceType);
        }}
        selectedItems={selectedActionDeviceType ? [selectedActionDeviceType] : []}
        selectionType={props.actionDeviceType === ActionDeviceType.input ? 'single' : undefined}
      />
    </div>
  );
}
