import * as APIt from '../API';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom'
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useQuery,
  useQueryClient,
} from 'react-query';
import ActionsSetupTablePanel from './ActionsSetup/ActionsSetupTablePanel';
import { Alert } from '@amzn/awsui-components-react';
import Audit from './Audit';
import DeviceActions from './DeviceActions';
import DeviceLinks from './DeviceLinks';
import { ForceAwakensStateInterface } from 'src/stores/app';
import SelectSite from './common/SelectSite';
import { State } from '@hookstate/core';
import Systems from './Systems';
import { TopNav } from './TopNav';
import { fetchParameterStoreValue } from 'src/utils/ParameterStore';
import { fetchUserSites } from 'src/utils/UserSites';
import { isAdmin } from 'src/utils/UserPermissionUtils';

import '@amzn/awsui-global-styles/polaris.css';

export default function App(props: {forceAwakensState: State<ForceAwakensStateInterface>}) {

  const forceAwakensState = props.forceAwakensState;

  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [showSelectSite, setShowSelectSite] = useState<boolean>(false);
  const [userSites, setUserSites] = useState<APIt.Site[]>([]);
  const [userSitesLoading, setUserSitesLoading] = useState<boolean>(false);

  const stage = useRef<string>('beta');

  useQuery<APIt.Site[]>(
    ['userSites'],
    () => fetchUserSites(forceAwakensState.username.value ?? ''),
    {
      onError: (error) => {
        setError(JSON.stringify(error) ?? String(error));
      },
      onSettled: data => {
        setError(undefined);
        if (!data) {
          setUserSites([]);
          setUserSitesLoading(false);
          return;
        }
        setUserSites(data);
        let lastSelectedSite: { SegmentLocation: string; SegmentName: string; SegmentSource: string; siteCode: string } | null = null;
        const lastSelectedSiteFromLocalStorage = localStorage.getItem('lastSelectedSite');
        if (lastSelectedSiteFromLocalStorage && props.forceAwakensState.selectedSite.value === undefined) {
          try {
            lastSelectedSite = JSON.parse(lastSelectedSiteFromLocalStorage ?? '{}');
            if (lastSelectedSite?.SegmentLocation
              && lastSelectedSite?.SegmentName
              && lastSelectedSite?.SegmentSource
              && lastSelectedSite?.siteCode
              && data.filter(userSite => userSite.SiteCode === lastSelectedSite?.siteCode).length > 0)
            {
              props.forceAwakensState.selectedSite.set({
                SegmentLocation: lastSelectedSite.SegmentLocation,
                SegmentName: lastSelectedSite.SegmentName,
                SegmentSource: lastSelectedSite.SegmentSource,
                siteCode: lastSelectedSite.siteCode,
              });
              setShowSelectSite(false);
            }
          } catch(error) {
            console.error('App() userSites query onSettled error:', error);
            setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
          }
        }
        const username = forceAwakensState.username.value ?? '';
        isAdmin(username).then((result) => {
          forceAwakensState?.isAdmin.set(result);
          setUserSitesLoading(false);
        });
      },
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  useQuery<string>(
    ['deviceActionsEnabled'],
    () => fetchParameterStoreValue(`/force-awakens/${stage.current.toLowerCase() === 'test' ? 'beta' : stage.current.toLowerCase()}/deviceActionsEnabled`),
    {
      onError: (error) => {
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      onSettled: data => {
        if (!data) {
          return;
        }
        const deviceActionsEnabled = data === 'true';
        props.forceAwakensState.deviceActionsEnabled.set(deviceActionsEnabled);
      },
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  const queryClient = useQueryClient();

  const refreshUserSites = () => {
    setUserSites([]);
    setUserSitesLoading(true);
    queryClient.refetchQueries(['userSites']).then(() => setUserSitesLoading(false));
  };

  const siteSelected = () => {
    setShowSelectSite(false);
  };

  useEffect(() => {
    console.log(`App() useEffect[darkMode]`);
    if (darkMode) {
      document.body.classList.add('awsui-polaris-dark-mode');
    } else {
      document.body.classList.remove('awsui-polaris-dark-mode');
    }
  }, [darkMode]);

  useEffect(() => {
    if (userSites.length === 0) refreshUserSites();
  }, [showSelectSite]);

  useEffect(() => {
    if (!forceAwakensState.value.selectedSite) setShowSelectSite(true);
    const init = async () => {
      refreshUserSites();
      const lastDarkMode = localStorage.getItem('darkMode');
      if (lastDarkMode) {
        setDarkMode(lastDarkMode === 'true');
      }
    }
    init();
  }, []);

  return (
    <>
      {error
      &&
      <Alert
        type='error'
      >
        {error}
      </Alert>}
      <div
        className='awsui'
        id='topNavigation'
        style={{position: 'sticky', top: 0, zIndex: 1002}}
      >
        <TopNav
          darkMode={darkMode}
          setDarkModeCallback={setDarkMode}
          setShowSelectSite={(v: boolean) => {
            setShowSelectSite(v);
          }}
          siteCode={forceAwakensState.selectedSite.value?.siteCode || ''}
          username={forceAwakensState.username.value || ''}
        />
      </div>
      {
        showSelectSite
        &&
        <SelectSite
          forceAwakensState={forceAwakensState}
          refreshUserSitesCallback={refreshUserSites}
          siteSelectedCallback={siteSelected}
          userSites={userSites}
          userSitesLoading={userSitesLoading}
        />
      }
      <BrowserRouter>
        <Switch>
          <Route exact path='/DeviceLinks' render={(props) => <DeviceLinks {...props} forceAwakensState={forceAwakensState} />} />
          <Route exact path='/CameraLinks' render={(props) => <DeviceLinks {...props} forceAwakensState={forceAwakensState} />} />
          {
            ((props.forceAwakensState.deviceActionsEnabled.value || props.forceAwakensState.isAdmin.value)
            &&
            (<Route exact path='/DeviceActions' render={(props) => <DeviceActions {...props} forceAwakensState={forceAwakensState}/>} />))
          }
          {
            ((forceAwakensState.isAdmin.value || forceAwakensState.isAdmin.value)
            &&
            (<Route exact path='/ActionsSetup' render={(props) => <ActionsSetupTablePanel {...props} forceAwakensState={forceAwakensState}/>} />))
          }
          {
            ((forceAwakensState.isAdmin.value || forceAwakensState.isAdmin.value)
            &&
            (<Route exact path='/Systems' render={(props) => <Systems {...props} forceAwakensState={forceAwakensState}/>} />))
          }
          {
            ((forceAwakensState.isAdmin.value || forceAwakensState.isAdmin.value)
            &&
            (<Route exact path='/Audit' render={(props) => <Audit {...props} forceAwakensState={forceAwakensState}/>} />))
          }
          <Route path='*' render={(props) => <DeviceLinks {...props} forceAwakensState={forceAwakensState} />} />
        </Switch>
      </BrowserRouter>
    </>
  );
}