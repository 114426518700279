import API, {
  GraphQLResult,
  graphqlOperation,
} from '@aws-amplify/api';
import { GetParameterStoreValueQuery } from '../API';
import { getParameterStoreValue } from 'src/graphql/queries';

export async function fetchParameterStoreValue(parameterName: string): Promise<string> {
  console.debug(`fetchParameterStoreValue() parameterName is ${parameterName}`);

  if (!parameterName || parameterName === '') return '';

  try {
    const getParameterStoreValueResponse = await API.graphql(graphqlOperation(getParameterStoreValue,
      {
        parameterName,
      })) as GraphQLResult<GetParameterStoreValueQuery>;
    let parameterStoreValue = getParameterStoreValueResponse.data?.getParameterStoreValue as string;
    console.debug(`fetchParameterStoreValue() parameterStoreValue is ${JSON.stringify(parameterStoreValue)}`);
    return(parameterStoreValue);
  } catch(error) {
    console.error(`fetchParameterStoreValue(): error is ${JSON.stringify(error)}`);
    throw error;
  }
}
