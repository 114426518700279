import API, {
  GraphQLResult,
  graphqlOperation,
} from '@aws-amplify/api';
import {
  ListSIGInfraUserSitesV1Query,
  ListUserSitesV1Query,
  Site,
} from '../API';
import {
  listSIGInfraUserSitesV1,
  listUserSitesV1,
} from 'src/graphql/queries';

export async function fetchUserSites(username: string): Promise<Site[]> {
  console.debug(`fetchUserSites() username is ${username}`);

  if (!username || username === '') return [];

  let stage = 'beta';
  try {
    const userSitesResponse = await API.graphql(graphqlOperation(listUserSitesV1,
      {
        // GLOBAL because all regions produce the same results
        path: `/FlexQuery/ForceAwakens_Segment_Lookup_V3/region/GLOBAL/stage/${stage === 'test' ? 'BETA' : stage.toUpperCase()}/?OutputFormat=json`
      })) as GraphQLResult<ListUserSitesV1Query>;
    let userSites = userSitesResponse.data?.listUserSitesV1 as Site[];
    console.debug(`fetchUserSites() userSites is ${JSON.stringify(userSites)}`);
    const sigInfraUserSitesResponse = await API.graphql(graphqlOperation(listSIGInfraUserSitesV1,
      {
        username: username
      })) as GraphQLResult<ListSIGInfraUserSitesV1Query>;
    let filteredUserSites = userSites;
    if (!sigInfraUserSitesResponse.data?.listSIGInfraUserSitesV1?.allSites) {
      const sigInfraUserSites = sigInfraUserSitesResponse.data?.listSIGInfraUserSitesV1?.sites;
      filteredUserSites = userSites.filter(userSite => {
        const siteCode = userSite.SiteCode;
        return sigInfraUserSites?.includes(siteCode);
      });
    }
    console.debug(`fetchUserSites() filteredUserSites is ${JSON.stringify(filteredUserSites)}`);
    return(filteredUserSites);
  } catch(error) {
    console.error(`fetchUserSites(): error is ${JSON.stringify(error)}`);
    throw error;
  }
}
