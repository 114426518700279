import { listSIGInfraUserSitesV1 } from 'src/graphql/queries';
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import * as APIt from "../API";

export const isAdmin = async (username: string): Promise<boolean> => {
  console.debug(`isAdmin(): username is ${username}`);

  return new Promise(async (resolve, reject) => {
    try {
      const sigInfraUserSitesResponse = await API.graphql(graphqlOperation(listSIGInfraUserSitesV1,
        {
          username: username
        })) as GraphQLResult<APIt.ListSIGInfraUserSitesV1Query>;
      if (sigInfraUserSitesResponse.data?.listSIGInfraUserSitesV1?.allSites) resolve(true);
    } catch(error) {
      console.error(`isAdmin(): error is ${JSON.stringify(error)}`);
    }
    resolve(false);
  });
}