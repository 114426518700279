import API, {
  GraphQLResult,
  graphqlOperation,
} from '@aws-amplify/api';
import {
  ListNotificationsV1Query,
  NotificationV1,
} from '../../API';
import { listNotificationsV1 } from 'src/graphql/queries';

export async function fetchNotifications(): Promise<NotificationV1[]> {
  try {
    const listNotificationsResponse = await API.graphql(graphqlOperation(listNotificationsV1, {})) as GraphQLResult<ListNotificationsV1Query>;
    const notifications = listNotificationsResponse.data?.listNotificationsV1 as NotificationV1[];
    console.debug(`fetchNotifications() notifications is ${JSON.stringify(notifications)}`);
    return(notifications);
  } catch(error) {
    console.error(`fetchNotifications(): error is ${JSON.stringify(error)}`);
    throw error;
  }
}
