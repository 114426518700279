import React, { Dispatch, SetStateAction } from 'react';
import { IoVideocam } from 'react-icons/io5';
import { SiGooglemaps } from "react-icons/si";
import { IconContext } from 'react-icons';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import * as APIt from "../../API";
import Box from '@amzn/awsui-components-react/polaris/box';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import Button from '@amzn/awsui-components-react/polaris/button';
import Toggle from '@amzn/awsui-components-react/polaris/toggle';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { useBundle } from '@amzn/react-arb-tools';
import { Spinner } from '@amzn/awsui-components-react';

interface ColumnDefinitionsProps {
  cameraImage: string | undefined;
  loading: boolean;
  blobUrl: string | undefined;
  timeStamp: string | undefined;
  isDoraClientVisible: boolean;
  isNonProd: boolean;
  setIsDoraClientVisible: Dispatch<SetStateAction<boolean>>;
  setDeviceId: Dispatch<SetStateAction<string>>;
  DoraClients: () => JSX.Element;
  fetchImage: (cameraId: string) => void;
}

export const ColumnDefinitions: (props: ColumnDefinitionsProps) => TableProps.ColumnDefinition<APIt.DeviceLinkV2>[]= ({timeStamp, blobUrl, cameraImage, loading, isDoraClientVisible, isNonProd, setIsDoraClientVisible, setDeviceId, DoraClients, fetchImage}) => [
  {
    id: 'enabled',
    header: <TranslateHeader value={'enabled'} />,
    cell: (item) => <Toggle checked={item.enabled === 'Y'} onChange={()=>{}}/>,
    sortingField: 'enabled',
    width: 120,
  },
  {
    id: 'system_name',
    header: <TranslateHeader value={'system'} />,
    cell: item => item.system_name,
  },
  {
    id: 'acd_device_name',
    header: <TranslateHeader value={'device-name'} />,
    cell: item => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word'}}>
        {item.acd_device_name}
      {isNonProd &&
        <div
          style={{
            display: 'inline-block',
          }}
        >
          <Popover
          triggerType="custom"
          size='large'
          dismissButton={false}
          fixedWidth={true}
          renderWithPortal={true}
          content={
            <div>
              {isDoraClientVisible && <DoraClients />}
            </div>
          }
          >
            <IconContext.Provider value={{ style: { verticalAlign: 'middle', cursor: 'pointer', marginLeft: '8px', width: '16px', height: '16px' } }}>
              <SiGooglemaps onClick={() => {
                setDeviceId(`${item.acd_parent_device_id}_${item.acd_child_device_id}_${item.acd_subchild_device_id}`);
                setIsDoraClientVisible(!isDoraClientVisible);
              }}
              />
            </IconContext.Provider>
          </Popover>
        </div>
      }
      </div>),
    sortingField: 'acd_device_name',
  },
  {
    id: 'acd_device_type',
    header: <TranslateHeader value={'device-type'} />,
    cell: item => item.acd_device_type,
  },
  {
    id: 'acd_parent_device_id',
    header: <TranslateHeader value={'device-id'} />,
    cell: item => item.acd_parent_device_id,
  },
  {
    id: 'acd_child_device_id',
    header: <TranslateHeader value={'child-device-id'} />,
    cell: item => item.acd_child_device_id,
  },
  {
    id: 'acd_subchild_device_id',
    header: <TranslateHeader value={'subchild-device-id'} />,
    cell: item => item.acd_subchild_device_id,
  },
  {
    id: 'camera_name',
    header: <TranslateHeader value={'camera-name'} />,
    cell: item => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word'}}>
        {item.camera_name}
        <Popover
          triggerType="custom"
          dismissButton={false}
          size='large'
          content={
            <div>
                {loading ? (
                  <Spinner />
                ) : (
                  cameraImage && (
                    <>
                      <img
                        src={ cameraImage }
                        style={{ width: "100%", height: "100%", objectFit: "contain" }}
                      />
                      {blobUrl && (
                        <>
                          <a href={blobUrl} target="_blank" rel="noopener noreferrer"><TranslateHeader value={'image-full-size'} /></a>
                          <div>{timeStamp}</div>
                        </>
                      )}
                    </>
                  )
                )}
            </div>
          }
        >
          <IconContext.Provider value={{ style: { verticalAlign: 'middle', cursor: 'pointer', marginLeft: '8px', width: '16px', height: '16px' } }}>
            <IoVideocam onClick={() => fetchImage(item.camera_system_id)} />
          </IconContext.Provider>
      </Popover>
      {isNonProd && <Popover
          triggerType="custom"
          size='large'
          dismissButton={false}
          fixedWidth={true}
          renderWithPortal={true}
          content={
            <div>
              {isDoraClientVisible && <DoraClients />}
            </div>
          }
        >
          <IconContext.Provider value={{ style: { verticalAlign: 'middle', cursor: 'pointer', marginLeft: '8px', width: '16px', height: '16px' } }}>
          <SiGooglemaps onClick={() => {
              setDeviceId(item.camera_system_id);
              setIsDoraClientVisible(!isDoraClientVisible);
            }}
          />
          </IconContext.Provider>
      </Popover>
      }
      </div>),
    sortingField: 'camera_name',
  },
  {
    id: 'event_links',
    header: <TranslateHeader value={'event-links'} />,
    cell: item => <div style={{ whiteSpace: 'normal', wordWrap: 'break-word'}}>{item.device_link_events?.map(link => link?.event_name.replace(/,\s*$/,'')).join(', ')}</div>,
    sortingField: 'event_links',
  },
  {
    id: 'camera_system_id',
    header: <TranslateHeader value={'camera-system-identifier'} />,
    cell: item => item.camera_system_id,
  },
  {
    id: 'created',
    header: <TranslateHeader value={'created'} />,
    cell: item => item.created,
  },
  {
    id: 'updated',
    header: <TranslateHeader value={'updated'} />,
    cell: item => item.updated,
  },
];

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.DeviceLinks.tableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)}</>;
}

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 100
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};

export const VisibleColumns = [
  'acd_device_name',
  'camera_name',
  'enabled',
  'event_links',
];