import {
  ActionParameterV1,
  CreateDeviceActionParameterInputV1,
  DeleteDeviceActionParameterInputV1,
  DeviceActionOutputActionV1,
  DeviceActionParameterV1,
  ParameterV1,
  UpdateDeviceActionParameterInputV1,
} from 'src/API';
import {
  Alert,
  Box,
  Button,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  Table,
  TableProps,
} from '@amzn/awsui-components-react';
import {
  DefaultPageSize,
  TableEmptyState,
  TableNoMatchState,
} from './DeviceActionParametersTableConfig';
import {
  createDeviceActionParameter,
  deleteDeviceActionParameter,
  listActionParameters,
  listDeviceActionOutputActionsForDeviceActionId,
  listDeviceActionParametersForDeviceActionId,
  updateDeviceActionParameter,
} from './utils';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { ForceAwakensStateInterface } from 'src/stores/app';
import { State } from '@hookstate/core';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { listParameters } from '../ActionsSetup/utils';

class ParameterValidationError extends Error {
  public details: any;

  constructor(message: string, details?: any) {
    super(message);
    this.name = 'ParameterValidationError';
    this.details = details;
    Object.setPrototypeOf(this, ParameterValidationError.prototype);
  }
}

interface IDeviceActionParametersTablePanel {
  forceAwakensState: State<ForceAwakensStateInterface>;
  deviceActionId: string;
  savedParameters: Function;
}

export default function DeviceActionParametersTablePanel(props: IDeviceActionParametersTablePanel) {
  console.log(`DeviceActionParametersTablePanel() props.deviceActionId is ${JSON.stringify(props.deviceActionId)}`);

  const queryClient = useQueryClient();

  const [actionOptions, setActionOptions] = useState<SelectProps.Options>([]);
  const [actionParameters, setActionParameters] = useState<ActionParameterV1[]>([]);
  const [deviceActionParameters, setDeviceActionParameters] = useState<DeviceActionParameterV1[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [selectedActionOption, setSelectedActionOption] = useState<SelectProps.Option | null>(null);
  const [selectedDeviceActionParameters, setSelectedDeviceActionParameters] = useState<DeviceActionParameterV1[]>([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.DeviceActions.DeviceActionParametersTablePanel');

  const { items, actions: collectionActions, paginationProps } = useCollection(
    deviceActionParameters,
    {
      filtering: {
        empty: <TableEmptyState title={isBundleLoading ? 'No Parameters Found' : bundle.getMessage('no-parameters-found')} />,
        noMatch: <TableNoMatchState onClearFilter={() => collectionActions.setFiltering('')} />,
      },
      pagination: { pageSize: DefaultPageSize.pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  useQuery<ParameterV1[]>(
    ['parameters'],
    () => listParameters(),
    {
      onError: (error) => {
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  const actionParametersQuery = useQuery(
    ['actionParameters'],
    async () => await listActionParameters(selectedActionOption!.value!),
    {
      enabled: !!props.deviceActionId && !!selectedActionOption?.value,
      onSettled: data => {
        data && setActionParameters(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  useQuery(
    ['deviceActionOutputActions'],
    async () => await listDeviceActionOutputActionsForDeviceActionId(props.deviceActionId),
    {
      enabled: !!props.deviceActionId,
      onSettled: async data => {
        const deviceActions = data as DeviceActionOutputActionV1[];
        const deviceActionOptions = deviceActions
          ?.filter((daoa): daoa is DeviceActionOutputActionV1 => daoa !== null && !!daoa.action_name)
          ?.map((daoa) => ({label: daoa.action_name!, value: daoa.action_id}))
          ?.sort((a, b) => a.label < b.label ? -1 : 1);
        if (!deviceActionOptions || deviceActionOptions.length === 0) {
          setActionOptions([]);
          setSelectedActionOption(null);
          return;
        }
        const newDeviceActionOptions = [...deviceActionOptions];
        for (const deviceActionOption of deviceActionOptions) {
          const deviceActionParameters = await listActionParameters(deviceActionOption.value);
          if (deviceActionParameters === null || deviceActionParameters.findIndex(dap => dap.action_id === deviceActionOption.value) === -1) {
            newDeviceActionOptions.splice(newDeviceActionOptions.indexOf(deviceActionOption), 1);
          }
        }
        setActionOptions(newDeviceActionOptions);
        if (newDeviceActionOptions?.length > 0) setSelectedActionOption(newDeviceActionOptions[0]);
      },
      refetchOnWindowFocus: false,
    }
  );

  const deviceActionParametersQuery = useQuery(
    ['deviceActionParameters'],
    async () => await listDeviceActionParametersForDeviceActionId(props.deviceActionId),
    {
      enabled: !!props.deviceActionId && !!selectedActionOption?.value,
      onSettled: data => {
        const deviceActionParameters = data
          ?.filter(ap => ap !== null)
          ?.filter(ap => ap.action_id === selectedActionOption?.value)
        setDeviceActionParameters([]);
        if (deviceActionParameters) setDeviceActionParameters(deviceActionParameters);
        queryClient.fetchQuery(['actionParameters']);
      },
      refetchOnWindowFocus: false,
    }
  );

  const saveDeviceActionParameterMutation = useMutation<DeviceActionParameterV1, Error, DeviceActionParameterV1>(
    async (input: DeviceActionParameterV1): Promise<DeviceActionParameterV1> => {
      const currentDeviceActionParameter = deviceActionParameters.find(dap => dap.id !== '' && dap.id !== null && dap.id === input.id);
      if (currentDeviceActionParameter) {
        const updateDeviceActionParameterInput: UpdateDeviceActionParameterInputV1 = {
          action_id: input.action_id,
          device_action_id: input.device_action_id,
          id: input.id,
          parameter_id: input.parameter_id,
          updated_by: props.forceAwakensState.username.value!,
          value: input.value,
        };
        return await updateDeviceActionParameter(updateDeviceActionParameterInput);
      }
      const createDeviceActionParameterInput: CreateDeviceActionParameterInputV1 = {
        action_id: input.action_id,
        created_by: props.forceAwakensState.username.value!,
        device_action_id: input.device_action_id,
        parameter_id: input.parameter_id,
        value: input.value,
      };
      return await createDeviceActionParameter(createDeviceActionParameterInput);
    },
    {
      onSettled: (data, error) => {
        setError(undefined);
        if (error) {
          setError(typeof error === 'object'
            ? (error as any).details?.message
              ? JSON.stringify((error as any).details.message)
              : JSON.stringify(error)
            : error as string);
          return;
        }
        if (data) {
          const savedDeviceActionParameterIndex = deviceActionParameters.findIndex(a => a.id === data.id);
          const newDeviceActionParameters = [...deviceActionParameters.filter(a => a.id !== data.id && a.id !== '')];
          const savedDeviceActionParameter = {...data};
          savedDeviceActionParameter.parameter_name = actionParameters.find(ap => ap.parameter_id === savedDeviceActionParameter.parameter_id)?.parameter_name ?? '';
          if (savedDeviceActionParameterIndex !== -1) newDeviceActionParameters.splice(savedDeviceActionParameterIndex, 0, savedDeviceActionParameter);
          if (savedDeviceActionParameterIndex === -1) newDeviceActionParameters.push(savedDeviceActionParameter);
          if ((selectedDeviceActionParameters.length > 0 && selectedDeviceActionParameters[0].id === '')
            || selectedDeviceActionParameters[0]?.id === savedDeviceActionParameter.id)
          {
            setSelectedDeviceActionParameters([savedDeviceActionParameter]);
          }
          setDeviceActionParameters([...newDeviceActionParameters]);
          if (deviceActionParameters.findIndex(a => a.id === data?.id) === -1) collectionActions.setCurrentPage(paginationProps.pagesCount);
          props.savedParameters([...newDeviceActionParameters]);
        }
      },
    },
  );

  const deleteDeviceActionParameterMutation = useMutation<DeviceActionParameterV1 | undefined, Error, DeleteDeviceActionParameterInputV1>(
    async (input: DeleteDeviceActionParameterInputV1) => {
      const currentDeviceActionParameter = deviceActionParameters.find(dap => dap.id === input.id);
      if (currentDeviceActionParameter && currentDeviceActionParameter.id !== '') return(await deleteDeviceActionParameter(input));
    },
    {
      onSettled: (data, error) => {
        setError(undefined);
        if (error) {
          setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
          setShowConfirmDelete(false);
          return;
        }
        const newDeviceActionParameters = [...deviceActionParameters.filter(dap => dap.id !== data?.id)];
        setSelectedDeviceActionParameters(selectedDeviceActionParameters.filter(dap => dap.id !== data?.id));
        setDeviceActionParameters([...newDeviceActionParameters]);
        props.savedParameters([...newDeviceActionParameters]);
      },
    },
  );

  const validParameterValue = (deviceActionParameter: DeviceActionParameterV1, newValue: string): boolean => {
    console.debug(`DeviceActionParametersTablePanel() validParameterValue() deviceActionParameter is ${JSON.stringify(deviceActionParameter)}`)
    const parameter = queryClient.getQueryData<ParameterV1[]>(['parameters'])
      ?.find(p => p.id === deviceActionParameter?.parameter_id);
    console.debug(`DeviceActionParametersTablePanel() validParameterValue() parameter is ${JSON.stringify(parameter)}`)
    let defaultErrorMessage = 'Invalid parameter value';
    let parameterAdditionalInfo: any;
    try {
      if (!parameter?.additional_info) return true;
      parameterAdditionalInfo = JSON.parse(JSON.parse(parameter.additional_info));
      console.debug(`DeviceActionParametersTablePanel() validParameterValue() parameterAdditionalInfo is ${JSON.stringify(parameterAdditionalInfo)}`)
      if (parameterAdditionalInfo.validValues) {
        if (!parameterAdditionalInfo.validValues?.includes(newValue)) {
          setError(parameterAdditionalInfo.invalidMessage ?? defaultErrorMessage);
          return false;
        }
      }
      if (parameterAdditionalInfo.regex) {
        const regex = new RegExp(parameterAdditionalInfo.regex);
        console.debug(`DeviceActionParametersTablePanel() validParameterValue() regex is ${regex}`);
        if (!regex.test(newValue)) {
          setError(parameterAdditionalInfo.invalidMessage ?? defaultErrorMessage);
          return false;
        }
      }
    } catch(error) {
      console.error(error);
      if (error instanceof SyntaxError) setError(error.message);
      else if (typeof error === 'string') setError(error);
      else setError(JSON.stringify(error));
      return false;
    }
    return true;
  }

  const submitEdit = async (
    currentItem: DeviceActionParameterV1,
    column: TableProps.ColumnDefinition<DeviceActionParameterV1>,
    value: any) =>
  {
    const newDeviceActionParameters = [...deviceActionParameters];
    const index = newDeviceActionParameters.findIndex(v =>
      v.id === currentItem.id
      && v.device_action_id === currentItem.device_action_id
    );
    if (index !== -1) {
      const newDeviceActionParameter: DeviceActionParameterV1 = {...newDeviceActionParameters[index]};
      switch (column.id) {
        case 'name':
          newDeviceActionParameter.parameter_id = value.value;
          newDeviceActionParameter.parameter_name = value.label;
          break;

        case 'value':
          if (!validParameterValue(currentItem, value)) throw new Error('Invalid parameter value');
          newDeviceActionParameter.value = value;
          break;

        default:
          console.error(`submitEdit() column.id is ${column.id} which is not supported`);
      }
      newDeviceActionParameters[index] = newDeviceActionParameter;
      setDeviceActionParameters(newDeviceActionParameters);
      await saveDeviceActionParameterMutation.mutateAsync(newDeviceActionParameter);
    }
  };

  const selectParameterRef = useRef<any>(null);

  const addDeviceActionParameter = () => {
    if (!selectedActionOption?.value || selectedActionOption.value === '') return;
    const newDeviceActionParameters = [...deviceActionParameters];
    const newDeviceActionParameter: DeviceActionParameterV1 = {
      __typename: 'DeviceActionParameterV1',
      action_id: selectedActionOption.value,
      id: '',
      device_action_id: props.deviceActionId,
      parameter_id: '',
      parameter_name: '',
      value: '',
      created: '',
      created_by: props.forceAwakensState.username.value!,
      updated: '',
      updated_by: props.forceAwakensState.username.value!,
    };
    newDeviceActionParameters.push(newDeviceActionParameter);
    setDeviceActionParameters(newDeviceActionParameters);
    collectionActions.setCurrentPage(paginationProps.pagesCount);
    selectParameterRef.current?.focus();
  };

  const deleteDeviceActionParameters = async () => {
    if (selectedDeviceActionParameters[0].id === '') {
      setDeviceActionParameters(deviceActionParameters.filter(dap => dap.id !== ''));
      setShowConfirmDelete(false);
      setError(undefined);
      return;
    }
    for (const selectedDeviceActionParameter of selectedDeviceActionParameters.filter(dap => dap.id !== '')) {
      const deleteDeviceActionParameterInput: DeleteDeviceActionParameterInputV1 = {
        id: selectedDeviceActionParameter.id,
        updated_by: props.forceAwakensState.username.value!,
      };
      await deleteDeviceActionParameterMutation.mutateAsync(deleteDeviceActionParameterInput);
    }
    setShowConfirmDelete(false);
  };

  const refresh = () => {
    setSelectedDeviceActionParameters([]);
    queryClient.fetchQuery(['deviceActionParameters']);
  };

  useEffect(() => {
    setSelectedDeviceActionParameters([]);
    if (!selectedActionOption) return;
    queryClient.fetchQuery(['deviceActionParameters']);
  }, [selectedActionOption]);

  useEffect(() => {
    setSelectedActionOption(null);
    setDeviceActionParameters([]);
    setSelectedDeviceActionParameters([]);
    queryClient.fetchQuery(['deviceActionOutputActions']);
  }, [props.deviceActionId]);

  if (isBundleLoading) return <Spinner/>;

  const ColumnDefinitions: TableProps.ColumnDefinition<DeviceActionParameterV1>[] = [
    {
      cell: (item: DeviceActionParameterV1) => item.parameter_name,
      editConfig: {
        ariaLabel: 'Parameter',
        editIconAriaLabel: 'editable',
        errorIconAriaLabel: 'Parameter Error',
        editingCell: (item, { currentValue, setValue }) => {
          return(
            <Select
              autoFocus
              expandToViewport
              onChange={({ detail }) => {
                setValue(({ label: detail.selectedOption.label, value: detail.selectedOption.value }));
              }}
              options={actionParameters
                .filter(ap => !!ap.parameter_name)
                .filter(ap => deviceActionParameters
                  .findIndex(dap => dap.action_id === selectedActionOption?.value && dap.parameter_id === ap.parameter_id) === -1)
                .map(ap => ({label: ap.parameter_name!, value: ap.parameter_id}))}
              ref={selectParameterRef}
              selectedOption={currentValue ?? {
                label: actionParameters.find(d => d.parameter_id === item.parameter_id)?.parameter_name,
                value: item.parameter_id,
              }}
            />);
        },
      },
      header: 'Parameter',
      id: 'name',
    },
    {
      cell: (item: DeviceActionParameterV1) => item.value,
      editConfig: {
        ariaLabel: 'Value',
        editIconAriaLabel: 'editable',
        errorIconAriaLabel: 'Value Error',
        editingCell: (item, { currentValue, setValue }) => {
          return(
            <Input
              onChange={({ detail }) => {
                setValue(detail.value);
              }}
              value={currentValue ?? item.value}
            />);
        },
      },
      header: bundle.getMessage('value'),
      id: 'value',
    },
  ];

  return(
    <div id='DeviceActionParameterTablePanel'>
      {showConfirmDelete
      &&
      <Modal
        closeAriaLabel='Close'
        onDismiss={() => setShowConfirmDelete(false)}
        visible={showConfirmDelete}
        size='medium'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                onClick={() => setShowConfirmDelete(false)}
                variant='link'
              >
                {bundle.getMessage('no')}
              </Button>
              <Button
                disabled={deleteDeviceActionParameterMutation.isLoading}
                onClick={() => deleteDeviceActionParameters()}
                variant='primary'
              >
                {bundle.getMessage('yes')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={`${bundle.getMessage('delete')} (${selectedDeviceActionParameters.length})`}
      >
        {bundle.getMessage('confirm-delete')}
      </Modal>}
      <SpaceBetween direction='vertical' size='m'>
        <Grid gridDefinition={[{colspan: 4}]}>
          <FormField label='Action'>
            <Select
              expandToViewport
              onChange={({detail}) => setSelectedActionOption(detail.selectedOption)}
              options={actionOptions}
              placeholder={bundle.getMessage('select-action')}
              selectedOption={selectedActionOption}
            />
          </FormField>
        </Grid>
        <Table
          columnDefinitions={ColumnDefinitions}
          empty={
            <>
              {selectedActionOption ? bundle.getMessage('no-parameters-found') : bundle.getMessage('select-action')}
            </>
          }
          header={
            <Header
              counter={`(${deviceActionParameters.length})`}
              info={
                <>
                  {error
                  &&
                  <Alert
                    type='error'
                    dismissible
                    onDismiss={() => setError(undefined)}
                  >
                    {error}
                  </Alert>}
                </>
              }
              actions={
                <SpaceBetween direction='horizontal' size='s'>
                  <Button
                    disabled={
                      queryClient.isFetching({ queryKey: ['deviceActionParameters'] }) > 0
                      || queryClient.getQueryState(['deviceActionParameters'])?.status === 'loading'
                    }
                    iconName='refresh'
                    onClick={refresh}
                  />
                  <Button
                    disabled={selectedDeviceActionParameters.length === 0}
                    onClick={() => setShowConfirmDelete(true)}
                  >
                    {bundle.getMessage('delete')}
                  </Button>
                  <Button
                    disabled={
                      !selectedActionOption
                      || queryClient.isFetching({ queryKey: ['deviceActionParameters'] }) > 0
                      || queryClient.getQueryState(['deviceActionParameters'])?.status === 'loading'
                      || deviceActionParameters.findIndex(dap => dap.id === '') !== -1
                      || queryClient.isFetching({ queryKey: ['actionParameters'] }) > 0
                      || queryClient.getQueryState(['actionParameters'])?.status === 'loading'
                      || deviceActionParameters.length === actionParameters.filter(ap => ap.action_id === selectedActionOption.value).length
                    }
                    onClick={() => addDeviceActionParameter()}
                    variant='primary'
                  >
                    {bundle.getMessage('new')}
                  </Button>
                </SpaceBetween>
              }
            >
              Device Action Parameters
            </Header>
        }
          items={items}
          loading={deviceActionParametersQuery.isFetching || actionParametersQuery.isFetching}
          onSelectionChange={event => {
            setSelectedDeviceActionParameters(event.detail.selectedItems);
          }}
          selectionType='single'
          selectedItems={selectedDeviceActionParameters}
          submitEdit={submitEdit}
        />
      </SpaceBetween>
    </div>
  );
}
