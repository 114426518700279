import React, { useState } from 'react';
import {
  NotificationTypeV1,
  NotificationV1,
} from '../../API';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Flashbar from '@amzn/awsui-components-react/polaris/flashbar';
import { fetchNotifications } from './utils';
import { useQuery } from 'react-query';
import { Link } from '@amzn/awsui-components-react';

export function Notifications() {
  const [notifications, setNotifications] = useState<NotificationV1[]>([]);
  const [error, setError] = useState<string>('');

  useQuery<NotificationV1[]>(
    ['notifications'],
    () => fetchNotifications(),
    {
      cacheTime: 60000,
      onError: (error) => {
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      onSuccess: data => {
        if (!data) {
          setNotifications([]);
          return;
        }
        setNotifications(data);
      },
      refetchOnWindowFocus: true,
      retry: 3,
      staleTime: 30000,
    },
  );

  return (
    <div>
    {notifications.length > 0
    &&
    <Flashbar
      items={
        notifications
          .sort((a,b) => {
            const aType = a.type === NotificationTypeV1.CRITICAL
              ? 0
              : a.type === NotificationTypeV1.WARNING
                ? 1
                : 2;
            const bType = b.type === NotificationTypeV1.CRITICAL
              ? 0
              : b.type === NotificationTypeV1.WARNING
                ? 1
                : 2;
            return aType < bType ? -1 : 1;
          })
          .map((n: NotificationV1, index) => {
            const content =
              <>
                {String(n.message)}
                {n.links?.map(l => 
                  <>
                    <br/>
                    <Link
                      color='inverted'
                      external href={l!.target}
                      target='_blank'
                    >
                      {l!.text}
                    </Link>
                  </>)}
              </>;
            return({
              content,
              dismissible: n.dismissable,
              header: n.type,
              onDismiss: () => setNotifications([...notifications.filter((_, i) => i !== index)]),
              type: n.type === NotificationTypeV1.CRITICAL
                ? 'error'
                : n.type === NotificationTypeV1.WARNING
                  ? 'warning'
                  : 'info',
            })
          })
        }
        stackItems={notifications.length > 2}
    />}
    {error !== ''
    &&
    <Alert
      dismissible
      onDismiss={() => setError('')}
      type='error'
    >
      {error}
    </Alert>}
    </div>
  );
}
