import API, {
  GraphQLResult,
  graphqlOperation,
} from '@aws-amplify/api';
import {
  ActionParameterV1,
  ConditionOperandV1,
  CreateDeviceActionConditionGroupInputV1,
  CreateDeviceActionConditionGroupV1Mutation,
  CreateDeviceActionConditionInputV1,
  CreateDeviceActionConditionV1Mutation,
  CreateDeviceActionInputEventInputV1,
  CreateDeviceActionInputEventV1Mutation,
  CreateDeviceActionInputV1,
  CreateDeviceActionOutputActionInputV1,
  CreateDeviceActionOutputActionV1Mutation,
  CreateDeviceActionParameterInputV1,
  CreateDeviceActionParameterV1Mutation,
  CreateDeviceActionV1Mutation,
  DeleteDeviceActionConditionGroupInputV1,
  DeleteDeviceActionConditionGroupV1Mutation,
  DeleteDeviceActionConditionInputV1,
  DeleteDeviceActionConditionV1Mutation,
  DeleteDeviceActionInputEventV1Mutation,
  DeleteDeviceActionInputV1,
  DeleteDeviceActionOutputActionV1Mutation,
  DeleteDeviceActionParameterInputV1,
  DeleteDeviceActionParameterV1Mutation,
  DeleteDeviceActionV1Mutation,
  DeviceActionConditionGroupV1,
  DeviceActionConditionV1,
  DeviceActionForSiteV1,
  DeviceActionInputEventV1,
  DeviceActionOutputActionV1,
  DeviceActionParameterV1,
  DeviceActionV1,
  DeviceTypeV1,
  DeviceV1,
  ListActionParametersV1Query,
  ListConditionOperandsV1Query,
  ListDeviceActionConditionGroupsForDeviceActionV1Query,
  ListDeviceActionConditionsForGroupV1Query,
  ListDeviceActionOutputActionsV1Query,
  ListDeviceActionParametersForDeviceActionV1Query,
  ListDeviceActionsForSiteV1Query,
  ListDevicesMethodName,
  ListDevicesV1Query,
  SNSPublishDeviceActionCreationQuery,
  SNSPublishDeviceActionDeleteQuery,
  SNSPublishDeviceActionUpdateQuery,
  SNSResponse,
  UpdateDeviceActionConditionGroupInputV1,
  UpdateDeviceActionConditionGroupV1Mutation,
  UpdateDeviceActionConditionInputV1,
  UpdateDeviceActionConditionV1Mutation,
  UpdateDeviceActionInputEventInputV1,
  UpdateDeviceActionInputEventV1Mutation,
  UpdateDeviceActionInputV1,
  UpdateDeviceActionOutputActionInputV1,
  UpdateDeviceActionOutputActionV1Mutation,
  UpdateDeviceActionParameterInputV1,
  UpdateDeviceActionParameterV1Mutation,
  UpdateDeviceActionV1Mutation,
} from 'src/API';
import {
  createDeviceActionInputEventV1,
  createDeviceActionOutputActionV1,
  createDeviceActionV1,
  deleteDeviceActionV1,
  deleteDeviceActionInputEventV1,
  deleteDeviceActionOutputActionV1,
  updateDeviceActionInputEventV1,
  updateDeviceActionOutputActionV1,
  updateDeviceActionV1,
  deleteDeviceActionParameterV1,
  createDeviceActionParameterV1,
  updateDeviceActionParameterV1,
  createDeviceActionConditionGroupV1,
  updateDeviceActionConditionGroupV1,
  deleteDeviceActionConditionGroupV1,
  createDeviceActionConditionV1,
  updateDeviceActionConditionV1,
  deleteDeviceActionConditionV1,
} from 'src/graphql/mutations';
import {
  listDeviceActionsForSiteV1,
  listDeviceActionParametersForDeviceActionV1,
  listActionParametersV1,
  listDeviceActionOutputActionsV1,
  listDeviceActionConditionGroupsForDeviceActionV1,
  listDeviceActionConditionsForGroupV1,
  listConditionOperandsV1,
  listDevicesV1,
  SNSPublishDeviceActionCreation,
  SNSPublishDeviceActionDelete,
  SNSPublishDeviceActionUpdate,
} from 'src/graphql/queries';

export const listDeviceActionsForSite = async (siteCode: string | undefined): Promise<DeviceActionForSiteV1[]> => {
  console.debug(`listDeviceActionsForSite() siteCode is ${siteCode}`);

  let deviceActions: DeviceActionForSiteV1[] = []; 

  if (!siteCode) {
    console.debug('listDeviceActionsForSite() siteCode is undefined');
    return deviceActions;
  }

  try {
    const response = await API.graphql(graphqlOperation(listDeviceActionsForSiteV1, {
      site_code: siteCode,
    })) as GraphQLResult<ListDeviceActionsForSiteV1Query>;
    console.debug(`listDeviceActionsForSite() response is ${JSON.stringify(response)}`);
    deviceActions = response.data?.listDeviceActionsForSiteV1 as DeviceActionForSiteV1[] ?? [];
  } catch (error) {
    console.error('listDeviceActionsForSite() error is', error);
    throw error;
  }

  console.debug('listDeviceActionsForSite() deviceActions is', deviceActions);
  return deviceActions;
}

export const listDeviceActionOutputActionsForDeviceActionId = async (deviceActionId: string): Promise<DeviceActionOutputActionV1[]> => {
  console.debug(`listDeviceActionOutputActionsForDeviceActionId() deviceActionId is ${deviceActionId}`);

  let deviceActionOutputActions: DeviceActionOutputActionV1[] = []; 

  if (!deviceActionId) {
    return deviceActionOutputActions;
  }

  try {
    const response = await API.graphql(graphqlOperation(listDeviceActionOutputActionsV1, {
      device_action_id: deviceActionId,
    })) as GraphQLResult<ListDeviceActionOutputActionsV1Query>;
    console.debug(`listDeviceActionOutputActionsForDeviceActionId() response is ${JSON.stringify(response)}`);
    deviceActionOutputActions = response.data?.listDeviceActionOutputActionsV1 as DeviceActionOutputActionV1[];
  } catch (error) {
    console.error('listDeviceActionOutputActionsForDeviceActionId() error is', error);
    throw error;
  }

  console.debug('listDeviceActionOutputActionsForDeviceActionId() deviceActionOutputActions is', deviceActionOutputActions);
  return deviceActionOutputActions;
}

export const listDeviceActionParametersForDeviceActionId = async (deviceActionId: string): Promise<DeviceActionParameterV1[]> => {
  console.debug(`listDeviceActionParametersForDeviceActionId() deviceActionId is ${deviceActionId}`);

  let deviceActionParameters: DeviceActionParameterV1[] = []; 

  if (!deviceActionId) {
    return deviceActionParameters;
  }

  try {
    const response = await API.graphql(graphqlOperation(listDeviceActionParametersForDeviceActionV1, {
      device_action_id: deviceActionId,
    })) as GraphQLResult<ListDeviceActionParametersForDeviceActionV1Query>;
    console.debug(`listDeviceActionParametersForDeviceActionId() response is ${JSON.stringify(response)}`);
    deviceActionParameters = response.data?.listDeviceActionParametersForDeviceActionV1 as DeviceActionParameterV1[];
  } catch (error) {
    console.error('listDeviceActionParametersForDeviceActionId() error is', error);
    throw error;
  }

  console.debug('listDeviceActionParametersForDeviceActionId() deviceActionParameters is', deviceActionParameters);
  return deviceActionParameters;
}

export const listDeviceActionConditionGroupsForDeviceActionId = async (deviceActionId: string): Promise<DeviceActionConditionGroupV1[]> => {
  console.debug(`listDeviceActionConditionGroupsForDeviceActionId() deviceActionId is ${deviceActionId}`);

  let deviceActionConditionGroups: DeviceActionConditionGroupV1[] = []; 

  if (!deviceActionId) {
    return deviceActionConditionGroups;
  }

  try {
    const response = await API.graphql(graphqlOperation(listDeviceActionConditionGroupsForDeviceActionV1, {
      device_action_id: deviceActionId,
    })) as GraphQLResult<ListDeviceActionConditionGroupsForDeviceActionV1Query>;
    console.debug(`listDeviceActionConditionGroupsForDeviceActionId() response is ${JSON.stringify(response)}`);
    deviceActionConditionGroups = response.data?.listDeviceActionConditionGroupsForDeviceActionV1 as DeviceActionConditionGroupV1[];
  } catch (error) {
    console.error('listDeviceActionConditionGroupsForDeviceActionId() error is', error);
    throw error;
  }

  console.debug('listDeviceActionConditionGroupsForDeviceActionId() deviceActionConditionGroups is', deviceActionConditionGroups);
  return deviceActionConditionGroups;
}

export const listDeviceActionConditionsForGroup = async (groupId: string): Promise<DeviceActionConditionV1[]> => {
  console.debug(`listDeviceActionConditionsForGroup() groupId is ${groupId}`);

  let deviceActionConditions: DeviceActionConditionV1[] = []; 

  if (!groupId) {
    return deviceActionConditions;
  }

  try {
    const response = await API.graphql(graphqlOperation(listDeviceActionConditionsForGroupV1, {
      group_id: groupId,
    })) as GraphQLResult<ListDeviceActionConditionsForGroupV1Query>;
    console.debug(`listDeviceActionConditionsForGroup() response is ${JSON.stringify(response)}`);
    deviceActionConditions = response.data?.listDeviceActionConditionsForGroupV1 as DeviceActionConditionV1[];
  } catch (error) {
    console.error('listDeviceActionConditionsForGroup() error is', error);
    throw error;
  }

  console.debug('listDeviceActionConditionsForGroup() deviceActionConditions is', deviceActionConditions);
  return deviceActionConditions;
}

export const listActionParameters = async (actionId: string): Promise<ActionParameterV1[]> => {
  console.debug(`listActionParameters() actionId is ${actionId}`);

  let actionParameters: ActionParameterV1[] = [];

  if (!actionId) return actionParameters;

  try {
    const response = await API.graphql(graphqlOperation(listActionParametersV1, {
      action_id: actionId,
    })) as GraphQLResult<ListActionParametersV1Query>;
    console.debug(`listActionParameters() response is ${JSON.stringify(response)}`);
    actionParameters = response.data?.listActionParametersV1 as ActionParameterV1[];
  } catch(error) {
    console.error(`listActionParameters() error is ${JSON.stringify(error)} ${error}`);
  }

  console.debug('listActionParameters() actionParameters is', actionParameters);
  return actionParameters;
};

export const listConditionOperands = async (): Promise<ConditionOperandV1[]> => {
  console.debug(`listConditionOperands()`);

  let conditionOperands: ConditionOperandV1[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listConditionOperandsV1, {})) as GraphQLResult<ListConditionOperandsV1Query>;
    console.debug(`listConditionOperands() response is ${JSON.stringify(response)}`);
    conditionOperands = response.data?.listConditionOperandsV1 as ConditionOperandV1[];
  } catch(error) {
    console.error(`listConditionOperands() error is ${JSON.stringify(error)} ${error}`);
  }

  console.debug('listConditionOperands() conditionOperands is', conditionOperands);
  return conditionOperands;
};

export const listDevicesForSite = async (siteCode: string | undefined): Promise<DeviceV1[]> => {
  console.debug(`listDevicesForSite() siteCode is ${siteCode}`);

  const devices: DeviceV1[] = [];

  if (!siteCode) return devices;

  const limit = 100;
  let offset = 0;

  try {
    while (true) {
      const response = await API.graphql(graphqlOperation(
        listDevicesV1,
        {
          methodName: ListDevicesMethodName.getSiteDevices,
          parameters: JSON.stringify({
            limit,
            offset,
            siteCode,
          }),
        })) as GraphQLResult<ListDevicesV1Query>;
      console.debug(`listDevicesForSite() response received`);
      console.debug(`listDevicesForSite() response.data?.listDevicesV1.length is ${JSON.stringify(response.data?.listDevicesV1)}`);
      console.debug(`listDevicesForSite() response is ${JSON.stringify(response)}`);
      devices.push(...response.data?.listDevicesV1?.devices as DeviceV1[]);
      if (response.data?.listDevicesV1?.devices.length === 0) break;
      offset += limit;
    }
  } catch(error) {
    console.error(`listDevicesForSite() error is ${JSON.stringify(error)} ${error}`);
  }

  console.debug('listDevicesForSite() devices is', devices);
  return devices;
};

export const saveDeviceAction = async (deviceAction: DeviceActionForSiteV1): Promise<DeviceActionForSiteV1> => {
  console.debug(`saveDeviceAction() deviceAction is ${JSON.stringify(deviceAction)}`);

  const savedDeviceActionForSite: DeviceActionForSiteV1 = {...deviceAction};

  try {
    if (deviceAction.id === '') {
      const createDeviceActionInput: CreateDeviceActionInputV1 = {
        created_by: deviceAction.created_by,
        input_device_id: deviceAction.input_device_id,
        output_device_id: deviceAction.output_device_id,
        site_code: deviceAction.site_code,
      };
      const createdDeviceAction = await createDeviceAction(createDeviceActionInput);
      savedDeviceActionForSite.id = createdDeviceAction?.id ?? '';
      const savedDeviceActionInputEvents: DeviceActionInputEventV1[] = [];
      for (const deviceActionInputEvent of deviceAction.device_action_input_events as DeviceActionInputEventV1[] ?? []) {
        const createDeviceActionInputEventInput: CreateDeviceActionInputEventInputV1 = {
          created_by: deviceActionInputEvent.created_by,
          device_action_id: createdDeviceAction!.id,
          device_event_id: deviceActionInputEvent.device_event_id,
        };
        const createdDeviceActionInputEvent = await createDeviceActionInputEvent(createDeviceActionInputEventInput);
        if (createdDeviceActionInputEvent !== undefined) savedDeviceActionInputEvents.push(createdDeviceActionInputEvent);
      }
      savedDeviceActionForSite.device_action_input_events = savedDeviceActionInputEvents;
      const savedDeviceActionOutputActions: DeviceActionOutputActionV1[] = [];
      for (const deviceActionOutputAction of deviceAction.device_action_output_actions as DeviceActionOutputActionV1[] ?? []) {
        const createDeviceActionOutputActionInput: CreateDeviceActionOutputActionInputV1 = {
          action_id: deviceActionOutputAction.action_id,
          created_by: deviceActionOutputAction.created_by,
          device_action_id: createdDeviceAction!.id,
        };
        const createdDeviceActionOutputActions = await createDeviceActionOutputAction(createDeviceActionOutputActionInput);
        if (createdDeviceActionOutputActions !== undefined) savedDeviceActionOutputActions.push(createdDeviceActionOutputActions);
      }
      savedDeviceActionForSite.device_action_output_actions = savedDeviceActionOutputActions;
      return(savedDeviceActionForSite);
    }
    if (deviceAction.id !== '') {
      const updateDeviceActionInput: UpdateDeviceActionInputV1 = {
        id: deviceAction.id,
        input_device_id: deviceAction.input_device_id,
        output_device_id: deviceAction.output_device_id,
        site_code: deviceAction.site_code,
        updated_by: deviceAction.created_by,
      };
      const updatedDeviceAction = await updateDeviceAction(updateDeviceActionInput);
      savedDeviceActionForSite.updated = updatedDeviceAction?.updated ?? '';
      const savedDeviceActionInputEvents: DeviceActionInputEventV1[] = [];
      for (const deviceActionInputEvent of deviceAction.device_action_input_events as DeviceActionInputEventV1[] ?? []) {
        if (deviceActionInputEvent.id === '') {
          const createDeviceActionInputEventInput: CreateDeviceActionInputEventInputV1 = {
            created_by: deviceActionInputEvent.created_by,
            device_action_id: deviceActionInputEvent.device_action_id,
            device_event_id: deviceActionInputEvent.device_event_id,
          };
          const createdDeviceActionInputEvent = await createDeviceActionInputEvent(createDeviceActionInputEventInput);
          if (createdDeviceActionInputEvent !== undefined) savedDeviceActionInputEvents.push(createdDeviceActionInputEvent);
        }
        if (deviceActionInputEvent.id !== '') {
          const updateDeviceActionInputEventInput: UpdateDeviceActionInputEventInputV1 = {
            device_action_id: deviceActionInputEvent.device_action_id,
            device_event_id: deviceActionInputEvent.device_event_id,
            id: deviceActionInputEvent.id,
            updated_by: deviceActionInputEvent.updated_by,
          };
          const updatedDeviceActionInputEvent = await updateDeviceActionInputEvent(updateDeviceActionInputEventInput);
          if (updatedDeviceActionInputEvent !== undefined) savedDeviceActionInputEvents.push(updatedDeviceActionInputEvent);
        }
      }
      savedDeviceActionForSite.device_action_input_events = savedDeviceActionInputEvents;
      const savedDeviceActionOutputActions: DeviceActionOutputActionV1[] = [];
      for (const deviceActionOutputAction of deviceAction.device_action_output_actions as DeviceActionOutputActionV1[] ?? []) {
        if (deviceActionOutputAction.id === '') {
          const createDeviceActionOutputActionInput: CreateDeviceActionOutputActionInputV1 = {
            device_action_id: deviceActionOutputAction.device_action_id,
            action_id: deviceActionOutputAction.action_id,
            created_by: deviceActionOutputAction.created_by,
          };
          const createdDeviceActionOutputAction = await createDeviceActionOutputAction(createDeviceActionOutputActionInput);
          if (createdDeviceActionOutputAction !== undefined) savedDeviceActionOutputActions.push(createdDeviceActionOutputAction);
        }
        if (deviceActionOutputAction.id !== '') {
          const updateDeviceActionOutputActionInput: UpdateDeviceActionOutputActionInputV1 = {
            action_id: deviceActionOutputAction.action_id,
            device_action_id: deviceActionOutputAction.device_action_id,
            id: deviceActionOutputAction.id,
            updated_by: deviceActionOutputAction.updated_by,
          };
          const updatedDeviceActionOutputAction = await updateDeviceActionOutputAction(updateDeviceActionOutputActionInput);
          if (updatedDeviceActionOutputAction !== undefined) savedDeviceActionOutputActions.push(updatedDeviceActionOutputAction);
        }
      }
      savedDeviceActionForSite.device_action_output_actions = savedDeviceActionOutputActions;
      return(savedDeviceActionForSite);
    }
  } catch(error) {
    console.error('saveDeviceAction() error is', error);
    throw error;
  }

  console.debug(`saveDeviceAction() savedDeviceActionForSite is ${JSON.stringify(savedDeviceActionForSite)}`);
  return(savedDeviceActionForSite);
}

const createDeviceAction = async (createDeviceActionInput: CreateDeviceActionInputV1): Promise<DeviceActionV1 | undefined> => {
  console.debug(`createDeviceAction() createActionInput is ${JSON.stringify(createDeviceActionInput)}`);

  let createdDeviceAction: DeviceActionV1 | undefined = undefined;

  try {
    const response = await API.graphql(graphqlOperation(createDeviceActionV1, {
      input: createDeviceActionInput,
    })) as GraphQLResult<CreateDeviceActionV1Mutation>;
    console.debug(`createDeviceAction() response is ${JSON.stringify(response)}`);
    createdDeviceAction = response.data?.createDeviceActionV1 as DeviceActionV1;
  } catch (error) {
    console.error('createDeviceAction() error is', error);
    throw error;
  }

  console.debug('createDeviceAction() createdDeviceAction is', createdDeviceAction);
  return createdDeviceAction;
}

export const deleteDeviceAction = async (deleteDeviceActionInput: DeleteDeviceActionInputV1): Promise<DeviceActionV1 | undefined> => {
  console.debug(`deleteDeviceAction() deleteActionInput is ${JSON.stringify(deleteDeviceActionInput)}`);

  let deletedDeviceAction: DeviceActionV1 | undefined = undefined;

  try {
    const response = await API.graphql(graphqlOperation(deleteDeviceActionV1, {
      input: deleteDeviceActionInput,
    })) as GraphQLResult<DeleteDeviceActionV1Mutation>;
    console.debug(`deleteDeviceAction() response is ${JSON.stringify(response)}`);
    deletedDeviceAction = response.data?.deleteDeviceActionV1 as DeviceActionV1;
  } catch (error) {
    console.error('deleteDeviceAction() error is', error);
    throw error;
  }

  console.debug('deleteDeviceAction() deletedDeviceAction is', deletedDeviceAction);
  return deletedDeviceAction;
}

const updateDeviceAction = async (updateDeviceActionInput: UpdateDeviceActionInputV1): Promise<DeviceActionV1 | undefined> => {
  console.debug(`updateDeviceAction() updateDeviceActionInput is ${JSON.stringify(updateDeviceActionInput)}`);

  let updatedDeviceAction: DeviceActionV1 | undefined = undefined;

  try {
    const response = await API.graphql(graphqlOperation(updateDeviceActionV1, {
      input: updateDeviceActionInput,
    })) as GraphQLResult<UpdateDeviceActionV1Mutation>;
    console.debug(`updateDeviceAction() response is ${JSON.stringify(response)}`);
    updatedDeviceAction = response.data?.updateDeviceActionV1 as DeviceActionV1;
  } catch (error) {
    console.error('updateDeviceAction() error is', error);
    throw error;
  }

  console.debug('updateDeviceAction() updatedDeviceAction is', updatedDeviceAction);
  return updatedDeviceAction;
}

const createDeviceActionInputEvent = async (createDeviceActionInputEventInput: CreateDeviceActionInputEventInputV1): Promise<DeviceActionInputEventV1 | undefined> => {
  console.debug(`createDeviceActionInputEvents() createDeviceActionInputEventInput is ${JSON.stringify(createDeviceActionInputEventInput)}`);

  let createdDeviceActionInputEvent: DeviceActionInputEventV1 | undefined = undefined;

  try {
    const response = await API.graphql(graphqlOperation(createDeviceActionInputEventV1, {
      input: createDeviceActionInputEventInput,
    })) as GraphQLResult<CreateDeviceActionInputEventV1Mutation>;
    console.debug(`createDeviceActionInputEvent() response is ${JSON.stringify(response)}`);
    createdDeviceActionInputEvent = response.data?.createDeviceActionInputEventV1 as DeviceActionInputEventV1;
  } catch (error) {
    console.error('createDeviceActionInputEvents() error is', error);
    throw error;
  }

  console.debug('createDeviceActionInputEvent() createdDeviceActionInputEvent is', createdDeviceActionInputEvent);
  return createdDeviceActionInputEvent;
}

const updateDeviceActionInputEvent = async (updateDeviceActionInputEventInput: UpdateDeviceActionInputEventInputV1): Promise<DeviceActionInputEventV1 | undefined> => {
  console.debug(`updateDeviceActionInputEvent() updateDeviceActionInputEventInput is ${JSON.stringify(updateDeviceActionInputEventInput)}`);

  let updatedDeviceActionInputEvent: DeviceActionInputEventV1 | undefined = undefined;

  try {
    const response = await API.graphql(graphqlOperation(updateDeviceActionInputEventV1, {
      input: updateDeviceActionInputEventInput,
    })) as GraphQLResult<UpdateDeviceActionInputEventV1Mutation>;
    console.debug(`updateDeviceActionInputEvent() response is ${JSON.stringify(response)}`);
    updatedDeviceActionInputEvent = response.data?.updateDeviceActionInputEventV1 as DeviceActionInputEventV1;
  } catch (error) {
    console.error('updateDeviceActionInputEvent() error is', error);
    throw error;
  }

  console.debug('updateDeviceActionInputEvent() updatedDeviceActionInputEvent is', updatedDeviceActionInputEvent);
  return updatedDeviceActionInputEvent;
}

export const deleteDeviceActionInputEvent = async (id: string, updatedBy: string): Promise<DeviceActionInputEventV1 | undefined> => {
  console.debug(`deleteDeviceActionInputEvent() id is ${id} updatedBy is ${updatedBy}`);

  let deletedDeviceActionInputEvent: DeviceActionInputEventV1 | undefined = undefined;

  if (!id || id === '') return deletedDeviceActionInputEvent;

  try {
    const response = await API.graphql(graphqlOperation(deleteDeviceActionInputEventV1, {
      id,
      updated_by: updatedBy,
    })) as GraphQLResult<DeleteDeviceActionInputEventV1Mutation>;
    console.debug(`deleteDeviceActionInputEvent() response is ${JSON.stringify(response)}`);
    deletedDeviceActionInputEvent = response.data?.deleteDeviceActionInputEventV1 as DeviceActionInputEventV1;
  } catch (error) {
    console.error('deleteDeviceActionInputEvent() error is', error);
    throw error;
  }

  console.debug('deleteDeviceActionInputEvent() deletedDeviceActionInputEvent is', deletedDeviceActionInputEvent);
  return deletedDeviceActionInputEvent;
}

const createDeviceActionOutputAction = async (createDeviceActionOutputActionInput: CreateDeviceActionOutputActionInputV1): Promise<DeviceActionOutputActionV1 | undefined> => {
  console.debug(`createDeviceActionOutputActions() createDeviceActionOutputActionInput is ${JSON.stringify(createDeviceActionOutputActionInput)}`);

  let createdDeviceActionOutputAction: DeviceActionOutputActionV1 | undefined = undefined;

  try {
    const response = await API.graphql(graphqlOperation(createDeviceActionOutputActionV1, {
      input: createDeviceActionOutputActionInput,
    })) as GraphQLResult<CreateDeviceActionOutputActionV1Mutation>;
    console.debug(`createDeviceActionOutputAction() response is ${JSON.stringify(response)}`);
    createdDeviceActionOutputAction = response.data?.createDeviceActionOutputActionV1 as DeviceActionOutputActionV1;
  } catch (error) {
    console.error('createDeviceActionOutputActions() error is', error);
    throw error;
  }

  console.debug('createDeviceActionOutputAction() createdDeviceActionOutputAction is', createdDeviceActionOutputAction);
  return createdDeviceActionOutputAction;
}

const updateDeviceActionOutputAction = async (updateDeviceActionOutputActionInput: UpdateDeviceActionOutputActionInputV1): Promise<DeviceActionOutputActionV1 | undefined> => {
  console.debug(`updateDeviceActionOutputAction() updateDeviceActionOutputActionInput is ${JSON.stringify(updateDeviceActionOutputActionInput)}`);

  let updatedDeviceActionOutputAction: DeviceActionOutputActionV1 | undefined = undefined;

  try {
    const response = await API.graphql(graphqlOperation(updateDeviceActionOutputActionV1, {
      input: updateDeviceActionOutputActionInput,
    })) as GraphQLResult<UpdateDeviceActionOutputActionV1Mutation>;
    console.debug(`updateDeviceActionOutputAction() response is ${JSON.stringify(response)}`);
    updatedDeviceActionOutputAction = response.data?.updateDeviceActionOutputActionV1 as DeviceActionOutputActionV1;
  } catch (error) {
    console.error('updateDeviceActionOutputAction() error is', error);
    throw error;
  }

  console.debug('updateDeviceActionOutputAction() updatedDeviceActionOutputAction is', updatedDeviceActionOutputAction);
  return updatedDeviceActionOutputAction;
}

export const deleteDeviceActionOutputAction = async (id: string, updatedBy: string): Promise<DeviceActionOutputActionV1 | undefined> => {
  console.debug(`deleteDeviceActionOutputAction() id is ${id} updatedBy is ${updatedBy}`);

  let deletedDeviceActionOutputAction: DeviceActionOutputActionV1 | undefined = undefined;

  if (!id || id === '') return deletedDeviceActionOutputAction;

  try {
    const response = await API.graphql(graphqlOperation(deleteDeviceActionOutputActionV1, {
      id,
      updated_by: updatedBy,
    })) as GraphQLResult<DeleteDeviceActionOutputActionV1Mutation>;
    console.debug(`deleteDeviceActionOutputAction() response is ${JSON.stringify(response)}`);
    deletedDeviceActionOutputAction = response.data?.deleteDeviceActionOutputActionV1 as DeviceActionOutputActionV1;
  } catch (error) {
    console.error('deleteDeviceActionOutputAction() error is', error);
    throw error;
  }

  console.debug('deleteDeviceActionOutputAction() deletedDeviceActionOutputAction is', deletedDeviceActionOutputAction);
  return deletedDeviceActionOutputAction;
}

export const createDeviceActionParameter = async (createDeviceActionParameterInput: CreateDeviceActionParameterInputV1): Promise<DeviceActionParameterV1> => {
  console.debug(`createDeviceActionParameter() createDeviceActionParameterInput is ${JSON.stringify(createDeviceActionParameterInput)}`);

  let createdDeviceActionParameter: DeviceActionParameterV1;

  try {
    const response = await API.graphql(graphqlOperation(createDeviceActionParameterV1, {
      input: createDeviceActionParameterInput,
    })) as GraphQLResult<CreateDeviceActionParameterV1Mutation>;
    console.debug(`createDeviceActionParameter() response is ${JSON.stringify(response)}`);
    createdDeviceActionParameter = response.data?.createDeviceActionParameterV1 as DeviceActionParameterV1;
  } catch (error) {
    console.error('createDeviceActionParameter() error is', error);
    throw error;
  }

  console.debug('createDeviceActionParameter() createdDeviceActionParameter is', createdDeviceActionParameter);
  return createdDeviceActionParameter;
}

export const updateDeviceActionParameter = async (updateDeviceActionParameterInput: UpdateDeviceActionParameterInputV1): Promise<DeviceActionParameterV1> => {
  console.debug(`updateDeviceActionParameter() updateDeviceActionParameterInput is ${JSON.stringify(updateDeviceActionParameterInput)}`);

  let updatedDeviceActionParameter: DeviceActionParameterV1;

  try {
    const response = await API.graphql(graphqlOperation(updateDeviceActionParameterV1, {
      input: updateDeviceActionParameterInput,
    })) as GraphQLResult<UpdateDeviceActionParameterV1Mutation>;
    console.debug(`updateDeviceActionParameter() response is ${JSON.stringify(response)}`);
    updatedDeviceActionParameter = response.data?.updateDeviceActionParameterV1 as DeviceActionParameterV1;
  } catch (error) {
    console.error('updateDeviceActionParameter() error is', error);
    throw error;
  }

  console.debug('updateDeviceActionParameter() updatedDeviceActionParameter is', updatedDeviceActionParameter);
  return updatedDeviceActionParameter;
}

export const deleteDeviceActionParameter = async (deleteDeviceActionParameterInput: DeleteDeviceActionParameterInputV1): Promise<DeviceActionParameterV1 | undefined> => {
  console.debug(`deleteDeviceActionParameter() deleteActionParameterInput is ${JSON.stringify(deleteDeviceActionParameterInput)}`);

  let deletedDeviceActionParameter: DeviceActionParameterV1 | undefined = undefined;

  try {
    const response = await API.graphql(graphqlOperation(deleteDeviceActionParameterV1, {
      input: deleteDeviceActionParameterInput,
    })) as GraphQLResult<DeleteDeviceActionParameterV1Mutation>;
    console.debug(`deleteDeviceActionParameter() response is ${JSON.stringify(response)}`);
    deletedDeviceActionParameter = response.data?.deleteDeviceActionParameterV1 as DeviceActionParameterV1;
  } catch (error) {
    console.error('deleteDeviceActionParameter() error is', error);
    throw error;
  }

  console.debug('deleteDeviceActionParameter() deletedDeviceActionParameter is', deletedDeviceActionParameter);
  return deletedDeviceActionParameter;
}

export const createDeviceActionConditionGroup = async (createDeviceActionConditionGroupInput: CreateDeviceActionConditionGroupInputV1): Promise<DeviceActionConditionGroupV1> => {
  console.debug(`createDeviceActionConditionGroup() createDeviceActionConditionGroupInput is ${JSON.stringify(createDeviceActionConditionGroupInput)}`);

  let createdDeviceActionConditionGroup: DeviceActionConditionGroupV1;

  try {
    const response = await API.graphql(graphqlOperation(createDeviceActionConditionGroupV1, {
      input: createDeviceActionConditionGroupInput,
    })) as GraphQLResult<CreateDeviceActionConditionGroupV1Mutation>;
    console.debug(`createDeviceActionConditionGroup() response is ${JSON.stringify(response)}`);
    createdDeviceActionConditionGroup = response.data?.createDeviceActionConditionGroupV1 as DeviceActionConditionGroupV1;
  } catch (error) {
    console.error('createDeviceActionConditionGroup() error is', error);
    throw error;
  }

  console.debug('createDeviceActionConditionGroup() createdDeviceActionConditionGroup is', createdDeviceActionConditionGroup);
  return createdDeviceActionConditionGroup;
}

export const updateDeviceActionConditionGroup = async (updateDeviceActionConditionGroupInput: UpdateDeviceActionConditionGroupInputV1): Promise<DeviceActionConditionGroupV1> => {
  console.debug(`updateDeviceActionConditionGroup() updateDeviceActionConditionGroupInput is ${JSON.stringify(updateDeviceActionConditionGroupInput)}`);

  let updatedDeviceActionConditionGroup: DeviceActionConditionGroupV1;

  try {
    const response = await API.graphql(graphqlOperation(updateDeviceActionConditionGroupV1, {
      input: updateDeviceActionConditionGroupInput,
    })) as GraphQLResult<UpdateDeviceActionConditionGroupV1Mutation>;
    console.debug(`updateDeviceActionConditionGroup() response is ${JSON.stringify(response)}`);
    updatedDeviceActionConditionGroup = response.data?.updateDeviceActionConditionGroupV1 as DeviceActionConditionGroupV1;
  } catch (error) {
    console.error('updateDeviceActionConditionGroup() error is', error);
    throw error;
  }

  console.debug('updateDeviceActionConditionGroup() updatedDeviceActionConditionGroup is', updatedDeviceActionConditionGroup);
  return updatedDeviceActionConditionGroup;
}

export const deleteDeviceActionConditionGroup = async (deleteDeviceActionConditionGroupInput: DeleteDeviceActionConditionGroupInputV1): Promise<DeviceActionConditionGroupV1 | undefined> => {
  console.debug(`deleteDeviceActionConditionGroup() deleteActionConditionGroupInput is ${JSON.stringify(deleteDeviceActionConditionGroupInput)}`);

  let deletedDeviceActionConditionGroup: DeviceActionConditionGroupV1 | undefined = undefined;

  try {
    const response = await API.graphql(graphqlOperation(deleteDeviceActionConditionGroupV1, {
      input: deleteDeviceActionConditionGroupInput,
    })) as GraphQLResult<DeleteDeviceActionConditionGroupV1Mutation>;
    console.debug(`deleteDeviceActionConditionGroup() response is ${JSON.stringify(response)}`);
    deletedDeviceActionConditionGroup = response.data?.deleteDeviceActionConditionGroupV1 as DeviceActionConditionGroupV1;
  } catch (error) {
    console.error('deleteDeviceActionConditionGroup() error is', error);
    throw error;
  }

  console.debug('deleteDeviceActionConditionGroup() deletedDeviceActionConditionGroup is', deletedDeviceActionConditionGroup);
  return deletedDeviceActionConditionGroup;
}

export const createDeviceActionCondition = async (createDeviceActionConditionInput: CreateDeviceActionConditionInputV1): Promise<DeviceActionConditionV1> => {
  console.debug(`createDeviceActionCondition() createDeviceActionConditionInput is ${JSON.stringify(createDeviceActionConditionInput)}`);

  let createdDeviceActionCondition: DeviceActionConditionV1;

  try {
    const response = await API.graphql(graphqlOperation(createDeviceActionConditionV1, {
      input: createDeviceActionConditionInput,
    })) as GraphQLResult<CreateDeviceActionConditionV1Mutation>;
    console.debug(`createDeviceActionCondition() response is ${JSON.stringify(response)}`);
    createdDeviceActionCondition = response.data?.createDeviceActionConditionV1 as DeviceActionConditionV1;
  } catch (error) {
    console.error('createDeviceActionCondition() error is', error);
    throw error;
  }

  console.debug('createDeviceActionCondition() createdDeviceActionCondition is', createdDeviceActionCondition);
  return createdDeviceActionCondition;
}

export const updateDeviceActionCondition = async (updateDeviceActionConditionInput: UpdateDeviceActionConditionInputV1): Promise<DeviceActionConditionV1> => {
  console.debug(`updateDeviceActionCondition() updateDeviceActionConditionInput is ${JSON.stringify(updateDeviceActionConditionInput)}`);

  let updatedDeviceActionCondition: DeviceActionConditionV1;

  try {
    const response = await API.graphql(graphqlOperation(updateDeviceActionConditionV1, {
      input: updateDeviceActionConditionInput,
    })) as GraphQLResult<UpdateDeviceActionConditionV1Mutation>;
    console.debug(`updateDeviceActionCondition() response is ${JSON.stringify(response)}`);
    updatedDeviceActionCondition = response.data?.updateDeviceActionConditionV1 as DeviceActionConditionV1;
  } catch (error) {
    console.error('updateDeviceActionCondition() error is', error);
    throw error;
  }

  console.debug('updateDeviceActionCondition() updatedDeviceActionCondition is', updatedDeviceActionCondition);
  return updatedDeviceActionCondition;
}

export const deleteDeviceActionCondition = async (deleteDeviceActionConditionInput: DeleteDeviceActionConditionInputV1): Promise<DeviceActionConditionV1 | undefined> => {
  console.debug(`deleteDeviceActionCondition() deleteActionConditionInput is ${JSON.stringify(deleteDeviceActionConditionInput)}`);

  let deletedDeviceActionCondition: DeviceActionConditionV1 | undefined = undefined;

  try {
    const response = await API.graphql(graphqlOperation(deleteDeviceActionConditionV1, {
      input: deleteDeviceActionConditionInput,
    })) as GraphQLResult<DeleteDeviceActionConditionV1Mutation>;
    console.debug(`deleteDeviceActionCondition() response is ${JSON.stringify(response)}`);
    deletedDeviceActionCondition = response.data?.deleteDeviceActionConditionV1 as DeviceActionConditionV1;
  } catch (error) {
    console.error('deleteDeviceActionCondition() error is', error);
    throw error;
  }

  console.debug('deleteDeviceActionCondition() deletedDeviceActionCondition is', deletedDeviceActionCondition);
  return deletedDeviceActionCondition;
}

export interface IMissingDeviceActionParameters {
  actionId: string;
};

export const missingDeviceActionParameters = async (deviceAction: DeviceActionForSiteV1): Promise<IMissingDeviceActionParameters[]> => {
  console.debug(`missingDeviceActionParameters() deviceAction is ${JSON.stringify(deviceAction)}`);

  const result: IMissingDeviceActionParameters[] = [];

  const deviceActionOutputActions = deviceAction.device_action_output_actions;
  if (!deviceActionOutputActions) return result;

  const deviceActionParameters = deviceAction.device_action_parameters ?? [];

  for (const deviceAction of deviceActionOutputActions) {
    console.debug(`missingDeviceActionParameters() deviceAction is ${JSON.stringify(deviceAction)}`);
    if (deviceAction === null) continue;
    const actionParameters = await listActionParameters(deviceAction.action_id) ?? [];
    console.debug(`missingDeviceActionParameters() actionParameters is ${JSON.stringify(actionParameters)}`);
    for (const actionParameter of actionParameters) {
      console.debug(`missingDeviceActionParameters() actionParameter is ${JSON.stringify(actionParameter)}`);
      if (deviceActionParameters.findIndex(dap => dap?.action_id === actionParameter.action_id && dap?.parameter_id === actionParameter.parameter_id) === -1
      || deviceActionParameters.find(dap => dap?.action_id === actionParameter.action_id && dap?.parameter_id === actionParameter.parameter_id)?.value === '') {
        console.debug(`missingDeviceActionParameters() returning true`);
        result.push({actionId: actionParameter.action_id});
      }
    }
  }

  console.debug(`missingDeviceActionParameters() result is ${JSON.stringify(result)}`);
  return result;
};

export const deviceTypeIdFromDeviceTypeName = (deviceTypeName: string, deviceTypes: DeviceTypeV1[]): string | null => {
  let deviceTypeId: string | null = null;

  deviceTypeId = deviceTypes.find(dt => dt.name === deviceTypeName)?.id ?? null;

  return(deviceTypeId);
}

export const publishDeviceActionCreation = async (deviceAction: DeviceActionForSiteV1): Promise<SNSResponse> => {
  console.debug(`publishDeviceActionCreation() deviceAction is ${JSON.stringify(deviceAction)}`);

  let publishedDeviceAction: SNSResponse;

  const newDeviceAction: Partial<DeviceActionForSiteV1> = {
    id: deviceAction.id,
    site_code: deviceAction.site_code,
    device_action_input_events: deviceAction.device_action_input_events?.map(daie => ({...daie, __typename: undefined}) as unknown as DeviceActionInputEventV1),
    device_action_output_actions: deviceAction.device_action_output_actions?.map(daoa => ({...daoa, __typename: undefined}) as unknown as DeviceActionOutputActionV1),
    input_device_id: deviceAction.input_device_id,
    output_device_id: deviceAction.output_device_id,
    additional_info: deviceAction.additional_info,
    created: deviceAction.created,
    created_by: deviceAction.created_by,
    updated: deviceAction.updated,
    updated_by: deviceAction.updated_by,
  };

  try {
    const response = await API.graphql(graphqlOperation(SNSPublishDeviceActionCreation, {
      deviceAction: newDeviceAction,
    })) as GraphQLResult<SNSPublishDeviceActionCreationQuery>;
    console.debug(`publishDeviceActionCreation() response is ${JSON.stringify(response)}`);
    publishedDeviceAction = response.data?.SNSPublishDeviceActionCreation as SNSResponse;
  } catch (error) {
    console.error('publishDeviceActionCreation() error is', error);
    throw error;
  }

  console.debug('publishDeviceActionCreation() publishedDeviceAction is', publishedDeviceAction);
  return publishedDeviceAction;
}

export const publishDeviceActionUpdate = async (deviceAction: DeviceActionForSiteV1): Promise<SNSResponse> => {
  console.debug(`publishDeviceActionUpdate() deviceAction is ${JSON.stringify(deviceAction)}`);

  let publishedDeviceAction: SNSResponse;

  const newDeviceAction: Partial<DeviceActionForSiteV1> = {
    id: deviceAction.id,
    site_code: deviceAction.site_code,
    device_action_input_events: deviceAction.device_action_input_events?.map(daie => ({...daie, __typename: undefined}) as unknown as DeviceActionInputEventV1),
    device_action_output_actions: deviceAction.device_action_output_actions?.map(daoa => ({...daoa, __typename: undefined}) as unknown as DeviceActionOutputActionV1),
    input_device_id: deviceAction.input_device_id,
    output_device_id: deviceAction.output_device_id,
    additional_info: deviceAction.additional_info,
    created: deviceAction.created,
    created_by: deviceAction.created_by,
    updated: deviceAction.updated,
    updated_by: deviceAction.updated_by,
  };

  try {
    const response = await API.graphql(graphqlOperation(SNSPublishDeviceActionUpdate, {
      deviceAction: newDeviceAction,
    })) as GraphQLResult<SNSPublishDeviceActionUpdateQuery>;
    console.debug(`publishDeviceActionUpdate() response is ${JSON.stringify(response)}`);
    publishedDeviceAction = response.data?.SNSPublishDeviceActionUpdate as SNSResponse;
  } catch (error) {
    console.error('publishDeviceActionUpdate() error is', error);
    throw error;
  }

  console.debug('publishDeviceActionUpdate() publishedDeviceAction is', publishedDeviceAction);
  return publishedDeviceAction;
}

export const publishDeviceActionDelete = async (deviceAction: DeviceActionV1): Promise<SNSResponse> => {
  console.debug(`publishDeviceActionDelete() deviceAction is ${JSON.stringify(deviceAction)}`);

  let publishedDeviceAction: SNSResponse;

  const newDeviceAction: Partial<DeviceActionV1> = {
    id: deviceAction.id,
    site_code: deviceAction.site_code,
    input_device_id: deviceAction.input_device_id,
    output_device_id: deviceAction.output_device_id,
    additional_info: deviceAction.additional_info,
    created: deviceAction.created,
    created_by: deviceAction.created_by,
    updated: deviceAction.updated,
    updated_by: deviceAction.updated_by,
  };

  try {
    const response = await API.graphql(graphqlOperation(SNSPublishDeviceActionDelete, {
      deviceAction: newDeviceAction,
    })) as GraphQLResult<SNSPublishDeviceActionDeleteQuery>;
    console.debug(`publishDeviceActionDelete() response is ${JSON.stringify(response)}`);
    publishedDeviceAction = response.data?.SNSPublishDeviceActionDelete as SNSResponse;
  } catch (error) {
    console.error('publishDeviceActionDelete() error is', error);
    throw error;
  }

  console.debug('publishDeviceActionDelete() publishedDeviceAction is', publishedDeviceAction);
  return publishedDeviceAction;
}
