import {
  ActionDeviceTypeEventV1,
  ActionDeviceTypeV1,
  ActionV1,
  DeleteDeviceActionInputV1,
  DeviceActionForSiteV1,
  DeviceActionInputEventV1,
  DeviceActionOutputActionV1,
  DeviceActionParameterV1,
  DeviceActionV1,
  DeviceEventV1,
  DeviceTypeV1,
  DeviceV1,
} from 'src/API';
import {
  Alert,
  Badge,
  Box,
  Button,
  Container,
  Header,
  Modal,
  Multiselect,
  Select,
  SpaceBetween,
  Spinner,
  Table,
  TableProps,
  TextFilter,
} from '@amzn/awsui-components-react';
import CollectionPreferences, {
  CollectionPreferencesProps,
} from '@amzn/awsui-components-react/polaris/collection-preferences';
import {
  TableEmptyState,
  TableNoMatchState,
  DefaultPageSize,
} from './DeviceActionsTableConfig';
import {
  deleteDeviceAction,
  deleteDeviceActionInputEvent,
  deleteDeviceActionOutputAction,
  deviceTypeIdFromDeviceTypeName,
  listDeviceActionsForSite,
  missingDeviceActionParameters,
  publishDeviceActionCreation,
  publishDeviceActionDelete,
  publishDeviceActionUpdate,
  saveDeviceAction,
} from './utils';
import {
  listActionDeviceTypeEvents,
  listActionDeviceTypes,
  listActions,
  listDeviceTypeEvents,
} from '../ActionsSetup/utils';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { ActionDeviceType } from 'src/constants/Constants';
import DeviceActionConditionGroupsTablePanel from './DeviceActionConditionGroupsTablePanel';
import DeviceActionParametersTablePanel from './DeviceActionParametersTablePanel';
import { ForceAwakensStateInterface } from 'src/stores/app';
import { RouteComponentProps } from 'react-router-dom';
import { State } from '@hookstate/core';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';

interface IDeviceActionsTablePanel extends RouteComponentProps {
  forceAwakensState: State<ForceAwakensStateInterface>;
}

type DeviceActionForSiteV1Extended = DeviceActionForSiteV1 & {
  missingDeviceActionParameters?: {actionId: string}[];
}

export default function DeviceActionsTablePanel(props: IDeviceActionsTablePanel) {
  console.log(`DeviceActionsTablePanel()`);

  const queryClient = useQueryClient();

  const [actionDeviceTypeEvents, setActionDeviceTypeEvents] = useState<ActionDeviceTypeEventV1[]>([]);
  const [actionDeviceTypes, setActionDeviceTypes] = useState<ActionDeviceTypeV1[]>([]);
  const [actions, setActions] = useState<ActionV1[]>([]);
  const [addingDeviceAction, setAddingDeviceAction] = useState<boolean>(false);
  const [deviceActions, setDeviceActions] = useState<DeviceActionForSiteV1Extended[]>([]);
  const [deviceEvents, setDeviceEvents] = useState<DeviceEventV1[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<DeviceTypeV1[]>([]);
  const [devices, setDevices] = useState<DeviceV1[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [preferences, setPreferences] = React.useState<CollectionPreferencesProps.Preferences>();
  const [selectedDeviceActions, setSelectedDeviceActions] = useState<DeviceActionForSiteV1Extended[]>([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showMissingDeviceActionParameters, setShowMissingDeviceActionParameters] = useState<boolean>(false);
  const [showMissingDeviceActionParametersInstructions, setShowMissingDeviceActionParametersInstructions] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.DeviceActions.DeviceActionsTablePanel');

  useQuery<ActionV1[]>(
    ['actions'],
    () => listActions(),
    {
      onError: (error) => {
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      onSettled: data => {
        data && setActions(data);
      },
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  useQuery<DeviceEventV1[]>(
    ['deviceEvents'],
    () => listDeviceTypeEvents(),
    {
      onError: (error) => {
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      onSettled: data => {
        data && setDeviceEvents(data);
      },
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  useQuery<ActionDeviceTypeV1[]>(
    ['actionDeviceTypes'],
    () => listActionDeviceTypes(),
    {
      onError: (error) => {
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      onSettled: data => {
        data && setActionDeviceTypes(data);
      },
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  useQuery<ActionDeviceTypeEventV1[]>(
    ['actionDeviceTypeEvents'],
    () => listActionDeviceTypeEvents(),
    {
      onError: (error) => {
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      onSettled: data => {
        data && setActionDeviceTypeEvents(data);
      },
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  useQuery<DeviceActionForSiteV1[]>(
    ['deviceActionsForSite'],
    async () => {
      const deviceActionsForSite = await listDeviceActionsForSite(props.forceAwakensState.selectedSite.value?.siteCode);
      const deviceActionsForSiteExtended = await checkForMissingActionParameters(deviceActionsForSite);
      return deviceActionsForSiteExtended;
    },
    {
      enabled: !!props.forceAwakensState.selectedSite.value?.siteCode,
      onError: (error) => {
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      onSettled: async (data, error) => {
        setError(undefined);
        if (error) {
          setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
          return;
        }
        setDeviceActions([]);
        if (data) setDeviceActions(data);
      },
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  const { items, actions: collectionActions, filteredItemsCount, filterProps, paginationProps } = useCollection(
    deviceActions,
    {
      filtering: {
        empty: <TableEmptyState title={isBundleLoading ? 'No Device Actions Found' : bundle.getMessage('no-device-actions-found')} />,
        noMatch: <TableNoMatchState onClearFilter={() => collectionActions.setFiltering('')} />,
        filteringFunction: (item, filteringText) => {
          return item.id === null || item.id === undefined || item.id === ''
          || (preferences?.visibleContent?.includes('id') && item.id.includes(filteringText))
          || deviceTypes.find(dt => dt.name === devices.find(d => d.id === item.input_device_id)?.device_type)
            ?.description.toLocaleLowerCase().includes(filteringText.toLowerCase())
          || deviceTypes.find(dt => dt.name === devices.find(d => d.id === item.output_device_id)
            ?.device_type)?.description.toLocaleLowerCase().includes(filteringText.toLowerCase())
          || devices.find(d => d.id === item.input_device_id)?.name.toLowerCase().includes(filteringText.toLowerCase())
          || devices.find(d => d.id === item?.output_device_id)?.name.toLowerCase().includes(filteringText.toLowerCase())
          || item.device_action_input_events?.find(e => e?.device_event_name?.toLowerCase().includes(filteringText.toLowerCase())) !== undefined
          || item.device_action_output_actions?.find(a => a?.action_name?.toLowerCase().includes(filteringText.toLowerCase())) !== undefined
        }
      },
      pagination: { pageSize: DefaultPageSize.pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const refresh = () => {
    setSelectedDeviceActions([]);
    queryClient.refetchQueries(['actionDeviceTypes']);
    queryClient.refetchQueries(['deviceTypes']);
    queryClient.refetchQueries(['devicesForSite']);
    queryClient.refetchQueries(['deviceActionsForSite']);
  };

  const checkForMissingActionParameters = async (deviceActions: DeviceActionForSiteV1[]): Promise<DeviceActionForSiteV1Extended[]> => {
    let anyMissed = false;
    const deviceActionsExtended: DeviceActionForSiteV1Extended[] = [];
    for (const deviceAction of deviceActions) {
      const missingCheckResult = await missingDeviceActionParameters(deviceAction);
      deviceActionsExtended.push({
        ...deviceAction,
        missingDeviceActionParameters: missingCheckResult,
      });
      if (missingCheckResult.length > 0) anyMissed = true;
    }
    setShowMissingDeviceActionParameters(anyMissed);
    return deviceActionsExtended;
  };

  const setDeviceActionsExtended = async (deviceActions: DeviceActionForSiteV1[]) => {
    const deviceActionsExtended: DeviceActionForSiteV1Extended[] = await checkForMissingActionParameters(deviceActions);
    setDeviceActions([...deviceActionsExtended]);
    const newSelectedDeviceAction = deviceActionsExtended.find(dae => dae.id === selectedDeviceActions[0]?.id);
    if (newSelectedDeviceAction) {
      setSelectedDeviceActions([newSelectedDeviceAction]);
    }
  };

  const saveDeviceActionMutation = useMutation<DeviceActionForSiteV1, Error, DeviceActionForSiteV1>(
    async (input: DeviceActionForSiteV1): Promise<DeviceActionForSiteV1> => {
      const currentDeviceAction = deviceActions.find(da => da.id === input.id);
      if (currentDeviceAction) {
        input.updated_by = props.forceAwakensState.username.value!;
        if (currentDeviceAction.device_action_input_events?.length && currentDeviceAction.device_action_input_events.length > 0) {
          const deletedDeviceActionInputEvents = currentDeviceAction.device_action_input_events
            ?.filter(e => !input.device_action_input_events
              ?.find(i => i?.device_event_id === e?.device_event_id)
          ) as DeviceActionInputEventV1[];
          for (const deletedDeviceActionInputEvent of deletedDeviceActionInputEvents) {
            await deleteDeviceActionInputEvent(deletedDeviceActionInputEvent?.id, props.forceAwakensState.username.value!)
          }
          for (const deviceActionInputEvent of input.device_action_input_events as DeviceActionInputEventV1[]) {
            if (deviceActionInputEvent.id !== '') {
              deviceActionInputEvent.updated_by = props.forceAwakensState.username.value!;
            }
          }
        }
        if (currentDeviceAction.device_action_output_actions?.length && currentDeviceAction.device_action_output_actions.length > 0) {
          const deletedDeviceActionOutputActions = currentDeviceAction.device_action_output_actions
            ?.filter(e => !input.device_action_output_actions
              ?.find(i => i?.action_id === e?.action_id)
            ) as DeviceActionOutputActionV1[];
          for (const deletedDeviceActionOutputAction of deletedDeviceActionOutputActions) {
            await deleteDeviceActionOutputAction(deletedDeviceActionOutputAction?.id, props.forceAwakensState.username.value!)
          }
          for (const deviceActionOutputAction of input.device_action_output_actions as DeviceActionOutputActionV1[]) {
            if (deviceActionOutputAction.id !== '') {
              deviceActionOutputAction.updated_by = props.forceAwakensState.username.value!;
            }
          }
        }
      }
      return await saveDeviceAction(input);
    },
    {
      onSettled: async (data, error) => {
        setError(undefined);
        if (error) {
          setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
          return;
        }
        if (data) {
          const savedDeviceActionIndex = deviceActions.findIndex(a => a.id === data.id);
          const newDeviceActions = [...deviceActions.filter(a => a.id !== data.id && a.id !== '')];
          const savedDeviceAction = {...data};
          if (savedDeviceAction.device_action_input_events?.length && savedDeviceAction.device_action_input_events.length > 0) {
            savedDeviceAction.device_action_input_events = savedDeviceAction.device_action_input_events
              .filter((ie): ie is DeviceActionInputEventV1 => ie !== null)
              .map((ie: DeviceActionInputEventV1) => ({
                ...ie,
                device_event_name: deviceEvents.find(e => e.id === ie.device_event_id)?.name,
              }));
          }
          if (savedDeviceAction.device_action_output_actions?.length && savedDeviceAction.device_action_output_actions.length > 0) {
            savedDeviceAction.device_action_output_actions = savedDeviceAction.device_action_output_actions
              .filter((oa): oa is DeviceActionOutputActionV1 => oa !== null)
              .map((oa: DeviceActionOutputActionV1) => ({
                ...oa,
                action_name: actions.find(a => a.id === oa.action_id)?.name,
              }));
          }
          if (savedDeviceActionIndex !== -1) newDeviceActions.splice(savedDeviceActionIndex, 0, savedDeviceAction as DeviceActionForSiteV1Extended);
          if (savedDeviceActionIndex === -1) newDeviceActions.push(savedDeviceAction as DeviceActionForSiteV1Extended);
          await setDeviceActionsExtended([...newDeviceActions]);
          if ((selectedDeviceActions.length > 0 && selectedDeviceActions[0].id === '')
            || selectedDeviceActions[0]?.id === savedDeviceAction.id)
          {
            setSelectedDeviceActions([savedDeviceAction]);
          }
          if (deviceActions.findIndex(a => a.id === data?.id) === -1) collectionActions.setCurrentPage(paginationProps.pagesCount);
          if (data.created === data.updated) await publishDeviceActionCreation(savedDeviceAction);
          if (data.created !== data.updated) await publishDeviceActionUpdate(savedDeviceAction);
        }
      },
    },
  );

  const deleteDeviceActionMutation = useMutation<DeviceActionV1 | undefined, Error, DeleteDeviceActionInputV1>(
    async (input: DeleteDeviceActionInputV1) => {
      const currentDeviceAction = deviceActions.find(da => da.id === input.id);
      if (currentDeviceAction && currentDeviceAction.id !== '') return(await deleteDeviceAction(input));
    },
    {
      onSettled: async (data, error) => {
        setError(undefined);
        if (error) {
          setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
          setShowConfirmDelete(false);
          return;
        }
        const newDeviceActions = [...deviceActions.filter(da => da.id !== data?.id)];
        setSelectedDeviceActions([]);
        setDeviceActions([...newDeviceActions]);
        await setDeviceActionsExtended(newDeviceActions);
        if (data) await publishDeviceActionDelete(data);
      },
    },
  );

  const submitEdit = async (
    currentItem: DeviceActionForSiteV1,
    column: TableProps.ColumnDefinition<DeviceActionForSiteV1>,
    value: any) =>
  {
    const index = deviceActions.findIndex((v) => v.id === currentItem.id);
    if (index !== -1) {
      const newDeviceAction = { ...deviceActions[index] };
      switch (column.id) {
        case 'device_action_input_events':
          const newDeviceActionInputEvents: DeviceActionInputEventV1[] = value.map((v: {label: string, value: string}) => {
            const existingDeviceActionInputEvent = newDeviceAction.device_action_input_events?.find(e => e?.device_event_id === v.value);
            if (existingDeviceActionInputEvent) return existingDeviceActionInputEvent;
            return({
              __typename: 'DeviceActionInputEventV1',
              id: '',
              device_action_id: newDeviceAction.id,
              device_event_id: v.value,
              device_event_name: v.label,
              created: '',
              created_by: props.forceAwakensState.username.value!,
              updated: '',
              updated_by: props.forceAwakensState.username.value!,
            });
          });
          newDeviceAction.device_action_input_events = newDeviceActionInputEvents;
          break;

        case 'device_action_output_actions':
          const newDeviceActionOutputActions: DeviceActionOutputActionV1[] = value.map((v: {label: string, value: string}) => {
            const existingDeviceActionOutputAction = newDeviceAction.device_action_output_actions?.find(e => e?.action_id === v.value);
            if (existingDeviceActionOutputAction) return existingDeviceActionOutputAction;
            return({
              __typename: 'DeviceActionOutputActionV1',
              id: '',
              device_action_id: newDeviceAction.id,
              action_id: v.value,
              action_name: v.label,
              created: '',
              created_by: props.forceAwakensState.username.value!,
              updated: '',
              updated_by: props.forceAwakensState.username.value!,
            });
          });
          newDeviceAction.device_action_output_actions = newDeviceActionOutputActions;
          newDeviceAction.output_device_id = '';
          break;

        case 'input_device_id':
          newDeviceAction.input_device_id = value.value; 
          newDeviceAction.device_action_input_events = [];
          break;

        case 'output_device_id':
          newDeviceAction.output_device_id = value.value; 
          break;

        default:
          console.error(`submitEdit() column.id is ${column.id} which is not supported`);
      }
      if (newDeviceAction.id !== '') {
        await saveDeviceActionMutation.mutateAsync(newDeviceAction);
      }
      if (newDeviceAction.id === '' && newDeviceAction.input_device_id !== '') {
        await saveDeviceActionMutation.mutateAsync(newDeviceAction);
      }
      if (newDeviceAction.id === '' && newDeviceAction.input_device_id === '') {
        await setDeviceActionsExtended([...deviceActions.filter((v) => v.id !== ''), newDeviceAction]);
        setSelectedDeviceActions([newDeviceAction]);
      }
    }
  };

  const addDeviceAction = async () => {
    setAddingDeviceAction(true);
    setSelectedDeviceActions([]);
    const newDeviceActions = [...deviceActions];
    const newDeviceAction: DeviceActionForSiteV1Extended = {
      __typename: 'DeviceActionForSiteV1',
      created: '',
      created_by: props.forceAwakensState.username.value!,
      device_action_input_events: [],
      device_action_output_actions: [],
      id: '',
      input_device_id: '',
      missingDeviceActionParameters: [],
      output_device_id: '',
      site_code: props.forceAwakensState.selectedSite.value!.siteCode,
      updated: '',
      updated_by: props.forceAwakensState.username.value!,
    };
    newDeviceActions.push(newDeviceAction);
    await setDeviceActionsExtended(newDeviceActions);
    collectionActions.setCurrentPage(paginationProps.pagesCount);
    inputDeviceSelectRef.current?.focus();
    setAddingDeviceAction(false);
  };

  const deleteDeviceActions = async () => {
    if (selectedDeviceActions[0].id === '') {
      setDeviceActions(deviceActions.filter(da => da.id !== ''));
      setShowConfirmDelete(false);
      setError(undefined);
      return;
    }
    for (const selectedDeviceAction of selectedDeviceActions.filter(dap => dap.id !== '')) {
      const deleteDeviceActionInput: DeleteDeviceActionInputV1 = {
        id: selectedDeviceAction.id,
        updated_by: props.forceAwakensState.username.value!,
      };
      await deleteDeviceActionMutation.mutateAsync(deleteDeviceActionInput);
    }
    setShowConfirmDelete(false);
  };

  const queryKeys = [
    'actionDeviceTypes',
    'actionDeviceTypeEvents',
    'deviceTypes',
    'deviceEvents',
    'devicesForSite',
    'deviceActionsForSite',
  ];

  const inputDeviceSelectRef = useRef<any>(null);

  const handleSelectionChange = async (selectedItems: DeviceActionForSiteV1Extended[]) => {
    setSelectedDeviceActions(selectedItems);
  };

  const savedParameters = async (parameters: DeviceActionParameterV1[]) => {
    const changedDeviceActionIndex = deviceActions.findIndex(da => da.id === selectedDeviceActions[0].id);
    if (changedDeviceActionIndex === -1) return;
    const changedDeviceAction = {...deviceActions[changedDeviceActionIndex]};
    if (!changedDeviceAction) return;
    const newDeviceActions = [...deviceActions];
    changedDeviceAction.device_action_parameters = [
      ...changedDeviceAction.device_action_parameters?.filter(p => p?.action_id !== parameters[0].action_id) ?? [],
      ...parameters,
    ];
    newDeviceActions.splice(changedDeviceActionIndex, 1, changedDeviceAction as DeviceActionForSiteV1Extended);
    await setDeviceActionsExtended(newDeviceActions);
  };

  useEffect(() => {
    if (queryClient.getQueryData(['deviceTypes']) !== undefined) {
      setDeviceTypes(queryClient.getQueryData(['deviceTypes'])!);
    }
  }, [queryClient.getQueryData(['deviceTypes'])]);

  useEffect(() => {
    if (queryClient.getQueryData(['devicesForSite']) !== undefined) {
      setDevices(queryClient.getQueryData(['devicesForSite'])!);
    }
  }, [queryClient.getQueryData(['devicesForSite'])]);

  if (isBundleLoading) return <Spinner/>;

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? bundle.getMessage('match') : bundle.getMessage('matches')}`;

  const ColumnDefinitions: TableProps.ColumnDefinition<DeviceActionForSiteV1Extended>[] = [
    {
      cell: (item: DeviceActionForSiteV1Extended) => devices.find(d => d.id === item.input_device_id)?.name,
      editConfig: {
        ariaLabel: 'Key',
        editIconAriaLabel: 'editable',
        errorIconAriaLabel: 'Key Error',
        editingCell: (item, { currentValue, setValue }) => {
          return(
            <Select
              autoFocus
              expandToViewport
              filteringType='auto'
              onChange={({ detail }) => {
                setValue(({ label: detail.selectedOption.label, value: detail.selectedOption.value }));
              }}
              options={devices
                .filter(d => {
                  const deviceTypeId = deviceTypeIdFromDeviceTypeName(d.device_type, deviceTypes);
                  const foundActionDeviceTypes = actionDeviceTypes
                    .filter(adt => deviceTypeId === adt.device_type_id && adt.action_device_type === ActionDeviceType.input)
                  return(foundActionDeviceTypes.length > 0);
                })
                .sort((a, b) => a.name < b.name ? -1 : 1)
                .map(d => ({ label: d.name, value: d.id }))}
              placeholder='Enter a value'
              ref={inputDeviceSelectRef}
              selectedOption={currentValue ?? {
                label: devices.find(d => d.id === item.input_device_id)?.name,
                value: item.input_device_id,
              }}
            />);
        },
      },
      header: 'Input Device',
      id: 'input_device_id',
    },
    {
      cell: (item: DeviceActionForSiteV1Extended) => {
        const deviceType = devices.find(d => d.id === item.input_device_id)?.device_type;
        return deviceTypes.find(dt => dt.name === deviceType)?.description;
      },
      header: 'Input Device Type',
      id: 'inputDeviceType',
    },
    {
      cell: (item: DeviceActionForSiteV1Extended) => {
        return(
          <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
            <ul style={{ listStyleType: 'none',  margin: 0, padding: 0 }} >
              {deviceEvents.filter(e => item.device_action_input_events?.find(ie => ie?.device_event_id === e.id)).map((v, index) => <li key={index}>{v.name}</li>)}
            </ul>
          </div>);
      },
      editConfig: {
        ariaLabel: 'Key',
        editIconAriaLabel: 'editable',
        errorIconAriaLabel: 'Key Error',
        editingCell: (item, { currentValue, setValue }) => {
          return(
            <Multiselect
              disabled={item.input_device_id === undefined || item.input_device_id === ''}
              expandToViewport
              filteringPlaceholder={item.input_device_id === undefined || item.input_device_id === '' ? bundle.getMessage('select-input-device') : ''}
              filteringType='auto'
              hideTokens
              onChange={({ detail }) => {
                setValue(detail.selectedOptions.map(v => ({ value: v.value, label: v.label })));
              }}
              options={deviceEvents
                .filter(de => {
                  const deviceTypeName = devices.find(d => d.id === item.input_device_id)?.device_type;
                  if (!deviceTypeName) return(false);
                  const deviceTypeId = deviceTypeIdFromDeviceTypeName(deviceTypeName, deviceTypes);
                  const foundActionDeviceTypes = actionDeviceTypes.filter(adt => deviceTypeId === adt.device_type_id);
                  const foundActionDeviceTypeEvents = actionDeviceTypeEvents
                    .filter(adte => de.id === adte.device_event_id && foundActionDeviceTypes.find(adt => adt.id === adte.action_device_type_id));
                  return(foundActionDeviceTypeEvents.length > 0);
                })
                .sort((a, b) => a.name < b.name ? -1 : 1)
                .map(e => ({ label: e.name, value: e.id }))}
              placeholder={item.input_device_id === undefined || item.input_device_id === '' ? bundle.getMessage('select-input-device') : ''}
              selectedOptions={currentValue ?? item.device_action_input_events?.map((v: DeviceActionInputEventV1 | null) => {
                if (v) {
                  return({
                    label: v.device_event_name,
                    value: v.device_event_id,
                  });
                }
              })}
            />);
        },
      },
      header: 'Events',
      id: 'device_action_input_events',
    },
    {
      cell: (item: DeviceActionForSiteV1Extended) => {
        return(
          <div>
            <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
              <ul style={{ listStyleType: 'none',  margin: 0, padding: 0 }} >
                {actions
                  .filter(ca => item.device_action_output_actions
                  ?.find(ia => ia?.action_id === ca.id))
                  .map((v,index) =>
                    <li key={index}>
                      {v.name}{item.missingDeviceActionParameters?.findIndex(mdap => mdap.actionId === v.id) !== -1
                        ? <span onClick={(event) => { event.preventDefault(); setSelectedDeviceActions([item]); setShowMissingDeviceActionParametersInstructions(true); }}>
                            <Badge color='severity-medium'>!</Badge>
                          </span>
                        : <></>}
                    </li>)}
              </ul>
            </div>
          </div>);
      },
      editConfig: {
        ariaLabel: 'Key',
        editIconAriaLabel: 'editable',
        errorIconAriaLabel: 'Key Error',
        editingCell: (item, { currentValue, setValue }) => {
          return(
            <Multiselect
              expandToViewport
              filteringType='auto'
              hideTokens
              options={actions
                .sort((a, b) => a.name < b.name ? -1 : 1)
                .map(e => ({ label: e.name, value: e.id }))}
              selectedOptions={currentValue ?? item.device_action_output_actions?.map((v: DeviceActionOutputActionV1 | null) => {
                if (v) {
                  return({
                    label: v.action_name,
                    value: v.action_id,
                  });
                }
              })}
              onChange={({ detail }) => {
                setValue(detail.selectedOptions.map(v => ({ value: v.value, label: v.label })));
              }}
            />);
        },
      },
      header: 'Actions',
      id: 'device_action_output_actions',
    },
    {
      cell: (item: DeviceActionForSiteV1Extended) => devices.find(d => d.id === item.output_device_id)?.name,
      editConfig: {
        ariaLabel: 'Key',
        editIconAriaLabel: 'editable',
        errorIconAriaLabel: 'Key Error',
        editingCell: (item, { currentValue, setValue }) => {
          return(
            <Select
              disabled={item.device_action_output_actions?.length === undefined || item.device_action_output_actions?.length === 0}
              expandToViewport
              filteringPlaceholder={item.device_action_output_actions?.length === undefined || item.device_action_output_actions?.length === 0 ? bundle.getMessage('select-actions') : ''}
              filteringType='auto'
              options={devices
                .filter(d => {
                  const deviceTypeId = deviceTypeIdFromDeviceTypeName(d.device_type, deviceTypes);
                  const foundActionDeviceTypes = actionDeviceTypes
                    .filter(adt => deviceTypeId === adt.device_type_id
                      && item.device_action_output_actions?.findIndex(daoa => adt.action_device_type === ActionDeviceType.output && daoa?.action_id === adt.action_id) !== -1);
                  return(foundActionDeviceTypes.length > 0);
                })
                .sort((a, b) => a.name < b.name ? -1 : 1)
                .map(d => ({ label: d.name, value: d.id }))}
              placeholder={item.device_action_output_actions?.length === undefined || item.device_action_output_actions?.length === 0 ? bundle.getMessage('select-actions') : ''}
              selectedOption={currentValue ?? {
                label: devices.find(d => d.id === item.output_device_id)?.name,
                value: item.output_device_id,
              }}
              onChange={({ detail }) => {
                setValue(({ value: detail.selectedOption.value, label: detail.selectedOption.label }));
              }}
            />);
        },
      },
      header: 'Output Device',
      id: 'output_device_id',
    },
    {
      cell: (item: DeviceActionForSiteV1Extended) => {
        const deviceType = devices.find(d => d.id === item.output_device_id)?.device_type;
        return deviceTypes.find(dt => dt.name === deviceType)?.description;
      },
      header: 'Output Device Type',
      id: 'outputDeviceType',
    },
    {
      cell: (item: DeviceActionForSiteV1Extended) => item.id,
      header: 'Device Action ID',
      id: 'id',
    },
  ];

  return(
    <div id='DeviceActionsTablePanel'>
      {showConfirmDelete
      &&
      <Modal
        closeAriaLabel='Close'
        onDismiss={() => setShowConfirmDelete(false)}
        visible={showConfirmDelete}
        size='medium'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                onClick={() => setShowConfirmDelete(false)}
                variant='link'
              >
                {bundle.getMessage('no')}
              </Button>
              <Button
                disabled={deleteDeviceActionMutation.isLoading}
                loading={deleteDeviceActionMutation.isLoading}
                onClick={() => deleteDeviceActions()}
                variant='primary'
              >
                {bundle.getMessage('yes')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={`${bundle.getMessage('delete')} (${selectedDeviceActions.length})`}
      >
        {bundle.getMessage('confirm-delete')}
      </Modal>}
      {showMissingDeviceActionParametersInstructions
      &&
      <Modal visible={showMissingDeviceActionParametersInstructions}
        onDismiss={() => setShowMissingDeviceActionParametersInstructions(false)}
        size='small'
        header={'Missing Action Parameters'}
      >
        Please enter values for missing action parameters.
      </Modal>
      }
      <Table
        columnDefinitions={ColumnDefinitions}
        columnDisplay={ColumnDefinitions.map(cd => {
          const visible = (cd.id === 'id' && !preferences?.visibleContent?.includes('id')) ? false : true;
          return({
            id: cd.id!,
            visible,
          });
        })}
        empty={
          <>
            {bundle.getMessage('no-device-actions-found')}
          </>
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringPlaceholder={bundle.getMessage('find-device-actions')}
            countText={getFilterCounterText(
              filteredItemsCount === undefined ? 0 : filteredItemsCount,
            )}
          />
        }
        footer={
          (selectedDeviceActions.length === 1 || showMissingDeviceActionParameters)
          && !(queryKeys.some(key => queryClient.isFetching({ queryKey: [key] }) > 0 || queryClient.getQueryState([key])?.status === 'loading'))
          &&
          <SpaceBetween direction='vertical' size='m'>
            {showMissingDeviceActionParameters
            &&
            <Alert type='warning'>
              {bundle.getMessage('missing-action-parameters')}
            </Alert>}
            {selectedDeviceActions.length === 1
            &&
              <>
                <Container
                  header={
                    <Header variant='h2'>
                      Action Parameters
                    </Header>
                  }
                  variant='stacked'
                >
                  <DeviceActionParametersTablePanel
                    forceAwakensState={props.forceAwakensState}
                    deviceActionId={selectedDeviceActions[0].id}
                    savedParameters={savedParameters}
                  />
                </Container>
                <Container
                  header={
                    <Header variant='h2'>
                      Action Conditions
                    </Header>
                  }
                  variant='stacked'
                >
                  <DeviceActionConditionGroupsTablePanel
                    forceAwakensState={props.forceAwakensState}
                    deviceActionId={selectedDeviceActions[0].id}
                  />
                </Container>
              </>}
          </SpaceBetween>
        }
        header={
          <Header
            counter={`(${deviceActions.length})`}
            info={
              <>
                {error
                &&
                <Alert
                  type='error'
                  dismissible
                  onDismiss={() => setError(undefined)}
                >
                  {error}
                </Alert>}
              </>
            }
            actions={
              <SpaceBetween direction='horizontal' size='s'>
                <Button
                  disabled={
                    queryKeys.some(key => 
                      queryClient.isFetching({ queryKey: [key] }) > 0
                      || queryClient.getQueryState([key])?.status === 'loading'
                    )
                  }
                  iconName='refresh'
                  onClick={refresh}
                />
                <Button
                  disabled={selectedDeviceActions.length === 0}
                  onClick={() => setShowConfirmDelete(true)}
                >
                  {bundle.getMessage('delete')}
                </Button>
                <Button
                  disabled={
                    queryKeys.some(key => 
                      queryClient.isFetching({ queryKey: [key] }) > 0
                      || queryClient.getQueryState([key])?.status === 'loading'
                    )
                    || deviceActions.findIndex(da => da.id === '') !== -1
                  }
                  loading={addingDeviceAction}
                  onClick={() => addDeviceAction()}
                  variant='primary'
                >
                  {bundle.getMessage('new')}
                </Button>
              </SpaceBetween>
            }
          >
            Device Actions
          </Header>
        }
        items={items}
        loading={
          queryKeys.some(key =>
            queryClient.isFetching({ queryKey: [key] }) > 0
            || queryClient.getQueryState([key])?.status === 'loading'
          )
        }
        onSelectionChange={({detail}) => {
          handleSelectionChange(detail.selectedItems);
        }}
        preferences={
          <CollectionPreferences
            cancelLabel={bundle.getMessage('cancel')}
            confirmLabel={bundle.getMessage('confirm')}
            onConfirm={({ detail }) => setPreferences(detail)}
            preferences={preferences}
            title={bundle.getMessage('preferences')}
            visibleContentPreference={{
              title: bundle.getMessage('select-optional-content'),
              options: [
                {
                  label: '',
                  options: [
                    {
                      id: 'id',
                      label: 'Device Action ID',
                    },
                  ]
                }
              ]
            }}
          />}
        selectedItems={selectedDeviceActions}
        selectionType='single'
        submitEdit={submitEdit}
      />
    </div>
  );
}
