/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateActionDeviceTypeEventInputV1 = {
  created_by: string,
  additional_info?: string | null,
  device_event_id: string,
  action_device_type_id: string,
};

export type ActionDeviceTypeEventV1 = {
  __typename: "ActionDeviceTypeEventV1",
  id: string,
  device_event_id: string,
  action_device_type_id: string,
  additional_info?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type CreateActionParameterInputV1 = {
  action_id: string,
  parameter_id: string,
  additional_info?: string | null,
  created_by: string,
};

export type ActionParameterV1 = {
  __typename: "ActionParameterV1",
  id: string,
  action_id: string,
  parameter_id: string,
  parameter_name?: string | null,
  additional_info?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type CreateActionInputV1 = {
  name: string,
  description: string,
  additional_info?: string | null,
  created_by: string,
};

export type ActionV1 = {
  __typename: "ActionV1",
  id: string,
  name: string,
  description: string,
  additional_info?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type CreateConditionOperandInputV1 = {
  name: string,
  description: string,
  additional_info?: string | null,
  created_by: string,
};

export type ConditionOperandV1 = {
  __typename: "ConditionOperandV1",
  id: string,
  name: string,
  description: string,
  additional_info?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type CreateDeviceActionConditionGroupInputV1 = {
  device_action_id: string,
  operator: string,
  created_by: string,
};

export type DeviceActionConditionGroupV1 = {
  __typename: "DeviceActionConditionGroupV1",
  id: string,
  device_action_id: string,
  device_action_conditions?:  Array<DeviceActionConditionV1 | null > | null,
  operator: string,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type DeviceActionConditionV1 = {
  __typename: "DeviceActionConditionV1",
  id: string,
  group_id: string,
  operator: string,
  operand_id: string,
  operand_name?: string | null,
  value: string,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type CreateDeviceActionConditionInputV1 = {
  group_id: string,
  operator: string,
  operand_id: string,
  value: string,
  created_by: string,
};

export type CreateDeviceActionParameterInputV1 = {
  device_action_id: string,
  action_id: string,
  parameter_id: string,
  value: string,
  created_by: string,
};

export type DeviceActionParameterV1 = {
  __typename: "DeviceActionParameterV1",
  id: string,
  device_action_id: string,
  action_id: string,
  action_name?: string | null,
  parameter_id: string,
  parameter_name?: string | null,
  value: string,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type CreateDeviceActionInputV1 = {
  site_code: string,
  input_device_id: string,
  output_device_id: string,
  additional_info?: string | null,
  created_by: string,
};

export type DeviceActionV1 = {
  __typename: "DeviceActionV1",
  id: string,
  site_code: string,
  input_device_id: string,
  output_device_id: string,
  additional_info?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type CreateDeviceActionInputEventInputV1 = {
  device_action_id: string,
  device_event_id: string,
  created_by: string,
};

export type DeviceActionInputEventV1 = {
  __typename: "DeviceActionInputEventV1",
  id: string,
  device_action_id: string,
  device_event_id: string,
  device_event_name?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type CreateDeviceActionOutputActionInputV1 = {
  device_action_id: string,
  action_id: string,
  created_by: string,
};

export type DeviceActionOutputActionV1 = {
  __typename: "DeviceActionOutputActionV1",
  id: string,
  device_action_id: string,
  action_id: string,
  action_name?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type CreateDeviceEventInputV1 = {
  name: string,
  description: string,
  additional_info?: string | null,
  created_by: string,
};

export type DeviceEventV1 = {
  __typename: "DeviceEventV1",
  id: string,
  name: string,
  description: string,
  additional_info?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type CreateDeviceLinkInput = {
  acd_device_name: string,
  acd_device_type: string,
  acd_parent_device_id: number,
  acd_child_device_id: number,
  acd_subchild_device_id: number,
  camera_name: string,
  camera_system_id: string,
  created_by: string,
  enabled: string,
  system_id: string,
};

export type DeviceLink = {
  __typename: "DeviceLink",
  acd_device_name: string,
  acd_device_type: string,
  acd_parent_device_id: number,
  acd_child_device_id: number,
  acd_subchild_device_id: number,
  camera_name: string,
  camera_system_id: string,
  created: string,
  created_by: string,
  enabled: string,
  event_links?: Array< string | null > | null,
  id: string,
  system_id: string,
  system_name: string,
  updated: string,
  updated_by: string,
};

export type CreateDeviceLinkEventInput = {
  created_by: string,
  device_link_id: string,
  event_id: string,
};

export type DeviceLinkEvent = {
  __typename: "DeviceLinkEvent",
  created: string,
  created_by: string,
  device_link_id: string,
  id: string,
  event_name: string,
  event_id: string,
  updated: string,
  updated_by: string,
};

export type CreateDeviceTypeInputV1 = {
  name: string,
  description: string,
  additional_info?: string | null,
  created_by: string,
};

export type DeviceTypeV1 = {
  __typename: "DeviceTypeV1",
  id: string,
  name: string,
  description: string,
  additional_info?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type CreateActionDeviceTypeInputV1 = {
  action_id: string,
  device_type_id: string,
  action_device_type: string,
  additional_info?: string | null,
  created_by: string,
};

export type ActionDeviceTypeV1 = {
  __typename: "ActionDeviceTypeV1",
  id: string,
  action_id: string,
  device_type_id: string,
  action_device_type: string,
  additional_info?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type CreateParameterInputV1 = {
  name: string,
  description: string,
  additional_info?: string | null,
  created_by: string,
};

export type ParameterV1 = {
  __typename: "ParameterV1",
  id: string,
  name: string,
  description: string,
  additional_info?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type CreateSiteCamerasInput = {
  siteCode: string,
  created: string,
  cameras: Array< CameraInput | null >,
};

export type CameraInput = {
  name: string,
  description?: string | null,
  systemIdentifier: string,
};

export type SiteCameras = {
  __typename: "SiteCameras",
  siteCode: string,
  created: string,
  cameras:  Array<Camera | null >,
};

export type Camera = {
  __typename: "Camera",
  name: string,
  description?: string | null,
  systemIdentifier: string,
};

export type CreateSystemInput = {
  auth_mode?: AuthMode | null,
  created_by: string,
  enabled: string,
  name: string,
  secondary_system_id?: string | null,
  segment_location: string,
  segment_name: string,
  segment_source: string,
  srvr_alrm_srvc_path: string,
  srvr_alrm_srvc_port: string,
  srvr_cmd_srvc_path: string,
  srvr_cmd_srvc_port: string,
  srvr_cnf_srvc_path: string,
  srvr_cnf_srvc_port: string,
  srvr_vpc_endpoint: string,
};

export enum AuthMode {
  BASIC = "BASIC",
  NTLM = "NTLM",
  NTLM_OR_BASIC = "NTLM_OR_BASIC",
}


export type System = {
  __typename: "System",
  auth_mode: AuthMode,
  created: string,
  created_by: string,
  enabled: string,
  id: string,
  name: string,
  secondary_system_id?: string | null,
  secondary_system_name?: string | null,
  segment_name: string,
  segment_location: string,
  segment_source: string,
  srvr_alrm_srvc_path: string,
  srvr_alrm_srvc_port: string,
  srvr_cmd_srvc_path: string,
  srvr_cmd_srvc_port: string,
  srvr_cnf_srvc_path: string,
  srvr_cnf_srvc_port: string,
  srvr_vpc_endpoint: string,
  updated: string,
  updated_by: string,
};

export type UserActionInput = {
  actionId: string,
  actionName: string,
  parameters?: string | null,
  timestamp: string,
  ttl: number,
  username: string,
};

export type UserAction = {
  __typename: "UserAction",
  actionId: string,
  actionName: string,
  parameters?: string | null,
  timestamp: string,
  ttl: number,
  username: string,
};

export type DeleteDeviceActionConditionGroupInputV1 = {
  id: string,
  updated_by: string,
};

export type DeleteDeviceActionConditionInputV1 = {
  id: string,
  updated_by: string,
};

export type DeleteDeviceActionParameterInputV1 = {
  id: string,
  updated_by: string,
};

export type DeleteDeviceActionInputV1 = {
  id: string,
  updated_by: string,
};

export type DeleteDeviceLinkInput = {
  id: string,
  updated_by: string,
};

export type DeleteDeviceLinkEventInput = {
  id: string,
  updated_by: string,
};

export type DeleteSystemInput = {
  id: string,
  updated_by: string,
};

export type UpdateActionInputV1 = {
  id: string,
  name: string,
  description: string,
  additional_info?: string | null,
  updated_by: string,
};

export type UpdateActionParameterInputV1 = {
  id: string,
  action_id: string,
  parameter_id: string,
  additional_info?: string | null,
  updated_by: string,
};

export type UpdateConditionOperandInputV1 = {
  id: string,
  name: string,
  description: string,
  additional_info?: string | null,
  updated_by: string,
};

export type UpdateActionDeviceTypeEventInputV1 = {
  id: string,
  device_event_id: string,
  action_device_type_id: string,
  additional_info?: string | null,
  updated_by: string,
};

export type UpdateDeviceActionConditionGroupInputV1 = {
  device_action_id: string,
  id: string,
  operator: string,
  updated_by: string,
};

export type UpdateDeviceActionConditionInputV1 = {
  group_id: string,
  id: string,
  operator: string,
  operand_id: string,
  value: string,
  updated_by: string,
};

export type UpdateDeviceActionParameterInputV1 = {
  device_action_id: string,
  action_id: string,
  parameter_id: string,
  id: string,
  updated_by: string,
  value?: string | null,
};

export type UpdateDeviceActionInputV1 = {
  site_code: string,
  input_device_id: string,
  id: string,
  output_device_id: string,
  additional_info?: string | null,
  updated_by: string,
};

export type UpdateDeviceActionInputEventInputV1 = {
  id: string,
  device_action_id: string,
  device_event_id: string,
  updated_by: string,
};

export type UpdateDeviceActionOutputActionInputV1 = {
  id: string,
  device_action_id: string,
  action_id: string,
  updated_by: string,
};

export type UpdateDeviceEventInputV1 = {
  id: string,
  name: string,
  description: string,
  additional_info?: string | null,
  updated_by: string,
};

export type UpdateDeviceLinkInput = {
  acd_device_name: string,
  acd_device_type: string,
  acd_parent_device_id: number,
  acd_child_device_id: number,
  acd_subchild_device_id: number,
  camera_name: string,
  camera_system_id: string,
  id: string,
  system_id: string,
  enabled: string,
  updated_by: string,
};

export type UpdateDeviceLinkEventInput = {
  event_id: string,
  id: string,
  updated_by: string,
};

export type UpdateDeviceTypeInputV1 = {
  id: string,
  name: string,
  description: string,
  additional_info?: string | null,
  updated_by: string,
};

export type UpdateActionDeviceTypeInputV1 = {
  id: string,
  action_id: string,
  device_type_id: string,
  action_device_type: string,
  additional_info?: string | null,
  updated_by: string,
};

export type UpdateParameterInputV1 = {
  id: string,
  name: string,
  description: string,
  additional_info?: string | null,
  updated_by: string,
};

export type UpdateSystemInput = {
  auth_mode?: AuthMode | null,
  enabled: string,
  id: string,
  name: string,
  segment_name: string,
  segment_location: string,
  segment_source: string,
  srvr_alrm_srvc_path: string,
  srvr_alrm_srvc_port: string,
  srvr_cmd_srvc_path: string,
  srvr_cmd_srvc_port: string,
  srvr_cnf_srvc_path: string,
  srvr_cnf_srvc_port: string,
  srvr_vpc_endpoint: string,
  secondary_system_id?: string | null,
  updated_by: string,
};

export type Event = {
  __typename: "Event",
  created: string,
  created_by: string,
  device_type: string,
  id: string,
  name: string,
  updated: string,
  updated_by: string,
};

export type EventLinks = {
  __typename: "EventLinks",
  event_links?:  Array<EventLink | null > | null,
};

export type EventLink = {
  __typename: "EventLink",
  device_link_id?: string | null,
  devices?: EventLinkDevices | null,
  events?: Array< string | null > | null,
  system?: SystemForEventLink | null,
};

export type EventLinkDevices = {
  __typename: "EventLinkDevices",
  cameraName: string,
  cameraSystemIdentifier: string,
  accessControlDeviceName: string,
  accessControlDeviceParentDeviceID: number,
  accessControlDeviceChildDeviceID: number,
  accessControlDeviceSubchildDeviceID: number,
  accessControlDeviceType: string,
};

export type SystemForEventLink = {
  __typename: "SystemForEventLink",
  auth_mode: AuthMode,
  system_id: string,
  secondary_system_id?: string | null,
  facman_sitename: string,
  srvr_alrm_srvc_path: string,
  srvr_alrm_srvc_port: string,
  srvr_cmd_srvc_path: string,
  srvr_cmd_srvc_port: string,
  srvr_cnf_srvc_path: string,
  srvr_cnf_srvc_port: string,
  srvr_vpc_endpoint: string,
};

export type AccessControlDevice = {
  __typename: "AccessControlDevice",
  Parent_DeviceID: number,
  Child_DeviceID: number,
  Subchild_DeviceID: number,
  Device_Type: string,
  DeviceName: string,
};

export type ActionDeviceTypeEventForActionDeviceTypeV1 = {
  __typename: "ActionDeviceTypeEventForActionDeviceTypeV1",
  id: string,
  action_device_type_id: string,
  action_device_type: string,
  action_id: string,
  action_name: string,
  device_event_id: string,
  device_event_name: string,
  device_type_id: string,
  device_type_name: string,
  additional_info?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export enum ListDevicesMethodName {
  getSiteDevices = "getSiteDevices",
}


export type ListDevicesResponseV1 = {
  __typename: "ListDevicesResponseV1",
  message: string,
  statusCode: number,
  devices:  Array<DeviceV1 | null >,
};

export type DeviceV1 = {
  __typename: "DeviceV1",
  id: string,
  device_type_id: string,
  device_type: string,
  device_type_name: string,
  device_family: string,
  name: string,
  source_system_device_id: string,
  device_id_key?: string | null,
  parent_id?: string | null,
  parent_name?: string | null,
  grand_parent_id?: string | null,
  grand_parent_name?: string | null,
  site_code?: string | null,
  site_type?: string | null,
  site_group?: string | null,
  site_region?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  country?: string | null,
  zone?: string | null,
  timezone_offset_minutes?: number | null,
  timezone_name?: string | null,
  system_id?: string | null,
  source_system?: string | null,
  source_stack?: string | null,
  source_location?: string | null,
  source_version?: string | null,
  source_instance?: string | null,
  last_updated_at?: string | null,
  device_int_id?: number | null,
  parent_device_int_id?: number | null,
  grand_parent_device_int_id?: number | null,
  deleted_at_timestamp?: string | null,
  communication_address?: string | null,
  communication_port?: number | null,
  communication_server_name?: string | null,
  device_ip_primary?: string | null,
  device_ip_secondary?: string | null,
  source_device_details?: string | null,
  reporting_details?: string | null,
};

export type ParameterForActionV1 = {
  __typename: "ParameterForActionV1",
  id: string,
  action_id: string,
  action_name: string,
  parameter_id: string,
  parameter_name: string,
  additional_info?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type Audit = {
  __typename: "Audit",
  event_id: string,
  table_name: string,
  action_tstamp_tx: string,
  action: string,
  row_data?: string | null,
  row_data_json?: string | null,
  changed_fields?: string | null,
  changed_fields_json?: string | null,
};

export type DeviceActionForSiteV1 = {
  __typename: "DeviceActionForSiteV1",
  id: string,
  site_code: string,
  device_action_condition_groups?:  Array<DeviceActionConditionGroupV1 | null > | null,
  device_action_input_events?:  Array<DeviceActionInputEventV1 | null > | null,
  device_action_output_actions?:  Array<DeviceActionOutputActionV1 | null > | null,
  device_action_parameters?:  Array<DeviceActionParameterV1 | null > | null,
  input_device_id: string,
  output_device_id: string,
  additional_info?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type DeviceTypeForActionV1 = {
  __typename: "DeviceTypeForActionV1",
  id: string,
  action_id: string,
  action_name: string,
  action_device_type: string,
  device_type_id: string,
  device_type_name: string,
  device_type_description: string,
  additional_info?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type DeviceLinkV2 = {
  __typename: "DeviceLinkV2",
  acd_device_name: string,
  acd_device_type: string,
  acd_parent_device_id: number,
  acd_child_device_id: number,
  acd_subchild_device_id: number,
  camera_name: string,
  camera_system_id: string,
  created: string,
  created_by: string,
  enabled: string,
  device_link_events?:  Array<DeviceLinkEvent | null > | null,
  id: string,
  system_id: string,
  system_name: string,
  updated: string,
  updated_by: string,
};

export type NotificationV1 = {
  __typename: "NotificationV1",
  dismissable: boolean,
  links?:  Array<NotificationLinkV1 | null > | null,
  message: string,
  name: string,
  type: NotificationTypeV1,
};

export type NotificationLinkV1 = {
  __typename: "NotificationLinkV1",
  target: string,
  text: string,
};

export enum NotificationTypeV1 {
  CRITICAL = "CRITICAL",
  WARNING = "WARNING",
  INFO = "INFO",
}


export type SIGInfraSite = {
  __typename: "SIGInfraSite",
  allSites?: boolean | null,
  sites?: Array< string | null > | null,
};

export type Permission = {
  __typename: "Permission",
  HASPERMISSION: string,
};

export type Site = {
  __typename: "Site",
  SegmentName: string,
  SegmentLocation: string,
  SegmentSource: string,
  SiteCode: string,
};

export type DeviceActionForSiteInputV1 = {
  id: string,
  site_code: string,
  device_action_condition_groups?: Array< DeviceActionConditionGroupInputV1 | null > | null,
  device_action_input_events?: Array< DeviceActionInputEventInputV1 | null > | null,
  device_action_output_actions?: Array< DeviceActionOutputActionInputV1 | null > | null,
  device_action_parameters?: Array< DeviceActionParameterInputV1 | null > | null,
  input_device_id: string,
  output_device_id: string,
  additional_info?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type DeviceActionConditionGroupInputV1 = {
  id: string,
  device_action_id: string,
  device_action_conditions?: Array< DeviceActionConditionInputV1 | null > | null,
  operator: string,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type DeviceActionConditionInputV1 = {
  id: string,
  group_id: string,
  operator: string,
  operand_id: string,
  operand_name?: string | null,
  value: string,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type DeviceActionInputEventInputV1 = {
  id: string,
  device_action_id: string,
  device_event_id: string,
  device_event_name?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type DeviceActionOutputActionInputV1 = {
  id: string,
  device_action_id: string,
  action_id: string,
  action_name?: string | null,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type DeviceActionParameterInputV1 = {
  id: string,
  device_action_id: string,
  action_id: string,
  action_name?: string | null,
  parameter_id: string,
  parameter_name?: string | null,
  value: string,
  created: string,
  created_by: string,
  updated: string,
  updated_by: string,
};

export type SNSResponse = {
  __typename: "SNSResponse",
  MessageId: string,
  SequenceNumber?: string | null,
};

export type CreateActionDeviceTypeEventV1MutationVariables = {
  input: CreateActionDeviceTypeEventInputV1,
};

export type CreateActionDeviceTypeEventV1Mutation = {
  createActionDeviceTypeEventV1?:  {
    __typename: "ActionDeviceTypeEventV1",
    id: string,
    device_event_id: string,
    action_device_type_id: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateActionParameterV1MutationVariables = {
  input: CreateActionParameterInputV1,
};

export type CreateActionParameterV1Mutation = {
  createActionParameterV1?:  {
    __typename: "ActionParameterV1",
    id: string,
    action_id: string,
    parameter_id: string,
    parameter_name?: string | null,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateActionV1MutationVariables = {
  input: CreateActionInputV1,
};

export type CreateActionV1Mutation = {
  createActionV1?:  {
    __typename: "ActionV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateConditionOperandV1MutationVariables = {
  input: CreateConditionOperandInputV1,
};

export type CreateConditionOperandV1Mutation = {
  createConditionOperandV1?:  {
    __typename: "ConditionOperandV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateDeviceActionConditionGroupV1MutationVariables = {
  input: CreateDeviceActionConditionGroupInputV1,
};

export type CreateDeviceActionConditionGroupV1Mutation = {
  createDeviceActionConditionGroupV1?:  {
    __typename: "DeviceActionConditionGroupV1",
    id: string,
    device_action_id: string,
    device_action_conditions?:  Array< {
      __typename: "DeviceActionConditionV1",
      id: string,
      group_id: string,
      operator: string,
      operand_id: string,
      operand_name?: string | null,
      value: string,
      created: string,
      created_by: string,
      updated: string,
      updated_by: string,
    } | null > | null,
    operator: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateDeviceActionConditionV1MutationVariables = {
  input: CreateDeviceActionConditionInputV1,
};

export type CreateDeviceActionConditionV1Mutation = {
  createDeviceActionConditionV1?:  {
    __typename: "DeviceActionConditionV1",
    id: string,
    group_id: string,
    operator: string,
    operand_id: string,
    operand_name?: string | null,
    value: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateDeviceActionParameterV1MutationVariables = {
  input: CreateDeviceActionParameterInputV1,
};

export type CreateDeviceActionParameterV1Mutation = {
  createDeviceActionParameterV1?:  {
    __typename: "DeviceActionParameterV1",
    id: string,
    device_action_id: string,
    action_id: string,
    action_name?: string | null,
    parameter_id: string,
    parameter_name?: string | null,
    value: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateDeviceActionV1MutationVariables = {
  input: CreateDeviceActionInputV1,
};

export type CreateDeviceActionV1Mutation = {
  createDeviceActionV1?:  {
    __typename: "DeviceActionV1",
    id: string,
    site_code: string,
    input_device_id: string,
    output_device_id: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateDeviceActionInputEventV1MutationVariables = {
  input: CreateDeviceActionInputEventInputV1,
};

export type CreateDeviceActionInputEventV1Mutation = {
  createDeviceActionInputEventV1?:  {
    __typename: "DeviceActionInputEventV1",
    id: string,
    device_action_id: string,
    device_event_id: string,
    device_event_name?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateDeviceActionOutputActionV1MutationVariables = {
  input: CreateDeviceActionOutputActionInputV1,
};

export type CreateDeviceActionOutputActionV1Mutation = {
  createDeviceActionOutputActionV1?:  {
    __typename: "DeviceActionOutputActionV1",
    id: string,
    device_action_id: string,
    action_id: string,
    action_name?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateDeviceEventV1MutationVariables = {
  input: CreateDeviceEventInputV1,
};

export type CreateDeviceEventV1Mutation = {
  createDeviceEventV1?:  {
    __typename: "DeviceEventV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateDeviceLinkMutationVariables = {
  input: CreateDeviceLinkInput,
};

export type CreateDeviceLinkMutation = {
  createDeviceLink?:  {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateDeviceLinkV1MutationVariables = {
  input: CreateDeviceLinkInput,
};

export type CreateDeviceLinkV1Mutation = {
  createDeviceLinkV1?:  {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateDeviceLinkEventMutationVariables = {
  input: CreateDeviceLinkEventInput,
};

export type CreateDeviceLinkEventMutation = {
  createDeviceLinkEvent?:  {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateDeviceLinkEventV1MutationVariables = {
  input: CreateDeviceLinkEventInput,
};

export type CreateDeviceLinkEventV1Mutation = {
  createDeviceLinkEventV1?:  {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateDeviceTypeV1MutationVariables = {
  input: CreateDeviceTypeInputV1,
};

export type CreateDeviceTypeV1Mutation = {
  createDeviceTypeV1?:  {
    __typename: "DeviceTypeV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateActionDeviceTypeV1MutationVariables = {
  input: CreateActionDeviceTypeInputV1,
};

export type CreateActionDeviceTypeV1Mutation = {
  createActionDeviceTypeV1?:  {
    __typename: "ActionDeviceTypeV1",
    id: string,
    action_id: string,
    device_type_id: string,
    action_device_type: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateParameterV1MutationVariables = {
  input: CreateParameterInputV1,
};

export type CreateParameterV1Mutation = {
  createParameterV1?:  {
    __typename: "ParameterV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateSiteCamerasMutationVariables = {
  input: CreateSiteCamerasInput,
};

export type CreateSiteCamerasMutation = {
  createSiteCameras?:  {
    __typename: "SiteCameras",
    siteCode: string,
    created: string,
    cameras:  Array< {
      __typename: "Camera",
      name: string,
      description?: string | null,
      systemIdentifier: string,
    } | null >,
  } | null,
};

export type CreateSiteCamerasV1MutationVariables = {
  input: CreateSiteCamerasInput,
};

export type CreateSiteCamerasV1Mutation = {
  createSiteCamerasV1?:  {
    __typename: "SiteCameras",
    siteCode: string,
    created: string,
    cameras:  Array< {
      __typename: "Camera",
      name: string,
      description?: string | null,
      systemIdentifier: string,
    } | null >,
  } | null,
};

export type CreateSystemMutationVariables = {
  input: CreateSystemInput,
};

export type CreateSystemMutation = {
  createSystem?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateSystemV1MutationVariables = {
  input: CreateSystemInput,
};

export type CreateSystemV1Mutation = {
  createSystemV1?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateUserActionMutationVariables = {
  input: UserActionInput,
};

export type CreateUserActionMutation = {
  createUserAction?:  {
    __typename: "UserAction",
    actionId: string,
    actionName: string,
    parameters?: string | null,
    timestamp: string,
    ttl: number,
    username: string,
  } | null,
};

export type DeleteActionV1MutationVariables = {
  id: string,
};

export type DeleteActionV1Mutation = {
  deleteActionV1?:  {
    __typename: "ActionV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteActionParameterV1MutationVariables = {
  id: string,
};

export type DeleteActionParameterV1Mutation = {
  deleteActionParameterV1?:  {
    __typename: "ActionParameterV1",
    id: string,
    action_id: string,
    parameter_id: string,
    parameter_name?: string | null,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteConditionOperandV1MutationVariables = {
  id: string,
};

export type DeleteConditionOperandV1Mutation = {
  deleteConditionOperandV1?:  {
    __typename: "ConditionOperandV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteDeviceActionConditionGroupV1MutationVariables = {
  input: DeleteDeviceActionConditionGroupInputV1,
};

export type DeleteDeviceActionConditionGroupV1Mutation = {
  deleteDeviceActionConditionGroupV1?:  {
    __typename: "DeviceActionConditionGroupV1",
    id: string,
    device_action_id: string,
    device_action_conditions?:  Array< {
      __typename: "DeviceActionConditionV1",
      id: string,
      group_id: string,
      operator: string,
      operand_id: string,
      operand_name?: string | null,
      value: string,
      created: string,
      created_by: string,
      updated: string,
      updated_by: string,
    } | null > | null,
    operator: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteDeviceActionConditionV1MutationVariables = {
  input: DeleteDeviceActionConditionInputV1,
};

export type DeleteDeviceActionConditionV1Mutation = {
  deleteDeviceActionConditionV1?:  {
    __typename: "DeviceActionConditionV1",
    id: string,
    group_id: string,
    operator: string,
    operand_id: string,
    operand_name?: string | null,
    value: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteDeviceActionParameterV1MutationVariables = {
  input: DeleteDeviceActionParameterInputV1,
};

export type DeleteDeviceActionParameterV1Mutation = {
  deleteDeviceActionParameterV1?:  {
    __typename: "DeviceActionParameterV1",
    id: string,
    device_action_id: string,
    action_id: string,
    action_name?: string | null,
    parameter_id: string,
    parameter_name?: string | null,
    value: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteDeviceActionV1MutationVariables = {
  input: DeleteDeviceActionInputV1,
};

export type DeleteDeviceActionV1Mutation = {
  deleteDeviceActionV1?:  {
    __typename: "DeviceActionV1",
    id: string,
    site_code: string,
    input_device_id: string,
    output_device_id: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteDeviceActionInputEventV1MutationVariables = {
  id: string,
  updated_by: string,
};

export type DeleteDeviceActionInputEventV1Mutation = {
  deleteDeviceActionInputEventV1?:  {
    __typename: "DeviceActionInputEventV1",
    id: string,
    device_action_id: string,
    device_event_id: string,
    device_event_name?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteDeviceActionOutputActionV1MutationVariables = {
  id: string,
  updated_by: string,
};

export type DeleteDeviceActionOutputActionV1Mutation = {
  deleteDeviceActionOutputActionV1?:  {
    __typename: "DeviceActionOutputActionV1",
    id: string,
    device_action_id: string,
    action_id: string,
    action_name?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteDeviceEventV1MutationVariables = {
  id: string,
};

export type DeleteDeviceEventV1Mutation = {
  deleteDeviceEventV1?:  {
    __typename: "DeviceEventV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteActionDeviceTypeEventV1MutationVariables = {
  id: string,
};

export type DeleteActionDeviceTypeEventV1Mutation = {
  deleteActionDeviceTypeEventV1?:  {
    __typename: "ActionDeviceTypeEventV1",
    id: string,
    device_event_id: string,
    action_device_type_id: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteDeviceLinkMutationVariables = {
  input: DeleteDeviceLinkInput,
};

export type DeleteDeviceLinkMutation = {
  deleteDeviceLink?:  {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteDeviceLinkV1MutationVariables = {
  input: DeleteDeviceLinkInput,
};

export type DeleteDeviceLinkV1Mutation = {
  deleteDeviceLinkV1?:  {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteDeviceLinkEventMutationVariables = {
  input: DeleteDeviceLinkEventInput,
};

export type DeleteDeviceLinkEventMutation = {
  deleteDeviceLinkEvent?:  {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteDeviceLinkEventV1MutationVariables = {
  input: DeleteDeviceLinkEventInput,
};

export type DeleteDeviceLinkEventV1Mutation = {
  deleteDeviceLinkEventV1?:  {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteDeviceTypeV1MutationVariables = {
  id: string,
};

export type DeleteDeviceTypeV1Mutation = {
  deleteDeviceTypeV1?:  {
    __typename: "DeviceTypeV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteActionDeviceTypeV1MutationVariables = {
  id: string,
};

export type DeleteActionDeviceTypeV1Mutation = {
  deleteActionDeviceTypeV1?:  {
    __typename: "ActionDeviceTypeV1",
    id: string,
    action_id: string,
    device_type_id: string,
    action_device_type: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteParameterV1MutationVariables = {
  id: string,
};

export type DeleteParameterV1Mutation = {
  deleteParameterV1?:  {
    __typename: "ParameterV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteSystemMutationVariables = {
  input: DeleteSystemInput,
};

export type DeleteSystemMutation = {
  deleteSystem?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteSystemV1MutationVariables = {
  input: DeleteSystemInput,
};

export type DeleteSystemV1Mutation = {
  deleteSystemV1?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateActionV1MutationVariables = {
  input: UpdateActionInputV1,
};

export type UpdateActionV1Mutation = {
  updateActionV1?:  {
    __typename: "ActionV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateActionParameterV1MutationVariables = {
  input: UpdateActionParameterInputV1,
};

export type UpdateActionParameterV1Mutation = {
  updateActionParameterV1?:  {
    __typename: "ActionParameterV1",
    id: string,
    action_id: string,
    parameter_id: string,
    parameter_name?: string | null,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateConditionOperandV1MutationVariables = {
  input: UpdateConditionOperandInputV1,
};

export type UpdateConditionOperandV1Mutation = {
  updateConditionOperandV1?:  {
    __typename: "ConditionOperandV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateActionDeviceTypeEventV1MutationVariables = {
  input: UpdateActionDeviceTypeEventInputV1,
};

export type UpdateActionDeviceTypeEventV1Mutation = {
  updateActionDeviceTypeEventV1?:  {
    __typename: "ActionDeviceTypeEventV1",
    id: string,
    device_event_id: string,
    action_device_type_id: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateDeviceActionConditionGroupV1MutationVariables = {
  input: UpdateDeviceActionConditionGroupInputV1,
};

export type UpdateDeviceActionConditionGroupV1Mutation = {
  updateDeviceActionConditionGroupV1?:  {
    __typename: "DeviceActionConditionGroupV1",
    id: string,
    device_action_id: string,
    device_action_conditions?:  Array< {
      __typename: "DeviceActionConditionV1",
      id: string,
      group_id: string,
      operator: string,
      operand_id: string,
      operand_name?: string | null,
      value: string,
      created: string,
      created_by: string,
      updated: string,
      updated_by: string,
    } | null > | null,
    operator: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateDeviceActionConditionV1MutationVariables = {
  input: UpdateDeviceActionConditionInputV1,
};

export type UpdateDeviceActionConditionV1Mutation = {
  updateDeviceActionConditionV1?:  {
    __typename: "DeviceActionConditionV1",
    id: string,
    group_id: string,
    operator: string,
    operand_id: string,
    operand_name?: string | null,
    value: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateDeviceActionParameterV1MutationVariables = {
  input: UpdateDeviceActionParameterInputV1,
};

export type UpdateDeviceActionParameterV1Mutation = {
  updateDeviceActionParameterV1?:  {
    __typename: "DeviceActionParameterV1",
    id: string,
    device_action_id: string,
    action_id: string,
    action_name?: string | null,
    parameter_id: string,
    parameter_name?: string | null,
    value: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateDeviceActionV1MutationVariables = {
  input: UpdateDeviceActionInputV1,
};

export type UpdateDeviceActionV1Mutation = {
  updateDeviceActionV1?:  {
    __typename: "DeviceActionV1",
    id: string,
    site_code: string,
    input_device_id: string,
    output_device_id: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateDeviceActionInputEventV1MutationVariables = {
  input: UpdateDeviceActionInputEventInputV1,
};

export type UpdateDeviceActionInputEventV1Mutation = {
  updateDeviceActionInputEventV1?:  {
    __typename: "DeviceActionInputEventV1",
    id: string,
    device_action_id: string,
    device_event_id: string,
    device_event_name?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateDeviceActionOutputActionV1MutationVariables = {
  input: UpdateDeviceActionOutputActionInputV1,
};

export type UpdateDeviceActionOutputActionV1Mutation = {
  updateDeviceActionOutputActionV1?:  {
    __typename: "DeviceActionOutputActionV1",
    id: string,
    device_action_id: string,
    action_id: string,
    action_name?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateDeviceEventV1MutationVariables = {
  input: UpdateDeviceEventInputV1,
};

export type UpdateDeviceEventV1Mutation = {
  updateDeviceEventV1?:  {
    __typename: "DeviceEventV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateDeviceLinkMutationVariables = {
  input: UpdateDeviceLinkInput,
};

export type UpdateDeviceLinkMutation = {
  updateDeviceLink?:  {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateDeviceLinkV1MutationVariables = {
  input: UpdateDeviceLinkInput,
};

export type UpdateDeviceLinkV1Mutation = {
  updateDeviceLinkV1?:  {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateDeviceLinkEventMutationVariables = {
  input: UpdateDeviceLinkEventInput,
};

export type UpdateDeviceLinkEventMutation = {
  updateDeviceLinkEvent?:  {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateDeviceLinkEventV1MutationVariables = {
  input: UpdateDeviceLinkEventInput,
};

export type UpdateDeviceLinkEventV1Mutation = {
  updateDeviceLinkEventV1?:  {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateDeviceTypeV1MutationVariables = {
  input: UpdateDeviceTypeInputV1,
};

export type UpdateDeviceTypeV1Mutation = {
  updateDeviceTypeV1?:  {
    __typename: "DeviceTypeV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateActionDeviceTypeV1MutationVariables = {
  input: UpdateActionDeviceTypeInputV1,
};

export type UpdateActionDeviceTypeV1Mutation = {
  updateActionDeviceTypeV1?:  {
    __typename: "ActionDeviceTypeV1",
    id: string,
    action_id: string,
    device_type_id: string,
    action_device_type: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateParameterV1MutationVariables = {
  input: UpdateParameterInputV1,
};

export type UpdateParameterV1Mutation = {
  updateParameterV1?:  {
    __typename: "ParameterV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateSystemMutationVariables = {
  input: UpdateSystemInput,
};

export type UpdateSystemMutation = {
  updateSystem?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateSystemV1MutationVariables = {
  input: UpdateSystemInput,
};

export type UpdateSystemV1Mutation = {
  updateSystemV1?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type Device_link_eventsQueryVariables = {
  id: string,
};

export type Device_link_eventsQuery = {
  device_link_events?:  Array< {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type GetActionV1QueryVariables = {
  id: string,
};

export type GetActionV1Query = {
  getActionV1?:  {
    __typename: "ActionV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetActionParameterV1QueryVariables = {
  id: string,
};

export type GetActionParameterV1Query = {
  getActionParameterV1?:  {
    __typename: "ActionParameterV1",
    id: string,
    action_id: string,
    parameter_id: string,
    parameter_name?: string | null,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetCachedSiteCamerasQueryVariables = {
  siteCode: string,
};

export type GetCachedSiteCamerasQuery = {
  getCachedSiteCameras?:  {
    __typename: "SiteCameras",
    siteCode: string,
    created: string,
    cameras:  Array< {
      __typename: "Camera",
      name: string,
      description?: string | null,
      systemIdentifier: string,
    } | null >,
  } | null,
};

export type GetCachedSiteCamerasV1QueryVariables = {
  siteCode: string,
};

export type GetCachedSiteCamerasV1Query = {
  getCachedSiteCamerasV1?:  {
    __typename: "SiteCameras",
    siteCode: string,
    created: string,
    cameras:  Array< {
      __typename: "Camera",
      name: string,
      description?: string | null,
      systemIdentifier: string,
    } | null >,
  } | null,
};

export type GetConditionOperandV1QueryVariables = {
  id: string,
};

export type GetConditionOperandV1Query = {
  getConditionOperandV1?:  {
    __typename: "ConditionOperandV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetDeviceActionV1QueryVariables = {
  id: string,
};

export type GetDeviceActionV1Query = {
  getDeviceActionV1?:  {
    __typename: "DeviceActionV1",
    id: string,
    site_code: string,
    input_device_id: string,
    output_device_id: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetDeviceActionConditionGroupV1QueryVariables = {
  id: string,
};

export type GetDeviceActionConditionGroupV1Query = {
  getDeviceActionConditionGroupV1?:  {
    __typename: "DeviceActionConditionGroupV1",
    id: string,
    device_action_id: string,
    device_action_conditions?:  Array< {
      __typename: "DeviceActionConditionV1",
      id: string,
      group_id: string,
      operator: string,
      operand_id: string,
      operand_name?: string | null,
      value: string,
      created: string,
      created_by: string,
      updated: string,
      updated_by: string,
    } | null > | null,
    operator: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetDeviceActionConditionV1QueryVariables = {
  id: string,
};

export type GetDeviceActionConditionV1Query = {
  getDeviceActionConditionV1?:  {
    __typename: "DeviceActionConditionV1",
    id: string,
    group_id: string,
    operator: string,
    operand_id: string,
    operand_name?: string | null,
    value: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetDeviceActionParameterV1QueryVariables = {
  id: string,
};

export type GetDeviceActionParameterV1Query = {
  getDeviceActionParameterV1?:  {
    __typename: "DeviceActionParameterV1",
    id: string,
    device_action_id: string,
    action_id: string,
    action_name?: string | null,
    parameter_id: string,
    parameter_name?: string | null,
    value: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetDeviceEventV1QueryVariables = {
  id: string,
};

export type GetDeviceEventV1Query = {
  getDeviceEventV1?:  {
    __typename: "DeviceEventV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetActionDeviceTypeEventV1QueryVariables = {
  id: string,
};

export type GetActionDeviceTypeEventV1Query = {
  getActionDeviceTypeEventV1?:  {
    __typename: "ActionDeviceTypeEventV1",
    id: string,
    device_event_id: string,
    action_device_type_id: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetDeviceLinkQueryVariables = {
  id: string,
};

export type GetDeviceLinkQuery = {
  getDeviceLink?:  {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetDeviceLinkV1QueryVariables = {
  id: string,
};

export type GetDeviceLinkV1Query = {
  getDeviceLinkV1?:  {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetDeviceLinkEventQueryVariables = {
  id: string,
};

export type GetDeviceLinkEventQuery = {
  getDeviceLinkEvent?:  {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetDeviceLinkEventV1QueryVariables = {
  id: string,
};

export type GetDeviceLinkEventV1Query = {
  getDeviceLinkEventV1?:  {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetDeviceTypeV1QueryVariables = {
  id: string,
};

export type GetDeviceTypeV1Query = {
  getDeviceTypeV1?:  {
    __typename: "DeviceTypeV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetActionDeviceTypeV1QueryVariables = {
  id: string,
};

export type GetActionDeviceTypeV1Query = {
  getActionDeviceTypeV1?:  {
    __typename: "ActionDeviceTypeV1",
    id: string,
    action_id: string,
    device_type_id: string,
    action_device_type: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetEventQueryVariables = {
  id: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    created: string,
    created_by: string,
    device_type: string,
    id: string,
    name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetEventV1QueryVariables = {
  id: string,
};

export type GetEventV1Query = {
  getEventV1?:  {
    __typename: "Event",
    created: string,
    created_by: string,
    device_type: string,
    id: string,
    name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetEventLinksForSystemIdQueryVariables = {
  system_id: string,
};

export type GetEventLinksForSystemIdQuery = {
  getEventLinksForSystemId?:  {
    __typename: "EventLinks",
    event_links?:  Array< {
      __typename: "EventLink",
      device_link_id?: string | null,
      devices?:  {
        __typename: "EventLinkDevices",
        cameraName: string,
        cameraSystemIdentifier: string,
        accessControlDeviceName: string,
        accessControlDeviceParentDeviceID: number,
        accessControlDeviceChildDeviceID: number,
        accessControlDeviceSubchildDeviceID: number,
        accessControlDeviceType: string,
      } | null,
      events?: Array< string | null > | null,
      system?:  {
        __typename: "SystemForEventLink",
        auth_mode: AuthMode,
        system_id: string,
        secondary_system_id?: string | null,
        facman_sitename: string,
        srvr_alrm_srvc_path: string,
        srvr_alrm_srvc_port: string,
        srvr_cmd_srvc_path: string,
        srvr_cmd_srvc_port: string,
        srvr_cnf_srvc_path: string,
        srvr_cnf_srvc_port: string,
        srvr_vpc_endpoint: string,
      } | null,
    } | null > | null,
  } | null,
};

export type GetEventLinksForSystemIdV1QueryVariables = {
  system_id: string,
};

export type GetEventLinksForSystemIdV1Query = {
  getEventLinksForSystemIdV1?:  {
    __typename: "EventLinks",
    event_links?:  Array< {
      __typename: "EventLink",
      device_link_id?: string | null,
      devices?:  {
        __typename: "EventLinkDevices",
        cameraName: string,
        cameraSystemIdentifier: string,
        accessControlDeviceName: string,
        accessControlDeviceParentDeviceID: number,
        accessControlDeviceChildDeviceID: number,
        accessControlDeviceSubchildDeviceID: number,
        accessControlDeviceType: string,
      } | null,
      events?: Array< string | null > | null,
      system?:  {
        __typename: "SystemForEventLink",
        auth_mode: AuthMode,
        system_id: string,
        secondary_system_id?: string | null,
        facman_sitename: string,
        srvr_alrm_srvc_path: string,
        srvr_alrm_srvc_port: string,
        srvr_cmd_srvc_path: string,
        srvr_cmd_srvc_port: string,
        srvr_cnf_srvc_path: string,
        srvr_cnf_srvc_port: string,
        srvr_vpc_endpoint: string,
      } | null,
    } | null > | null,
  } | null,
};

export type GetParameterV1QueryVariables = {
  id: string,
};

export type GetParameterV1Query = {
  getParameterV1?:  {
    __typename: "ParameterV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetParameterStoreValueQueryVariables = {
  parameterName: string,
};

export type GetParameterStoreValueQuery = {
  getParameterStoreValue?: string | null,
};

export type GetSystemQueryVariables = {
  id: string,
};

export type GetSystemQuery = {
  getSystem?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetSystemV1QueryVariables = {
  id: string,
};

export type GetSystemV1Query = {
  getSystemV1?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetSystemForSegmentNameQueryVariables = {
  segmentName: string,
};

export type GetSystemForSegmentNameQuery = {
  getSystemForSegmentName?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetSystemForSegmentNameV1QueryVariables = {
  segmentName: string,
};

export type GetSystemForSegmentNameV1Query = {
  getSystemForSegmentNameV1?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type ListAccessControlDevicesQueryVariables = {
  path: string,
};

export type ListAccessControlDevicesQuery = {
  listAccessControlDevices?:  Array< {
    __typename: "AccessControlDevice",
    Parent_DeviceID: number,
    Child_DeviceID: number,
    Subchild_DeviceID: number,
    Device_Type: string,
    DeviceName: string,
  } | null > | null,
};

export type ListAccessControlDevicesV1QueryVariables = {
  path: string,
};

export type ListAccessControlDevicesV1Query = {
  listAccessControlDevicesV1?:  Array< {
    __typename: "AccessControlDevice",
    Parent_DeviceID: number,
    Child_DeviceID: number,
    Subchild_DeviceID: number,
    Device_Type: string,
    DeviceName: string,
  } | null > | null,
};

export type ListActionDeviceTypesV1Query = {
  listActionDeviceTypesV1?:  Array< {
    __typename: "ActionDeviceTypeV1",
    id: string,
    action_id: string,
    device_type_id: string,
    action_device_type: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListActionDeviceTypeEventsForActionDeviceTypeV1QueryVariables = {
  action_device_type_id: string,
};

export type ListActionDeviceTypeEventsForActionDeviceTypeV1Query = {
  listActionDeviceTypeEventsForActionDeviceTypeV1?:  Array< {
    __typename: "ActionDeviceTypeEventForActionDeviceTypeV1",
    id: string,
    action_device_type_id: string,
    action_device_type: string,
    action_id: string,
    action_name: string,
    device_event_id: string,
    device_event_name: string,
    device_type_id: string,
    device_type_name: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListActionDeviceTypeEventsV1Query = {
  listActionDeviceTypeEventsV1?:  Array< {
    __typename: "ActionDeviceTypeEventV1",
    id: string,
    device_event_id: string,
    action_device_type_id: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListActionsV1Query = {
  listActionsV1?:  Array< {
    __typename: "ActionV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListActionParametersV1QueryVariables = {
  action_id: string,
};

export type ListActionParametersV1Query = {
  listActionParametersV1?:  Array< {
    __typename: "ActionParameterV1",
    id: string,
    action_id: string,
    parameter_id: string,
    parameter_name?: string | null,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDevicesQueryVariables = {
  methodName?: ListDevicesMethodName | null,
  parameters: string,
};

export type ListDevicesQuery = {
  listDevices?:  {
    __typename: "ListDevicesResponseV1",
    message: string,
    statusCode: number,
    devices:  Array< {
      __typename: "DeviceV1",
      id: string,
      device_type_id: string,
      device_type: string,
      device_type_name: string,
      device_family: string,
      name: string,
      source_system_device_id: string,
      device_id_key?: string | null,
      parent_id?: string | null,
      parent_name?: string | null,
      grand_parent_id?: string | null,
      grand_parent_name?: string | null,
      site_code?: string | null,
      site_type?: string | null,
      site_group?: string | null,
      site_region?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      country?: string | null,
      zone?: string | null,
      timezone_offset_minutes?: number | null,
      timezone_name?: string | null,
      system_id?: string | null,
      source_system?: string | null,
      source_stack?: string | null,
      source_location?: string | null,
      source_version?: string | null,
      source_instance?: string | null,
      last_updated_at?: string | null,
      device_int_id?: number | null,
      parent_device_int_id?: number | null,
      grand_parent_device_int_id?: number | null,
      deleted_at_timestamp?: string | null,
      communication_address?: string | null,
      communication_port?: number | null,
      communication_server_name?: string | null,
      device_ip_primary?: string | null,
      device_ip_secondary?: string | null,
      source_device_details?: string | null,
      reporting_details?: string | null,
    } | null >,
  } | null,
};

export type ListDevicesV1QueryVariables = {
  methodName?: ListDevicesMethodName | null,
  parameters: string,
};

export type ListDevicesV1Query = {
  listDevicesV1?:  {
    __typename: "ListDevicesResponseV1",
    message: string,
    statusCode: number,
    devices:  Array< {
      __typename: "DeviceV1",
      id: string,
      device_type_id: string,
      device_type: string,
      device_type_name: string,
      device_family: string,
      name: string,
      source_system_device_id: string,
      device_id_key?: string | null,
      parent_id?: string | null,
      parent_name?: string | null,
      grand_parent_id?: string | null,
      grand_parent_name?: string | null,
      site_code?: string | null,
      site_type?: string | null,
      site_group?: string | null,
      site_region?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      country?: string | null,
      zone?: string | null,
      timezone_offset_minutes?: number | null,
      timezone_name?: string | null,
      system_id?: string | null,
      source_system?: string | null,
      source_stack?: string | null,
      source_location?: string | null,
      source_version?: string | null,
      source_instance?: string | null,
      last_updated_at?: string | null,
      device_int_id?: number | null,
      parent_device_int_id?: number | null,
      grand_parent_device_int_id?: number | null,
      deleted_at_timestamp?: string | null,
      communication_address?: string | null,
      communication_port?: number | null,
      communication_server_name?: string | null,
      device_ip_primary?: string | null,
      device_ip_secondary?: string | null,
      source_device_details?: string | null,
      reporting_details?: string | null,
    } | null >,
  } | null,
};

export type ListParametersForActionV1QueryVariables = {
  action_id: string,
};

export type ListParametersForActionV1Query = {
  listParametersForActionV1?:  Array< {
    __typename: "ParameterForActionV1",
    id: string,
    action_id: string,
    action_name: string,
    parameter_id: string,
    parameter_name: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListAuditQueryVariables = {
  startDate: string,
  endDate: string,
  limit: number,
  offset: number,
};

export type ListAuditQuery = {
  listAudit?:  Array< {
    __typename: "Audit",
    event_id: string,
    table_name: string,
    action_tstamp_tx: string,
    action: string,
    row_data?: string | null,
    row_data_json?: string | null,
    changed_fields?: string | null,
    changed_fields_json?: string | null,
  } | null > | null,
};

export type ListAuditV1QueryVariables = {
  startDate: string,
  endDate: string,
  limit: number,
  offset: number,
};

export type ListAuditV1Query = {
  listAuditV1?:  Array< {
    __typename: "Audit",
    event_id: string,
    table_name: string,
    action_tstamp_tx: string,
    action: string,
    row_data?: string | null,
    row_data_json?: string | null,
    changed_fields?: string | null,
    changed_fields_json?: string | null,
  } | null > | null,
};

export type ListConditionOperandsV1Query = {
  listConditionOperandsV1?:  Array< {
    __typename: "ConditionOperandV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceActionOutputActionsV1QueryVariables = {
  device_action_id: string,
};

export type ListDeviceActionOutputActionsV1Query = {
  listDeviceActionOutputActionsV1?:  Array< {
    __typename: "DeviceActionOutputActionV1",
    id: string,
    device_action_id: string,
    action_id: string,
    action_name?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceActionConditionGroupsForDeviceActionV1QueryVariables = {
  device_action_id: string,
};

export type ListDeviceActionConditionGroupsForDeviceActionV1Query = {
  listDeviceActionConditionGroupsForDeviceActionV1?:  Array< {
    __typename: "DeviceActionConditionGroupV1",
    id: string,
    device_action_id: string,
    device_action_conditions?:  Array< {
      __typename: "DeviceActionConditionV1",
      id: string,
      group_id: string,
      operator: string,
      operand_id: string,
      operand_name?: string | null,
      value: string,
      created: string,
      created_by: string,
      updated: string,
      updated_by: string,
    } | null > | null,
    operator: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceActionConditionsForGroupV1QueryVariables = {
  group_id: string,
};

export type ListDeviceActionConditionsForGroupV1Query = {
  listDeviceActionConditionsForGroupV1?:  Array< {
    __typename: "DeviceActionConditionV1",
    id: string,
    group_id: string,
    operator: string,
    operand_id: string,
    operand_name?: string | null,
    value: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceActionParametersForDeviceActionV1QueryVariables = {
  device_action_id: string,
};

export type ListDeviceActionParametersForDeviceActionV1Query = {
  listDeviceActionParametersForDeviceActionV1?:  Array< {
    __typename: "DeviceActionParameterV1",
    id: string,
    device_action_id: string,
    action_id: string,
    action_name?: string | null,
    parameter_id: string,
    parameter_name?: string | null,
    value: string,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceActionsForSiteV1QueryVariables = {
  site_code: string,
};

export type ListDeviceActionsForSiteV1Query = {
  listDeviceActionsForSiteV1?:  Array< {
    __typename: "DeviceActionForSiteV1",
    id: string,
    site_code: string,
    device_action_condition_groups?:  Array< {
      __typename: "DeviceActionConditionGroupV1",
      id: string,
      device_action_id: string,
      device_action_conditions?:  Array< {
        __typename: "DeviceActionConditionV1",
        id: string,
        group_id: string,
        operator: string,
        operand_id: string,
        operand_name?: string | null,
        value: string,
        created: string,
        created_by: string,
        updated: string,
        updated_by: string,
      } | null > | null,
      operator: string,
      created: string,
      created_by: string,
      updated: string,
      updated_by: string,
    } | null > | null,
    device_action_input_events?:  Array< {
      __typename: "DeviceActionInputEventV1",
      id: string,
      device_action_id: string,
      device_event_id: string,
      device_event_name?: string | null,
      created: string,
      created_by: string,
      updated: string,
      updated_by: string,
    } | null > | null,
    device_action_output_actions?:  Array< {
      __typename: "DeviceActionOutputActionV1",
      id: string,
      device_action_id: string,
      action_id: string,
      action_name?: string | null,
      created: string,
      created_by: string,
      updated: string,
      updated_by: string,
    } | null > | null,
    device_action_parameters?:  Array< {
      __typename: "DeviceActionParameterV1",
      id: string,
      device_action_id: string,
      action_id: string,
      action_name?: string | null,
      parameter_id: string,
      parameter_name?: string | null,
      value: string,
      created: string,
      created_by: string,
      updated: string,
      updated_by: string,
    } | null > | null,
    input_device_id: string,
    output_device_id: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceEventsV1Query = {
  listDeviceEventsV1?:  Array< {
    __typename: "DeviceEventV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceTypesV1Query = {
  listDeviceTypesV1?:  Array< {
    __typename: "DeviceTypeV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceTypesForActionV1QueryVariables = {
  action_id: string,
};

export type ListDeviceTypesForActionV1Query = {
  listDeviceTypesForActionV1?:  Array< {
    __typename: "DeviceTypeForActionV1",
    id: string,
    action_id: string,
    action_name: string,
    action_device_type: string,
    device_type_id: string,
    device_type_name: string,
    device_type_description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListSiteCamerasQueryVariables = {
  siteCode: string,
  authMode: string,
  cmdSrvcEndpoint: string,
  cmdSrvcPath: string,
  cmdSrvcPort: string,
};

export type ListSiteCamerasQuery = {
  listSiteCameras?:  Array< {
    __typename: "Camera",
    name: string,
    description?: string | null,
    systemIdentifier: string,
  } | null > | null,
};

export type ListSiteCamerasV1QueryVariables = {
  siteCode: string,
  authMode: string,
  cmdSrvcEndpoint: string,
  cmdSrvcPath: string,
  cmdSrvcPort: string,
};

export type ListSiteCamerasV1Query = {
  listSiteCamerasV1?:  Array< {
    __typename: "Camera",
    name: string,
    description?: string | null,
    systemIdentifier: string,
  } | null > | null,
};

export type ListDeviceLinkEventsForDeviceLinkQueryVariables = {
  device_link_id: string,
};

export type ListDeviceLinkEventsForDeviceLinkQuery = {
  listDeviceLinkEventsForDeviceLink?:  Array< {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceLinkEventsForDeviceLinkV1QueryVariables = {
  device_link_id: string,
};

export type ListDeviceLinkEventsForDeviceLinkV1Query = {
  listDeviceLinkEventsForDeviceLinkV1?:  Array< {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceLinkTypesQuery = {
  listDeviceLinkTypes?:  Array< {
    __typename: "DeviceTypeV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceLinkTypeActionsQuery = {
  listDeviceLinkTypeActions?:  Array< {
    __typename: "ActionDeviceTypeV1",
    id: string,
    action_id: string,
    device_type_id: string,
    action_device_type: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceLinksForSiteQueryVariables = {
  segment_name: string,
};

export type ListDeviceLinksForSiteQuery = {
  listDeviceLinksForSite?:  Array< {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceLinksForSiteV1QueryVariables = {
  segment_name: string,
};

export type ListDeviceLinksForSiteV1Query = {
  listDeviceLinksForSiteV1?:  Array< {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceLinksForSiteV2QueryVariables = {
  segment_name: string,
};

export type ListDeviceLinksForSiteV2Query = {
  listDeviceLinksForSiteV2?:  Array< {
    __typename: "DeviceLinkV2",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    device_link_events?:  Array< {
      __typename: "DeviceLinkEvent",
      created: string,
      created_by: string,
      device_link_id: string,
      id: string,
      event_name: string,
      event_id: string,
      updated: string,
      updated_by: string,
    } | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListEventsQuery = {
  listEvents?:  Array< {
    __typename: "Event",
    created: string,
    created_by: string,
    device_type: string,
    id: string,
    name: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListEventsV1Query = {
  listEventsV1?:  Array< {
    __typename: "Event",
    created: string,
    created_by: string,
    device_type: string,
    id: string,
    name: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListNotificationsV1Query = {
  listNotificationsV1?:  Array< {
    __typename: "NotificationV1",
    dismissable: boolean,
    links?:  Array< {
      __typename: "NotificationLinkV1",
      target: string,
      text: string,
    } | null > | null,
    message: string,
    name: string,
    type: NotificationTypeV1,
  } | null > | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  Array< {
    __typename: "NotificationV1",
    dismissable: boolean,
    links?:  Array< {
      __typename: "NotificationLinkV1",
      target: string,
      text: string,
    } | null > | null,
    message: string,
    name: string,
    type: NotificationTypeV1,
  } | null > | null,
};

export type ListParametersV1Query = {
  listParametersV1?:  Array< {
    __typename: "ParameterV1",
    id: string,
    name: string,
    description: string,
    additional_info?: string | null,
    created: string,
    created_by: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListSIGInfraUserSitesQueryVariables = {
  username: string,
};

export type ListSIGInfraUserSitesQuery = {
  listSIGInfraUserSites?:  {
    __typename: "SIGInfraSite",
    allSites?: boolean | null,
    sites?: Array< string | null > | null,
  } | null,
};

export type ListSIGInfraUserSitesV1QueryVariables = {
  username: string,
};

export type ListSIGInfraUserSitesV1Query = {
  listSIGInfraUserSitesV1?:  {
    __typename: "SIGInfraSite",
    allSites?: boolean | null,
    sites?: Array< string | null > | null,
  } | null,
};

export type ListSystemsQuery = {
  listSystems?:  Array< {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListSystemsV1Query = {
  listSystemsV1?:  Array< {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListSystemsForSiteQueryVariables = {
  segment_name: string,
};

export type ListSystemsForSiteQuery = {
  listSystemsForSite?:  Array< {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListSystemsForSiteV1QueryVariables = {
  segment_name: string,
};

export type ListSystemsForSiteV1Query = {
  listSystemsForSiteV1?:  Array< {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListUserPermissionQueryVariables = {
  path: string,
};

export type ListUserPermissionQuery = {
  listUserPermission?:  Array< {
    __typename: "Permission",
    HASPERMISSION: string,
  } | null > | null,
};

export type ListUserPermissionV1QueryVariables = {
  path: string,
};

export type ListUserPermissionV1Query = {
  listUserPermissionV1?:  Array< {
    __typename: "Permission",
    HASPERMISSION: string,
  } | null > | null,
};

export type ListUserSitesQueryVariables = {
  path: string,
};

export type ListUserSitesQuery = {
  listUserSites?:  Array< {
    __typename: "Site",
    SegmentName: string,
    SegmentLocation: string,
    SegmentSource: string,
    SiteCode: string,
  } | null > | null,
};

export type ListUserSitesV1QueryVariables = {
  path: string,
};

export type ListUserSitesV1Query = {
  listUserSitesV1?:  Array< {
    __typename: "Site",
    SegmentName: string,
    SegmentLocation: string,
    SegmentSource: string,
    SiteCode: string,
  } | null > | null,
};

export type SNSPublishDeviceActionCreationQueryVariables = {
  deviceAction?: DeviceActionForSiteInputV1 | null,
};

export type SNSPublishDeviceActionCreationQuery = {
  SNSPublishDeviceActionCreation?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishDeviceActionDeleteQueryVariables = {
  deviceAction?: DeviceActionForSiteInputV1 | null,
};

export type SNSPublishDeviceActionDeleteQuery = {
  SNSPublishDeviceActionDelete?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishDeviceActionUpdateQueryVariables = {
  deviceAction?: DeviceActionForSiteInputV1 | null,
};

export type SNSPublishDeviceActionUpdateQuery = {
  SNSPublishDeviceActionUpdate?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};

export type SNSPublishDeviceActionProcessQueryVariables = {
  deviceAction?: DeviceActionForSiteInputV1 | null,
};

export type SNSPublishDeviceActionProcessQuery = {
  SNSPublishDeviceActionProcess?:  {
    __typename: "SNSResponse",
    MessageId: string,
    SequenceNumber?: string | null,
  } | null,
};
